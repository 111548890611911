import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Card, Container, Row, Col } from 'reactstrap';

import NoDataIndication from 'components/Tables/NoDataIndication';
import CopyTable from 'components/Tables/CopyTable';
import PrintTable from 'components/Tables/PrintTable';

import { pagination } from 'config/tableConfig';
import { formatDateTime } from 'utils/dateFormatter';
import secureAPI from 'utils/secureAPI';

const { SearchBar } = Search;

const PendingUsersTable = props => {
	const [data, setData] = useState([]);
	const [alert, setAlert] = useState(null);
	const [loading, setLoading] = useState(true);
	const [sortState, setSortState] = useState({ field: 'customSortField', order: 'desc' });

	const tableRef = useRef();

	useEffect(() => {
		let canSet = true;
		secureAPI({ method: 'POST', endpoint: 'APPROVE_USER' })
			.then(response => {
				if (canSet) {
					setData(response.data);
					setLoading(false);
				}
			})
			.catch(error => console.log(error));
		return () => (canSet = false);
	}, [props.user]);

	const handleSort = (field, order) => setSortState({ field, order });

	const headerSortingStyle = { color: '#ffffff', backgroundColor: '#5d90c3' };

	const dateTimeFormatter = (cell, row) => (cell ? formatDateTime(cell) : null);
	const tableauAccessFormatter = (cell, row) => (cell === "No" && row["ApprovalStatus"] === "Pending"? "Pending" : cell);

	return (
		<>
			{alert}
			<Container className="mt--6" fluid>
				<Row>
					<div className="col">
						<Card>
							<ToolkitProvider
								data={data}
								keyField="UserID"
								columns={[
									{
										dataField: 'UserID',
										hidden: true,
									},
									{
										dataField: 'FirstName',
										text: 'First Name',
									},
									{
										dataField: 'LastName',
										text: 'Last Name',
									},
									{
										dataField: 'Email',
										text: 'Email',
									},
									{
										dataField: 'Access',
										text: 'Access',
										sort: true,
										onSort: handleSort,
										headerSortingStyle,
									},
									{
										dataField: 'TableauAccess',
										text: 'Tableau Access',
										formatter: tableauAccessFormatter,
									},
									{
										dataField: 'Organisation',
										text: 'Organisation',
										sort: true,
										onSort: handleSort,
										headerSortingStyle,
									},
									{
										dataField: 'DETAreas',
										text: 'DET Areas',
										sort: true,
										onSort: handleSort,
										headerSortingStyle,
									},
									{
										dataField: 'DateRequested',
										text: 'Date Requested',
										sort: true,
										onSort: handleSort,
										formatter: dateTimeFormatter,
										headerSortingStyle,
									},
									{
										dataField: 'ApprovalStatus',
										text: 'Outcome',
										sort: true,
										onSort: handleSort,
										headerSortingStyle,
									},
									{
										dataField: 'DateUpdated',
										text: 'Outcome Date',
										sort: true,
										onSort: handleSort,
										formatter: dateTimeFormatter,
										headerSortingStyle,
									},
								]}
								search
							>
								{props => (
									<div className="py-4 no-link">
										<Container fluid>
											<Row>
												<Col xs={12} sm={6}>
													<CopyTable
														tableElement={document.getElementById(
															'user-approval-list-table',
														)}
														alertFunc={setAlert}
													/>
													<PrintTable tableRef={tableRef.current} />
												</Col>
												<Col xs={12} sm={6}>
													<div
														id="datatable-basic_filter"
														className="dataTables_filter pb-1 float-right"
													>
														<label>
															Search:
															<SearchBar
																className="form-control-sm"
																placeholder=""
																{...props.searchProps}
															/>
														</label>
													</div>
												</Col>
											</Row>
										</Container>

										<BootstrapTable
											id="user-approval-list-table"
											ref={tableRef}
											bootstrap4={true}
											pagination={pagination}
											bordered={false}
											noDataIndication={() => <NoDataIndication loading={loading} />}
											sort={{ dataField: sortState.field, order: sortState.order }}
											{...props.baseProps}
										/>
									</div>
								)}
							</ToolkitProvider>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default PendingUsersTable;
