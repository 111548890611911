import React from 'react';
import CellButton from 'components/Tables/CellButton';
import { confirmApprove, confirmDeny } from 'components/Alerts/ReopenRequestAlerts';

export const ApproveButton = props => (
	<CellButton
		func={() => confirmApprove(props)}
		text="Approve"
		customProps={{
			style: { marginRight: '0.5rem', marginLeft: 0 },
			color: 'success',
		}}
	/>
);

export const DenyButton = props => (
	<CellButton
		func={() => confirmDeny({ ...props })}
		text="Deny"
		customProps={{
			style: { marginRight: 0, marginLeft: '0.5rem' },
			color: 'warning',
		}}
	/>
);
