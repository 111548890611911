import React from 'react';
import ReactToPrint from 'react-to-print';
import { Button, UncontrolledTooltip } from 'reactstrap';

const PrintTable = props => (
    <>
        <ReactToPrint
            trigger={() => (
                <Button
                    color="default"
                    size="sm"
                    className="buttons-copy buttons-html5"
                    id="print-tooltip"
                >
                    <span>
                        <i
                            className="fas fa-print"
                            style={{ margin: '0 10px 0 0' }}
                        ></i>
                        Print
                    </span>
                </Button>
            )}
            content={() => props.tableRef}
        />
        <UncontrolledTooltip placement="top" target="print-tooltip">
            This will open a print page with the visible rows of the table.
        </UncontrolledTooltip>
    </>
)

export default PrintTable;