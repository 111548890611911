import React from 'react';
import { Spinner } from 'reactstrap';

const Loading = props => {
	if (props.mini) {
		return <Spinner size="sm" color="red" />
	}
	return (
		<>
			<Spinner type="grow" className="text-primary"></Spinner>
			<Spinner type="grow" className="text-primary"></Spinner>
			<Spinner type="grow" className="text-primary"></Spinner>
			<Spinner type="grow" className="text-primary"></Spinner>
			<Spinner type="grow" className="text-primary"></Spinner>
			<Spinner type="grow" className="text-primary"></Spinner>
			<span className=" sr-only">Loading...</span>
		</>
	);
};

export default Loading;
