import { Col, Row } from 'reactstrap';
import StackedBar from 'components/Plots/StackedBar';
import _ from 'underscore';
import ChartWrapper from 'components/Plots/ChartWrapper';
import NoChartData from 'components/Plots/NoChartData';
import { chartNotEmpty } from 'utils/functions';

const checkEmptyDataArray = (data, key) => (!_.isEmpty(data[key]) ? data[key] : []);

const ReferralDistinctCMAHStatusUpdates = ({ data, dataFilters, keys, currentView, areas }) => {
	let distinctCMAH = checkEmptyDataArray(data, 'distinct-cmah');
	const rteTimesChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: distinctCMAH,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={distinctCMAH ?? []}
					index={'DETArea'}
					indices={areas}
					indexFilter={dataFilters.area}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'DET Areas'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'cmah'}
				/>
			</ChartWrapper>
		),
	});

	return (
		<Row className="mt-3">
			<Col>{rteTimesChart}</Col>
		</Row>
	);
};

export default ReferralDistinctCMAHStatusUpdates;
