import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Loading from 'components/General/Loading';

const NoDataIndication = props => (
    <Container fluid className="adapt">
        <Row>
            <Col className="text-center m-6">
                { props.loading ?  <Loading /> : <div>No data available</div>}
            </Col>
        </Row>
    </Container>
);

export default NoDataIndication;