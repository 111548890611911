import StackedBar from 'components/Plots/StackedBar';
import ChartWrapper from 'components/Plots/ChartWrapper';

const ReferralDemographics = ({ data, dataFilters, keys, currentView, areas }) => {
	let values = dataFilters.area ? data[dataFilters.area] : data['All Areas'];
	return (
		<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="80vh">
			<StackedBar
				data={values ?? []}
				index={'demographic'}
				indices={areas}
				keys={keys}
				keyFilter={dataFilters.provider}
				xLabel={'Demographic Categories'}
				yLabel={'Number of Referrals'}
				view={currentView}
			/>
		</ChartWrapper>
	);
};

export default ReferralDemographics;
