import React from 'react';

import PageHeader from 'components/Headers/PageHeader';
import ApproveUsersTable from './ApproveUsersTable';

const ApproveUsers = props => {
	return (
		<>
			<PageHeader name="Approve Users" parentName="Hub" user={props.user} />
            <ApproveUsersTable user={props.user} />
		</>
	);
};

export default ApproveUsers;