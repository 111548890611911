import React from 'react';
import ReferralDownloadTable from 'components/Tables/ReferralDownloadTable';
import compileTableRows from 'utils/compileTableRows';

const ReferralDownloads = ({ data }) => {
	const openData = compileTableRows(data[0].rows, data[0].columns);
	const closedData = compileTableRows(data[1].rows, data[1].columns);
	const Breakline = () => (
		<div className="py-5">
			<hr
				style={{
					color: 'black',
					backgroundColor: 'black',
					height: 3,
				}}
			/>
		</div>
	);

	return (
		<>
			<ReferralDownloadTable data={openData} dataCols={data[0].columns} tableTitle={'Open Referrals'} />
			<Breakline />
			<ReferralDownloadTable
				data={closedData}
				dataCols={data[1].columns}
				tableTitle={'Closed-Exit-Ineligible Referrals'}
			/>
		</>
	);
};
export default ReferralDownloads;
