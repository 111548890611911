import { Col, Row, TabContent, TabPane, Nav } from 'reactstrap';
import { useState } from 'react';
import StackedBar from 'components/Plots/StackedBar';
import NavigationPill from 'components/Buttons/NavigationPill';
import ChartWrapper from 'components/Plots/ChartWrapper';
import NoChartData from 'components/Plots/NoChartData';
import { chartNotEmpty } from 'utils/functions';
import _ from 'underscore';

const SuccessfulOutcomes = ({ data, dataFilters, keys, currentView, areas }) => {
	const [activeTab, setActiveTab] = useState('RTEAH');
	let attendance = dataFilters.area ? data.attendance[dataFilters.area] : data.attendance['All Areas'];
	let exitOther = dataFilters.area ? data['exit-other'][dataFilters.area] : data['exit-other']['All Areas'];
	let resilience = !_.isEmpty(data.resilience) ? data.resilience : [];
	let completedSurveys = !_.isEmpty(data['completed-surveys']) ? data['completed-surveys'] : [];
	let successfulExits = dataFilters.area
		? data['successful-exits'][dataFilters.area]
		: data['successful-exits']['All Areas'];
	let tailored = !_.isEmpty(data.tailored) ? data.tailored : [];
	let rteTypesAH, rteTypesCM;
	if (!_.isEmpty(data['rte-types'])) {
		rteTypesAH = dataFilters.area
			? data['rte-types']['Active Hold'][dataFilters.area]
			: data['rte-types']['Active Hold']['All Areas'];
		rteTypesCM = dataFilters.area
			? data['rte-types']['Case Management'][dataFilters.area]
			: data['rte-types']['Case Management']['All Areas'];
	} else {
		rteTypesAH = [];
		rteTypesCM = [];
	}
	let successfullyExited = !_.isEmpty(data['successfully-exited']) ? data['successfully-exited'] : [];
	let exitOtherReasons = dataFilters.area
		? data['exit-other-reasons'][dataFilters.area]
		: data['exit-other-reasons']['All Areas'];

	const rteTypesAHChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data on Return to Education from AH." />,
		values: rteTypesAH,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 60, left: 50 }}
					data={rteTypesAH ?? []}
					index={'type'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xLabel={'Return to Education Types from Active Hold'}
					xLabelOffset={40}
					legendAnchor="bottom-right"
					yLabel={'Referrals'}
					view={currentView}
					totalsTableId={'RTEAH'}
				/>
			</ChartWrapper>
		),
	});

	const rteTypesCMChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data on Return to Education from CM." />,
		values: rteTypesCM,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 60, left: 50 }}
					data={rteTypesCM ?? []}
					index={'type'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xLabel={'Return to Education Types from Case Management'}
					xLabelOffset={40}
					legendAnchor="bottom-right"
					yLabel={'Referrals'}
					view={currentView}
					totalsTableId={'RTECM'}
				/>
			</ChartWrapper>
		),
	});

	const alternativeOutcomesChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data on alternative outcomes." />,
		values: exitOther,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 60, left: 50 }}
					data={exitOther ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xLabel={'Exit Point Summary'}
					xLabelOffset={40}
					legendAnchor="bottom-right"
					yLabel={'Referrals'}
					view={currentView}
					totalsTableId={'exitPointSummary'}
				/>
			</ChartWrapper>
		),
	});

	const successfulExitChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: successfulExits,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 60, left: 50 }}
					data={successfulExits ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xLabel={'Young Person Who Maybe Ready To Exit'}
					xLabelOffset={40}
					legendAnchor="bottom-right"
					yLabel={'Referrals'}
					view={currentView}
					totalsTableId={'successfulExit'}
				/>
			</ChartWrapper>
		),
	});

	const attendanceRateChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data on attendance rates." />,
		values: attendance,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 60, left: 50 }}
					data={attendance ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xLabel={'Attendance rates in Active Hold and Case Management'}
					xLabelOffset={40}
					legendAnchor="bottom-right"
					yLabel={'Referrals'}
					view={currentView}
					totalsTableId={'attendanceRate'}
				/>
			</ChartWrapper>
		),
	});

	const tailoredSupportChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data on tailored support." />,
		values: tailored,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 50 }}
					data={tailored ?? []}
					index={'DETArea'}
					indices={areas}
					indexFilter={dataFilters.area}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Referrals with Tailored Support by DET Area'}
					yLabel={'Referrals'}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'tailoredSupport'}
				/>
			</ChartWrapper>
		),
	});

	const resilienceChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data on increased resilience." />,
		values: resilience,
		component: (
			<>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 50 }}
						data={resilience ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={`Referrals with improved resilience by DET Area`}
						yLabel={'% of Referrals by Provider by Area'}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'resilience-percent'}
						totalsType={'percent'}
					/>
				</ChartWrapper>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 50 }}
						data={completedSurveys ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={`Number of YP who completed both entry and exit surveys`}
						yLabel={'Referrals'}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'resilience'}
					/>
				</ChartWrapper>
			</>
		),
	});

	const otherEngagementsChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data on alternative outcomes." />,
		values: exitOtherReasons,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 60, left: 50 }}
					data={exitOtherReasons ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xLabel={'Reasons for Exit, Other Engagements'}
					xLabelOffset={40}
					legendAnchor="bottom-right"
					yLabel={'Referrals'}
					view={currentView}
					totalsTableId={'exitOtherReasons'}
				/>
			</ChartWrapper>
		),
	});

	const successfullyExitedChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data on increased resilience." />,
		values: successfullyExited,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 50 }}
					data={successfullyExited ?? []}
					index={'DETArea'}
					indices={areas}
					indexFilter={dataFilters.area}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Exits with sustained educational reengagements'}
					yLabel={'Referrals'}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'successfullyExited'}
				/>
			</ChartWrapper>
		),
	});

	const chartArray = [
		{
			tabName: 'RTEAH',
			tabText: 'Return to Education from Active Hold',
			chart: rteTypesAHChart,
		},
		{
			tabName: 'RTECM',
			tabText: 'Return to Education from Case Management',
			chart: rteTypesCMChart,
		},
		{
			tabName: 'exitPointSummary',
			tabText: 'Exit Point Summary',
			chart: alternativeOutcomesChart,
		},
		{
			tabName: 'successfulExit',
			tabText: 'Young People Who May Be Ready To Exit',
			chart: successfulExitChart,
		},
		{
			tabName: 'attendanceRate',
			tabText: 'Attendance Rate',
			chart: attendanceRateChart,
		},
		{
			tabName: 'tailoredSupport',
			tabText: 'Tailored Support',
			chart: tailoredSupportChart,
		},
		{
			tabName: 'resilience',
			tabText: 'Resilience',
			chart: resilienceChart,
		},
		{
			tabName: 'exitOtherReasons',
			tabText: 'Exits with Other Engagement',
			chart: otherEngagementsChart,
		},
		{
			tabName: 'successfullyExited',
			tabText: 'Exits with Sustained Educational Reengagement',
			chart: successfullyExitedChart,
		},
	];

	const navSidebar = chartArray.map((chart, index) => (
		<Row key={index}>
			<Col>
				<NavigationPill activeTab={activeTab} changeActiveTab={setActiveTab} tabName={chart.tabName}>
					{chart.tabText}
				</NavigationPill>
			</Col>
		</Row>
	));

	const chartTabPanes = chartArray.map((chart, index) => (
		<TabPane key={index} tabId={chart.tabName}>
			{chart.chart}
		</TabPane>
	));

	return (
		<Row className="mt-3">
			<Col xs={2}>
				<Nav className="nav-fill flex-column" id="tabs-icons-text" pills role="tablist">
					{navSidebar}
				</Nav>
			</Col>
			<Col xs={10}>
				<TabContent activeTab={activeTab}>{chartTabPanes}</TabContent>
			</Col>
		</Row>
	);
};

export default SuccessfulOutcomes;
