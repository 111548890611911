import moment from 'moment';

export const formatDate = dateTime => {
	let d = moment.utc(dateTime)
	return d.local().format("DD MMM YYYY");
};

export const formatDateTime = dateTime => {
	let d = moment.utc(dateTime)
	return d.local().format('DD MMM YYYY - h:mm a');
};
