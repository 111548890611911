import StackedBar from 'components/Plots/StackedBar';
import ChartWrapper from 'components/Plots/ChartWrapper';

const FundingNumbers = ({ data, dataFilters, keys, currentView, areas }) => (
	<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="80vh">
		<StackedBar
			data={data}
			index={'DETArea'}
			indices={areas}
			indexFilter={dataFilters.area}
			keys={keys}
			keyFilter={dataFilters.provider}
			xRotation={-15}
			xLabel={'DET Area'}
			yLabel={'Number of Referrals'}
			view={currentView}
		/>
	</ChartWrapper>
);

export default FundingNumbers;
