import React, { useState } from 'react';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { ErrorAlert, LoadingAlert, SuccessAlert } from 'components/Alerts/ActionAlerts';
import secureAPI from 'utils/secureAPI';

const AddNotesModal = props => {
    const [alert, setAlert] = useState(null);
    const [note, setNote] = useState(null);

    const closeModal = () => props.modalFunc(state => !state);

    const submitNote = () => {
        if (!note) {
            setAlert(
                <ErrorAlert
                    text="Invalid note."
                    onConfirm={()=>setAlert(null)}
                    onCancel={()=>setAlert(null)}
                />
            )
            return
        }
        setAlert(<LoadingAlert />)
        let isoDate = new Date().toISOString();
        const entry = {
            LastUpdate: isoDate,
            userEmail: props.user.email,
            Notes: note
        };
        secureAPI(
            {method: 'PUT', endpoint: 'NOTES'},
            {entry: {RefID: props.refId, ...entry}}
        )
            .then(response => {
                console.log(response);
                props.setNotes(notes => [entry, ...notes]);
                setNote(null);
                setAlert(
                    <SuccessAlert
                        onConfirm={()=>setAlert(null)}
                        onCancel={()=>setAlert(null)}
                    />
                );
            })
            .catch(err => {
                console.log(err);
                setNote(null);
                setAlert(null);
            })
        closeModal();
    };

    const handleInputChange = e => {
        const inputValue = e.target.value.trim();
        setNote(inputValue);
    };
    
    const CancelButton = props => (
        <Button color="danger" onClick={closeModal}>
            Cancel
        </Button>
    );

    const SubmitButton = props => (
        <Button color="info" onClick={submitNote}>
            Submit
        </Button>
    );

    return (
        <>
        {alert}
            <Modal fade={false} isOpen={props.state} className="modal-dialog-centered">
                <ModalHeader>Add Notes</ModalHeader>
                <ModalBody>
                    <Input invalid={!note} type="textarea" placeholder="..." onChange={handleInputChange}/>
                </ModalBody>
                <ModalFooter>
                    <Row className="align-items-center">
                        <Col>
                            <CancelButton />
                        </Col>
                        <Col className="text-right">
                            <SubmitButton />
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default AddNotesModal;