import {React} from 'react';
import { Card } from 'reactstrap';

import ReactIframeResizer from 'react-iframe-resizer-super';
import ReportError from '../errors/ReportError';

import { iframeOptions, iframeStyles } from 'config/iframes';

const TableauChart = props => {
    const iframe = props.isActiveTab && props.url!== undefined && props.url!== null? (
        <ReactIframeResizer
            iframeResizerOptions={iframeOptions}
            iframeResizerUrl={false}
            src={props.url}
            style={iframeStyles}
        />
    ) : (
        <ReportError user={props.user} message="Invalid Report" />
    );
	return (
                <>
                    <Card style={{ height: '76vh', background: '#F3F3F3' }}>
                        {iframe}
                    </Card>
                </>
	);
};

export default TableauChart;
