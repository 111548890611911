import React from 'react';
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Button } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';


const Datepicker = props => {
    const displayValue = value => moment(value).isValid() ? moment(value).format("DD MMM YYYY") : value;
    
    const changeState = selectedDate => {
        if (typeof(selectedDate) === 'object'){
            props.stateFunc(selectedDate)
        } else {
            props.stateFunc("")
        }
    }

    const renderDateInput = (renderProps) => (
        <Input {...renderProps}
            placeholder={props.placeholder}
            value={displayValue(props.stateValue)}
        />
    )

    return (
        <FormGroup>
            <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                </InputGroupAddon>
                <Datetime
                    renderInput={renderDateInput}
                    dateFormat={"DD MMM YYYY"}
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={changeState}
                />
                <InputGroupAddon addonType="append">
                    <Button onClick={() => props.stateFunc("")}>Clear Date</Button>
                </InputGroupAddon>
            </InputGroup>
        </FormGroup>
    )
}

export default Datepicker;