import React, { useState, useEffect } from 'react';
import {
	Col,
	Form,
	FormGroup,
	InputGroup,
	Label,
	Input,
	InputGroupAddon,
	Button,
	FormFeedback,
	FormText,
} from 'reactstrap';
import { userTiers } from 'config/userTiers';
import _ from 'underscore';

const AddUserForm = props => {
	const [areas, setAreas] = useState([
		{
			area: '',
			validState: false,
		},
	]);
	const initValidStates = () => ({
		firstName: false,
		lastName: false,
		email: false,
		access: false,
		tableauAccess: false,
		organisation: false,
		detAreas: false,
	});
	const [validStates, setValidStates] = useState(initValidStates());

	const tier1users = ['Administrator', 'Area Coordinator', 'Service Provider'];
	const tier2users = tier1users.slice(1);
	const tier3users = tier2users.slice(1);

	useEffect(() => {
		let canSet = true;
		if (_.isEmpty(props.applicant) && canSet) {
			setValidStates(initValidStates());
			setAreas([
				{
					area: '',
					validState: false,
				},
			]);
		}
		return () => (canSet = false);
	}, [props.applicant]);

	useEffect(() => {
		let canSet = true;
		if (canSet) {
			props.setAllValid(reducedState(validStates));
		}
	}, [props, validStates]);

	const createOptions = array => array.map((el, i) => <option key={i}>{el}</option>);

	const accessLevelOptions = userRank => {
		switch (userRank) {
			case userTiers.ADMIN.rank:
				return createOptions(tier1users);

			case userTiers.COORD.rank:
				return createOptions(tier2users);

			default:
				return createOptions(tier3users);
		}
	};

	const handleChange = e => {
		let inputValue = e.target.value.trim();
		let inputId = e.target.id;
		const setStateFunc = val => {
			return function(state) {
				let newState = { ...state };
				newState[inputId] = val;
				return newState;
			};
		};

		if (inputValue && inputValue.toLowerCase() !== 'please select') {
			props.updateApplicant(setStateFunc(inputValue));
			setValidStates(setStateFunc(true));
		} else {
			props.updateApplicant(setStateFunc(undefined));
			setValidStates(setStateFunc(false));
		}
		
	};

	const updateAccess = e => {
		let newApplicant = { ...props.applicant };
		let newValidStates = { ...validStates };
		switch (e.target.value) {
			case tier1users[0]: // admin
				delete newApplicant.detAreas;
				delete newValidStates.detAreas;

				newApplicant[e.target.id] = e.target.value;
				newValidStates[e.target.id] = true;
				newApplicant['tableauAccess'] = "Yes";
				newValidStates['tableauAccess'] = true;
				newApplicant['organisation'] = 'DET';
				newValidStates['organisation'] = true;
				break;
			case tier1users[1]: // coordinator
				newApplicant[e.target.id] = e.target.value;
				newValidStates[e.target.id] = true;
				newValidStates['detAreas'] = false;
				newApplicant['tableauAccess'] = "Yes";
				newValidStates['tableauAccess'] = true;
				newApplicant['organisation'] = 'DET';
				newValidStates['organisation'] = true;
				break;
			case tier1users[2]: // provider
				newApplicant[e.target.id] = e.target.value;
				newValidStates[e.target.id] = true;
                newApplicant['tableauAccess'] = "No";
				newValidStates['tableauAccess'] = true;
				newValidStates['organisation'] = false;
				newValidStates['detAreas'] = false;
				break;

			default:
				delete newApplicant[e.target.id];
				newValidStates[e.target.id] = false;
				break;
		}
		setValidStates(newValidStates);
		props.updateApplicant(newApplicant);
		setAreas([
			{
				area: '',
				validState: false,
			},
		]);
		
	};

	const addDETarea = () => {
		let newAreas = [...areas, { area: '', validState: false }];
		setAreas(newAreas);
		setValidStates(state => ({ ...state, detAreas: false }));
		props.updateApplicant(state => ({ ...state, detAreas: newAreas }));
	};

	const removeDETarea = i => {
		let newAreas = [...areas];
		newAreas.splice(i, 1);

		props.updateApplicant(state => ({ ...state, detAreas: newAreas }));
		setValidStates(state => ({ ...state, detAreas: validateAreas(newAreas) }));
		setAreas(newAreas);
	};

	const updateAreas = (i, e) => {
		let newAreas = [...areas];
		let inputValue = e.target.value;
		if (inputValue && inputValue.toLowerCase() !== 'please select') {
			newAreas[i]['area'] = e.target.value;
			newAreas[i]['validState'] = true;
		} else {
			newAreas[i]['area'] = '';
			newAreas[i]['validState'] = false;
		}

		props.updateApplicant(state => ({ ...state, detAreas: newAreas }));
		setAreas(newAreas);
		setValidStates(state => ({ ...state, detAreas: validateAreas(newAreas) }));
	};

	const validateAreas = areaArray => {
		if (areaArray.length === 1) {
			return areaArray[0]['validState'];
		}
		const areValid = (acc, curr) => acc && curr.validState;
		return areaArray.reduce(areValid, true);
	};

	const reducedState = stateObj => Object.values(stateObj).every(el => el);

	return (
		<>
			<Form>
				<FormGroup row>
					<Col>
						<Label className="form-control-label"> First Name </Label>
						<Input
							invalid={!validStates.firstName}
							id="firstName"
							placeholder="First Name"
							value={props.applicant.firstName || ''}
							onChange={e => handleChange(e)}
							type="text"
						/>
						{!validStates.firstName && <FormFeedback>Field cannot be empty.</FormFeedback>}
					</Col>
					<Col>
						<Label className="form-control-label"> Last Name </Label>
						<Input
							invalid={!validStates.lastName}
							id="lastName"
							placeholder="Last Name"
							value={props.applicant.lastName || ''}
							onChange={e => handleChange(e)}
							type="text"
						/>
						{!validStates.lastName && <FormFeedback>Field cannot be empty.</FormFeedback>}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Col>
						<Label className="form-control-label"> Email </Label>
						<Input
							invalid={!validStates.email}
							id="email"
							placeholder="email@domain.com"
							value={props.applicant.email || ''}
							onChange={e => handleChange(e)}
							type="email"
						/>
						{
							// TODO: validate emails max 200 chars
						}
						{!validStates.email && <FormFeedback>Field cannot be empty.</FormFeedback>}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Col>
						<Label className="form-control-label"> Navigator Access Level </Label>
						<Input
							invalid={!validStates.access}
							id="access"
							onChange={e => updateAccess(e)}
							value={props.applicant.access || ''}
							type="select"
						>
							<option>Please select</option>
							{accessLevelOptions(props.user.accessRank)}
						</Input>
						{!validStates.access && <FormFeedback>Please select one.</FormFeedback>}
						{tier1users.slice(0, 2).includes(props.applicant.access) && (
							<FormText>{props.applicant.access} organisation defaults to DET and is provided Reporting access.</FormText>
						)}
						{tier3users.includes(props.applicant.access) && props.user.accessRank >= 2 && (
							<FormText>{props.applicant.access} can be granted reporting access during approval.</FormText>
						)}
					</Col>
				</FormGroup>
				{tier3users.find(el => el === props.applicant.access) && props.user.accessRank < 2 && (
					<FormGroup row>
						<Col>
							<Label className="form-control-label">Requires Tableau Access</Label>
							<Input
								invalid={!validStates.tableauAccess}
								id="tableauAccess"
								onChange={e => handleChange(e)}
								type="select"
							>
								<option>No</option>
								<option>Yes</option>
							</Input>
							{!validStates.tableauAccess && <FormFeedback>Field cannot be empty.</FormFeedback>}
						</Col>
					</FormGroup>
				)}
				{tier1users.slice(0, 2).includes(props.applicant.access) && (
					<FormGroup row>
						<Col>
							<Label className="form-control-label"> Organisation </Label>
							<Input
								invalid={!validStates.organisation}
								id="organisation"
								value={props.applicant.organisation || ''}
								onChange={e => handleChange(e)}
								type="text"
							/>
							{!validStates.organisation && <FormFeedback>Field cannot be empty.</FormFeedback>}
						</Col>
					</FormGroup>
				)}
				{tier3users.find(el => el === props.applicant.access) && (
					<FormGroup row>
						<Col>
							<Label className="form-control-label"> Service Provider </Label>
							<Input
								invalid={!validStates.organisation}
								id="organisation"
								onChange={e => handleChange(e)}
								defaultValue="Please select"
								type="select"
							>
								<option>Please select</option>
								{createOptions(props.options.providers)}
							</Input>
							{!validStates.organisation && <FormFeedback>Please select one.</FormFeedback>}
						</Col>
					</FormGroup>
				)}
				{tier2users.find(el => el === props.applicant.access) &&
					areas.map((element, index) => (
						<FormGroup row key={index}>
							<Col>
								<Label className="form-control-label"> DET Area {Boolean(index) && index + 1}</Label>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<Button onClick={() => addDETarea()}>
											<i className="fas fa-plus" />
										</Button>
									</InputGroupAddon>
									<Input
										invalid={!element.validState}
										id="detArea"
										// className="form-control-alternative form-control-lg"
										className="form-control-lg"
										onChange={e => updateAreas(index, e)}
										value={element.area || ''}
										type="select"
									>
										<option>Please select</option>
										{createOptions(props.options.areas)}
									</Input>
									{Boolean(index) && (
										<InputGroupAddon addonType="append">
											<Button color="warning" onClick={() => removeDETarea(index)}>
												<i className="fas fa-times" />
											</Button>
										</InputGroupAddon>
									)}
									{!element.validState && <FormFeedback>Please select one.</FormFeedback>}
								</InputGroup>
							</Col>
						</FormGroup>
					))}
			</Form>
			{/* <pre style={{whiteSpace:'pre-wrap'}}>{JSON.stringify(props.applicant)}</pre>
            <pre style={{whiteSpace:'pre-wrap'}}>{JSON.stringify(validStates)}</pre> */}
		</>
	);
};

export default AddUserForm;
