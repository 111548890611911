import { Auth } from '@aws-amplify/auth';
import axios from 'axios';
import { Endpoints } from 'config/endpoints';

const secureAPI = async (api, body = {}) => {
    const header = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
    };
    switch (api.method) {
        case 'GET':
            return await axios.get(Endpoints[api.endpoint], header)
        case 'PUT':
            return await axios.put(Endpoints[api.endpoint], body, header)
        default:
            return await axios.post(Endpoints[api.endpoint], body, header)
    }
}

export default secureAPI;