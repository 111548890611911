import React from 'react';
import { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Container, Row, Col, Modal } from 'reactstrap';
import { useParams } from 'react-router-dom';

import ProfileUpdateHeader from 'components/Headers/ProfileUpdateHeader';
import Loading from 'components/General/Loading';
import { formatDateTime } from 'utils/dateFormatter';
import secureAPI from 'utils/secureAPI';

import { Chrono } from 'react-chrono';

const History = props => {
	const [history, setHistory] = useState([]);
	const [name, setName] = useState(['']);
	const [loading, setLoading] = useState(true);
	let params = useParams();
	
	const pageName = (params.type === 'timeline') ? 'Timeline' : 'Update History';

	useEffect(() => {
		let canSet = true;
		const getRefProfile = (
			secureAPI(
				{method: 'POST', endpoint: 'PROFILE'},
				{ RefID: params.id }
			)
				.then(response => {
					if (canSet) {
						let firstname = response.data['Young person - first name'] ?? ''
						let surname = response.data['Young person - surname'] ?? ''
						setName(`${firstname} ${surname}`);
					}
				})
				.catch(error => {
					console.log(error);
				})
		)
		const getProfileHistory = (
			secureAPI(
				{method: 'POST', endpoint: 'HISTORY'},
				{ RefID: params.id, type: params.type }
			)
				.then(response => {
					response.data.map(data => (
						data.title = formatDateTime(data.title)
					));
					if (canSet) {
						setHistory(response.data);
					}	
				})
				.catch(error => {
					console.log(error);
				})
		)
		const requestArr = [getRefProfile, getProfileHistory]
		Promise.all(requestArr).then(() => {
			if (canSet) {
				setLoading(false);
			}
		})
		return () => (canSet = false);
	}, [params.id, params.type]);
	
	return !loading ? (
		<>
			<ProfileUpdateHeader name={pageName} id={params.id} parentName={name} user={props.user} />
			<Container className="mt--6" fluid>
				<Row>
					<Col lg="12">
						<Card>
							<CardHeader className="bg-transparent">
								<h3 className="mb-0">{pageName}</h3>
							</CardHeader>
							<CardBody>
								{(history.every((item) => (
									item.hasOwnProperty('title') &&
									item.hasOwnProperty('cardTitle')
								)) && (history.length > 0)) ? (
									<Chrono
										items={history}
										mode='VERTICAL_ALTERNATING'
										theme={{
											primary: "#b5374f",
											secondary: "white",
										}}
										cardHeight={150}
										hideControls={true}
										scrollable
									/>
								) : (
									<p>
										No history on file for {name}
									</p>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	) : (
		<>
			<Modal className="modal-dialog-centered" isOpen={loading}>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Loading History ....
					</h6>
				</div>
				<div className="modal-body">
					<Container>
						<Row>
							<Col className="text-center">
								<Loading />
							</Col>
						</Row>
					</Container>
				</div>
			</Modal>
		</>
	);
};

export default History;
