import React from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Card, CardHeader, CardBody, CardFooter, CardText, CardTitle, Container, Row, Col } from 'reactstrap';

import PageHeader from 'components/Headers/PageHeader';
import { Reports } from 'config/reports';

const ReportingList = props => {
	const history = useHistory();

	const hubReportClick = id => {
		if (id) {
			history.push(`/report/${id}`);
		}
	};

	const restrictAccess = element => props.user.accessRank <= element.access;

	const maxRowSize = 4;

	const createCards = array =>
		array.map((r, i) => (
			<Card className="card-pricing bg-gradient-yellow" tag="a" key={i} onClick={() => hubReportClick(r.id)}>
				<CardBody>
					<Row className="mb-3">
						<Col xl="8" lg="6" md="6" sm="6" xs="6">
							<CardTitle tag="h5" className="h3 font-weight-bold">
								{r.title}
							</CardTitle>
						</Col>
						<Col xl="4" lg="6" md="6" sm="6" xs="6" style={{ display: 'flex', justifyContent: 'right' }}>
							<div className="icon icon-shape bg-gradient-yellow text-white rounded-circle shadow">
								<i className="ni ni-chart-bar-32" />
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<CardText>{r.description}</CardText>
							{r.inProgress ? (
								<Badge tag="h5" color={'warning'} pill>
									{'In Progress'}
								</Badge>
							) : null}
						</Col>
					</Row>
				</CardBody>
				<CardFooter className="border-0">
					<button type="button" className="btn btn-primary" onClick={e => e.preventDefault()}>
						Open {r.title}
					</button>
				</CardFooter>
			</Card>
		));

	const createRows = (array, row_size) => {
		let matrix = [];
		for (var i = 0; i < array.length; i += row_size) {
			let temp_array = array.slice(i, i + row_size);
			matrix.push(temp_array);
		}

		return matrix.map((row, index) => {
			if (row.length === row_size) {
				return (
					<Row key={index}>
						<Col>
							<div className="card-deck">{createCards(row)}</div>
						</Col>
					</Row>
				);
			} else {
				const colWidth = (12 / row_size) * row.length;
				return (
					<Row key={index}>
						<Col xl={colWidth} lg={colWidth}>
							<div className="card-deck">{createCards(row)}</div>
						</Col>
					</Row>
				);
			}
		});
	};

	const createSections = (array, maxSize) => {
		let sections = new Set();
		array.map(el => sections.add(el.section));
		return [...sections].map((thisSection, index) => (
			<Card key={index}>
				<CardHeader>
					<h5 className="h2 font-weight-bold mb-0">{thisSection}</h5>
				</CardHeader>
				<CardBody>
					{createRows(
						array.filter(el => el.section === thisSection),
						maxSize,
					)}
				</CardBody>
			</Card>
		));
	};

	return (
		<>
			<PageHeader name="Reporting" parentName="Hub" user={props.user} />
			<Container className="mt--6" fluid>
				{createSections(Reports.filter(restrictAccess), maxRowSize)}
			</Container>
		</>
	);
};

export default ReportingList;
