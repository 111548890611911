export const userTiers = {
	ADMIN: {
		access: 'admin',
		rank: 0,
	},
	COORD: {
		access: 'coordinator',
		rank: 1,
	},
	STAFF_TABLEAU: {
		access: 'provider-tableau',
		rank: 2,
	},
	STAFF: {
		access: 'provider',
		rank: 3,
	},
};

// constructor for user ranks
// lower integer represents higher ranks
const setRank = tiers => {
	const ranks = {};
	Object.values(tiers).map(tier => {
		return (ranks[tier.access] = tier.rank);
	});
	return ranks;
};

export const userRanks = new setRank(userTiers);
