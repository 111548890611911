import StackedBar from 'components/Plots/StackedBar';
import ChartWrapper from 'components/Plots/ChartWrapper';

const ReferralsByStatus = ({ data, dataFilters, keys, currentView, areas }) => {
	let values = dataFilters.area ? data[dataFilters.area] : data['All Areas'];
	return (
		<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="80vh">
			<StackedBar
				data={values ?? []}
				margins={{ top: 25, right: 200, bottom: 60, left: 250 }}
				index={'status'}
				indices={areas}
				keys={keys}
				keyFilter={dataFilters.provider}
				xLabel={'Number of Referrals'}
				xLabelOffset={50}
				yLabel={'Referral Statuses'}
				yLabelOffset={-240}
				horizontal={true}
				view={currentView}
			/>
		</ChartWrapper>
	);
};
export default ReferralsByStatus;
