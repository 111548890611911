import React from 'react';
import { Col, Card } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

const ProfileSupportsTable = props => {
	const totalSupports = data => {
		let total = 0;
		for (const option of data) {
			total += option['Pre-Referral School Supports'] + option['Post-Referral School Supports'];
		}
		return total;
	};

	const supportsFormatter = (cell, row) => {
		if (cell === 1) {
			return <i className="fas fa-check-circle" />;
		} else {
			return null;
		}
	};

	props.data.supportCols.map(col => {
		if (col.dataField === 'Pre-Referral School Supports' || col.dataField === 'Post-Referral School Supports') {
			col['formatter'] = supportsFormatter;
			return col;
		} else {
			return col;
		}
	});

	return (
		<Card>
			{totalSupports(props.data.supportOptions) === 0 ? (
				<Col>
					<p style={{ margin: '1rem' }}>No supports recorded</p>
				</Col>
			) : (
				<ToolkitProvider
					data={props.data.supportOptions}
					keyField="OptionOrder"
					columns={props.data.supportCols}
				>
					{props => (
						<Col>
							<BootstrapTable id="support-table" bootstrap4={true} bordered={true} {...props.baseProps} />
						</Col>
					)}
				</ToolkitProvider>
			)}
		</Card>
	);
};

export default ProfileSupportsTable;
