import React from 'react';
import { Col, Card } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

const ProfileSurveyTable = props => {

    return (
        <Card>
            {props.data.surveyCols.length <= 2 ? (
                <Col>
                    <p style={{margin: '1rem'}}>No survey responses available</p>
                </Col>
                ) : (
                <ToolkitProvider
                    data={props.data.surveyResponses}
                    keyField="Question Order"
                    columns={props.data.surveyCols}
                >
                    {props => (
                        <Col>
                            <BootstrapTable
                                id="survey-table"
                                bootstrap4={true}
                                bordered={true}
                                {...props.baseProps}
                            />
                        </Col>
                    )}
                </ToolkitProvider>
            )}
        </Card>
    )
}

export default ProfileSurveyTable;