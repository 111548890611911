import React from 'react';
import { Badge, Button, Card, CardBody, CardText, CardTitle, Row, Col, CardFooter } from 'reactstrap';

const HubCard = props => {
    return (
        <Card className={`card-pricing ${props.color}`} tag="a" onClick={props.onClick}>
            <CardBody>
                <Row>
                    <Col className="col">
                        <CardTitle tag="h5" className="h2 font-weight-bold">
                            {props.titleBadge ? (
                                <Row>
                                    <Col xs={7}>
                                    {props.title}
                                    </Col>
                                    <Col xs={5}>
                                        <Badge tag="h5" color={props.titleBadgeColor ?? "danger"} pill>
                                            {props.titleBadge}
                                        </Badge>
                                    </Col>
                                </Row>
                            ) : props.title}
                        </CardTitle>
                    </Col>
                    <Col className="col-auto">
                        <div className={`icon icon-shape ${props.color} text-white rounded-circle shadow`}>
                            <i className={props.icon}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {props.badge ? (
                            <Badge tag="h5" color={props.badgeColor} pill>
                                {props.badge}
                            </Badge>
                        ) : null}
                        <CardText>
                            {props.body}
                        </CardText>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter className="border-0">
                <Button
                    block
                    color="primary"
                    onClick={e => e.preventDefault()}
                >
                    {props.buttonText}
                </Button>
            </CardFooter>
        </Card>
    )
}

export default HubCard;