import React from 'react';

import PageHeader from 'components/Headers/PageHeader';
import ApproveReopenRequestsTable from 'components/Tables/ApproveReopenRequestsTable';

const ApproveReopenRequests = props => {
	return (
		<>
			<PageHeader name="Approve Reopen Requests" parentName="Hub" user={props.user} />
			<ApproveReopenRequestsTable user={props.user} />
		</>
	);
};

export default ApproveReopenRequests;
