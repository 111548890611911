import React from 'react';
import { Form, FormGroup, Col, Label, Input } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import { ConfirmAlert, SuccessAlert, ErrorAlert, CancelAlert } from 'components/Alerts/ActionAlerts';
import secureAPI from 'utils/secureAPI';

export const confirmAlert = (funcs, applicant, requester) => {
	return funcs.alert(
		<ConfirmUserAlert
			text="Please confirm user details."
			data={applicant}
			onConfirm={handleConfirmSubmitUserForApproval(funcs, applicant, requester)}
			onCancel={handleCancelAlerts(funcs.alert)}
		/>,
	);
};

export const adminConfirmAlert = (funcs, applicant) => {
	return funcs.alert(
		<ConfirmUserAlert
			text="Please confirm user details."
			data={applicant}
			onConfirm={handleAdminConfirmAddUser(funcs, applicant)}
			onCancel={handleCancelAlerts(funcs.alert)}
		/>,
	);
};

export const errorAlert = alertFunc => {
	return alertFunc(
		<ErrorAlert
			title="Form incomplete!"
			text="Ensure all fields have been filled correctly."
			onConfirm={handleClearAlerts(alertFunc)}
			onCancel={handleClearAlerts(alertFunc)}
		/>,
	);
};

export const confirmApprove = options => {
	const applicantUpdateTableauAccess = val => {
		options.applicant.tableauAccess = val;
	};
	return options.func(
		<ConfirmUserAlert
			title="Confirm Approval to NDBMS"
			text="Please confirm user details. User will gain access to the Navigator Data Management System."
			data={options.applicant}
			onConfirm={handleConfirmApproval(options)}
			onCancel={handleCancelAlerts(options.func)}
			updateTableauAccess={true}
			setTableauAccess={applicantUpdateTableauAccess}
		/>,
	);
};

export const confirmDeny = options => {
	return options.func(
		<ConfirmUserAlert
			title="Reject User"
			text="Please confirm user details. User will be denied access to the Navigator Data Management System."
			data={options.applicant}
			onConfirm={handleConfirmDenial(options)}
			onCancel={handleCancelAlerts(options.func)}
		/>,
	);
};

const UpdateTableauAccess = props => (
	<Form className="px-5 mb-5">
		<FormGroup row className="align-items-center">
			<Col>
				<Label className="form-control-label">Requires Tableau Access</Label>
            </Col>
            <Col>
				<Input id="tableauAccess" onChange={e => props.setTableauAccess(e.target.value)} type="select">
					<option>No</option>
					<option>Yes</option>
				</Input>
			</Col>
		</FormGroup>
	</Form>
);

const ConfirmUserAlert = props => (
	<ConfirmAlert onConfirm={props.onConfirm} onCancel={props.onCancel} title={props.title} text={props.text}>
		<ConfirmUserTable data={props.data} />
		{props.updateTableauAccess && <UpdateTableauAccess setTableauAccess={props.setTableauAccess} />}
	</ConfirmAlert>
);

const handleAdminConfirmAddUser = (funcs, applicant) => {
	return () => {
		secureAPI({ method: 'PUT', endpoint: 'ADMIN_USER' }, { applicant: applicant })
			.then(response => console.log(response.data?.message))
			.catch(err => console.log(err));
		funcs.alert(successAlert(funcs));
	};
};

const handleConfirmSubmitUserForApproval = (funcs, applicant, requester) => {
	return () => {
		secureAPI({ method: 'PUT', endpoint: 'APPROVE_USER' }, { applicant: applicant, requester: requester })
			.then(response => null)
			.catch(err => console.log(err));
		funcs.alert(successAlert(funcs));
	};
};

const handleConfirmApproval = options => {
	return () => {
		secureAPI({ method: 'PUT', endpoint: 'ADMIN_USER' }, { applicant: options.applicant })
			.then(response => null)
			.catch(err => console.log(err));

		secureAPI(
			{ method: 'POST', endpoint: 'ADMIN_USER' },
			{
				confirm: {
					email: options.applicant['email'],
					type: 'approved',
					admin: options.admin,
					tableauAccess: options.applicant['tableauAccess'],
				},
			},
		)
			.then(response => null)
			.catch(err => console.log(err));
		options.func(successAlert(options.func));
		removeConfirmed(options);
	};
};

const handleConfirmDenial = options => {
	return () => {
		secureAPI(
			{ method: 'POST', endpoint: 'ADMIN_USER' },
			{
				confirm: {
					email: options.applicant['email'],
					type: 'denied',
					admin: options.admin,
					tableauAccess: options.applicant['tableauAccess'],
				},
			},
		)
			.then(response => null)
			.catch(err => console.log(err));
		options.func(successAlert(options.func));
		removeConfirmed(options);
	};
};

const removeConfirmed = options => {
	const filterConfirmed = data => data.filter(row => row['UserID'] !== options.data['UserID']);
	options.tableFunc(filterConfirmed);
};

const successAlert = funcs => {
	let alertFunc, userFunc;
	if (typeof funcs === 'object') {
		alertFunc = funcs.alert;
		userFunc = funcs.user;
	} else {
		alertFunc = funcs;
		userFunc = null;
	}
	return (
		<SuccessAlert
			onConfirm={handleClearAlerts(alertFunc, userFunc)}
			onCancel={handleClearAlerts(alertFunc, userFunc)}
		/>
	);
};

const handleCancelAlerts = alertFunc => {
	return () => alertFunc(<CancelAlert onConfirm={() => alertFunc(null)} />);
};

const handleClearAlerts = (alertFunc, userFunc) => {
	return () => {
		if (typeof userFunc === 'function' && userFunc) {
			userFunc({});
		}
		alertFunc(null);
	};
};

const ConfirmUserTable = props => {
	let data = props.data;
	const dataTable = [
		{
			field: 'First Name',
			value: data.firstName,
		},
		{
			field: 'Last Name',
			value: data.lastName,
		},
		{
			field: 'Email',
			value: data.email,
		},
		{
			field: 'Navigator Access Level',
			value: data.access,
		},
		{
			field: 'Organisation',
			value: data.organisation,
		},
		{
			field: 'DET Area(s)',
			value: data.detAreas?.reduce((acc, cur) => (acc ? acc + ', ' + cur.area : acc + cur.area), ''),
			style: { maxWidth: 50, wordBreak: 'break-all' },
		},
	].filter(el => el.value);

	return (
		<Col>
			<ToolkitProvider
				data={dataTable}
				keyField="field"
				columns={[
					{
						dataField: 'field',
						text: 'Field',
						align: 'left',
					},
					{
						dataField: 'value',
						text: 'Value',
					},
				]}
			>
				{props => {
					return (
						<div>
							<BootstrapTable
								id="confirm-user-table"
								bootstrap4={true}
								bordered={true}
								{...props.baseProps}
							/>
						</div>
					);
				}}
			</ToolkitProvider>
		</Col>
	);
};
