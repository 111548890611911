import React from 'react';

import PageHeader from 'components/Headers/PageHeader';
import ReferralListTable from 'components/Tables/ReferralListTable';

const Referral = props => {
	return (
		<>
			<PageHeader name="Referral List" parentName="Hub" user={props.user} />
			<ReferralListTable user={props.user} />
		</>
	);
};

export default Referral;
