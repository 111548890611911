import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'reactstrap';
import { useParams } from 'react-router-dom';

import ProfileUpdateHeader from 'components/Headers/ProfileUpdateHeader';
import Loading from 'components/General/Loading';
import TimelineCard from 'components/General/TimelineCard';
import secureAPI from 'utils/secureAPI';

const Uploads = props => {
	const [uploads, setUploads] = useState({
		history: [],
		rp: [],
		sep: [],
		drp: [],
	});
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(true);
	const [collapsedState, setCollapse] = useState(['1']);
	let params = useParams();

	useEffect(() => {
		let canSet = true;
		const getRefProfile = secureAPI({ method: 'POST', endpoint: 'PROFILE' }, { RefID: params.id })
			.then(response => {
				if (canSet) {
					let firstname = response.data['Young person - first name'] ?? '';
					let surname = response.data['Young person - surname'] ?? '';
					setName(`${firstname} ${surname}`);
				}
			})
			.catch(error => {
				console.log(error);
			});

		const getProfileUploads = secureAPI({ method: 'POST', endpoint: 'UPLOADS' }, { RefID: params.id })
			.then(response => {
				if (canSet) {
					setUploads(response.data);
				}
			})
			.catch(error => {
				console.log(error);
			});

		const requestArr = [getRefProfile, getProfileUploads];
		Promise.all(requestArr).then(() => {
			if (canSet) {
				setLoading(false);
			}
		});

		return () => (canSet = false);
	}, [params.id]);

	return !loading ? (
		<>
			<ProfileUpdateHeader name="Uploads" id={params.id} parentName={name} user={props.user} />
			<Container className="mt--6" fluid>
				<Row>
					<Col lg="4">
						<TimelineCard
							header={{
								title: 'Upload History',
							}}
							body={{
								data: uploads.history,
								date: 'UploadDate',
								target: 'UploadContents',
								nodata: `No uploads on file for ${name}`,
								icon: 'ni ni-bell-55',
								iconStyle: 'timeline-step badge-success',
							}}
						/>
					</Col>
					<Col lg="8">
						<div className="accordion">
							<TimelineCard
								header={{
									title: 'Reengagement Plans',
									type: 'collapse',
									collapseIndex: '1',
									state: collapsedState,
									stateFunc: setCollapse,
								}}
								body={{
									data: uploads.rp,
									date: 'LastUpdate',
									target: 'Contents',
									nodata: `No reengagement plans on file for ${name}`,
									icon: 'ni ni-bell-55',
									iconStyle: 'timeline-step badge-primary',
								}}
							/>
						</div>
						<div className="accordion">
							<TimelineCard
								header={{
									title: 'Supported Exit Plans',
									type: 'collapse',
									collapseIndex: '2',
									state: collapsedState,
									stateFunc: setCollapse,
								}}
								body={{
									data: uploads.sep,
									date: 'LastUpdate',
									target: 'Contents',
									nodata: `No supported exit plans on file for ${name}`,
									icon: 'ni ni-bell-55',
									iconStyle: 'timeline-step badge-secondary',
								}}
							/>
						</div>
						<div className="accordion">
							<TimelineCard
								header={{
									title: 'Deferred Referral Plans',
									type: 'collapse',
									collapseIndex: '3',
									state: collapsedState,
									stateFunc: setCollapse,
								}}
								body={{
									data: uploads.drp,
									date: 'LastUpdate',
									target: 'Contents',
									nodata: `No deferred referral plans on file for ${name}`,
									icon: 'ni ni-bell-55',
									iconStyle: 'timeline-step badge-warning',
								}}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	) : (
		<>
			<Modal className="modal-dialog-centered" isOpen={loading}>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Loading Uploads ....
					</h6>
				</div>
				<div className="modal-body">
					<Container>
						<Row>
							<Col className="text-center">
								<Loading />
							</Col>
						</Row>
					</Container>
				</div>
			</Modal>
		</>
	);
};

export default Uploads;
