import { Row, Container } from 'reactstrap';

// Nivo charts parent containers must have a height to display
const ChartWrapper = ({ children, currentView, totalsKeyword, chartMaxHeight }) => (
	<Row>
		<Container fluid style={currentView === totalsKeyword ? null : { height: chartMaxHeight }}>
			{children}
		</Container>
	</Row>
);

export default ChartWrapper;
