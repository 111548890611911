import React, { useRef } from 'react';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import NoDataIndication from './NoDataIndication';
import { pagination } from 'config/tableConfig';
import { Container, Row, Col } from 'reactstrap';

// const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const ReferralDownloadTable = ({ data, dataCols, tableTitle = 'Data Export Table' }) => {
	const tableRef = useRef();
	const columns = dataCols.map(e => ({ dataField: e, text: e }));

	const getFileName = () => {
		let d = new Date().toISOString();
		let t = tableTitle
			.toLowerCase()
			.split(' ')
			.join('_');
		return `navi_export_${t}_${d}.csv`;
	};

	return (
		<ToolkitProvider
			data={data}
			keyField="ReferralID"
			search
			columns={columns}
			exportCSV={{
				fileName: getFileName(),
			}}
		>
			{props => (
				<>
					<Container fluid>
						<Row>
							<Col sm="8" style={{ display: 'flex', justifyContent: 'center' }}>
								<span
									style={{
										fontWeight: 'bold',
										fontSize: '0.875rem',
										marginTop: '1rem',
									}}
								>
									{tableTitle}
								</span>
							</Col>
							<Col sm="4">
								<ExportCSVButton {...props.csvProps} size="sm">
									Export {tableTitle}
								</ExportCSVButton>
							</Col>
						</Row>
					</Container>
					<div className="table-responsive">
						<BootstrapTable
							id="ref-list-table"
							ref={tableRef}
							bootstrap4={true}
							pagination={pagination}
							bordered={true}
							noDataIndication={() => <NoDataIndication loading={false} />}
							{...props.baseProps}
						/>
					</div>
				</>
			)}
		</ToolkitProvider>
	);
};

export default ReferralDownloadTable;
