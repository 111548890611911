function formatPriorityCategory(stringInput) {
	if (stringInput === null || stringInput === undefined) {
		return null;
	}
	if (typeof stringInput !== 'string') {
		return null;
	}
	let output = [];
	let category_map = {
		aboriginal: 'ATSI',
		refugee: 'Refugee',
		'youth justice': 'Involved with Youth Justice',
		'home care': 'OOHC',
		'child protection': 'Known to Child Protection',
		disability: 'Has disability',
		welfare: 'Welfare concerns',
	};
	Object.keys(category_map).map(key =>
		stringInput.toLowerCase().indexOf(key) !== -1 ? output.push(category_map[key]) : null,
	);
	return output.join(', ');
}

export default formatPriorityCategory;
