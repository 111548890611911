import Hub from 'views/pages/hubs/Hub';
import Referral from 'views/pages/referrals/Referral';
import Profile from 'views/pages/profile/Profile';
import NewReferral from 'views/pages/referrals/NewReferral';
import AddUser from 'views/pages/users/AddUser';
import ProfileUpdate from 'views/pages/profile/ProfileUpdate';
import Notes from 'views/pages/profile/Notes';
import History from 'views/pages/profile/History';
import CloseCase from 'views/pages/profile/CloseCase';
import SubmitSurvey from 'views/pages/profile/SubmitSurvey';
import Reporting from 'views/pages/reporting/Reporting';
import ReportingList from 'views/pages/reporting/ReportingList';
import Uploads from 'views/pages/profile/Uploads';
import ApproveUsers from 'views/pages/users/ApproveUsers';
import PendingUsers from 'views/pages/users/PendingUsers';
import ApproveReopenRequests from 'views/pages/referrals/ApproveReopenRequests';
import PendingReopenRequests from 'views/pages/referrals/PendingReopenRequests';

import { userTiers } from 'config/userTiers';

const routes = [
	{
		path: '/hub',
		name: 'Hub',
		icon: 'ni ni-shop text-primary',
		component: Hub,
		hiddenSideNav: false,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/referral-form',
		name: 'New Referral',
		icon: 'ni ni-single-02 text-primary',
		component: NewReferral,
		hiddenSideNav: false,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/referral',
		name: 'Referral List',
		icon: 'ni ni-bullet-list-67 text-primary',
		component: Referral,
		hiddenSideNav: false,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/reporting-list',
		name: 'Reporting',
		icon: 'ni ni-sound-wave text-primary',
		component: ReportingList,
		hiddenSideNav: false,
		rank: userTiers.STAFF_TABLEAU.rank,
	},
	{
		path: '/report/:id',
		name: 'Report',
		icon: 'ni ni-sound-wave text-primary',
		component: Reporting,
		hiddenSideNav: true,
		rank: userTiers.STAFF_TABLEAU.rank,
	},
	{
		path: '/add-user',
		name: 'Add User',
		icon: 'ni ni-lock-circle-open text-primary',
		component: AddUser,
		hiddenSideNav: false,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/approve-users',
		name: 'Approve Users',
		icon: 'ni ni-lock-circle-open text-primary',
		component: ApproveUsers,
		hiddenSideNav: true,
		rank: userTiers.COORD.rank,
	},
	{
		path: '/pending-users',
		name: 'Pending Users',
		icon: 'ni ni-lock-circle-open text-primary',
		component: PendingUsers,
		hiddenSideNav: true,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/approve-reopen-requests',
		name: 'Approve Reopen Requests',
		icon: 'ni ni-lock-circle-open text-primary',
		component: ApproveReopenRequests,
		hiddenSideNav: true,
		rank: userTiers.COORD.rank,
	},
	{
		path: '/pending-reopen-requests',
		name: 'Pending Reopen Requests',
		icon: 'ni ni-lock-circle-open text-primary',
		component: PendingReopenRequests,
		hiddenSideNav: true,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/profile/:id',
		name: 'Profile',
		icon: 'ni ni-circle-08 text-primary',
		component: Profile,
		hiddenSideNav: true,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/profile-update/:id',
		name: 'Update Profile',
		icon: 'ni ni-single-02 text-primary',
		component: ProfileUpdate,
		hiddenSideNav: true,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/profile-notes/:id',
		name: 'Profile Notes',
		icon: 'ni ni-single-02 text-primary',
		component: Notes,
		hiddenSideNav: true,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/profile-history/:id/:type',
		name: 'Profile History',
		icon: 'ni ni-single-02 text-primary',
		component: History,
		hiddenSideNav: true,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/profile-close-case/:id',
		name: 'Close Case',
		icon: 'ni ni-single-02 text-primary',
		component: CloseCase,
		hiddenSideNav: true,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/profile-submit-survey/:id',
		name: 'Submit Survey',
		icon: 'ni ni-single-02 text-primary',
		component: SubmitSurvey,
		hiddenSideNav: true,
		rank: userTiers.STAFF.rank,
	},
	{
		path: '/profile-uploads/:id',
		name: 'Profile Uploads',
		icon: 'ni ni-single-02 text-primary',
		component: Uploads,
		hiddenSideNav: true,
		rank: userTiers.STAFF.rank,
	},
];

export default routes;
