import { useState } from 'react';
import { Col, Row, TabContent, TabPane, Nav } from 'reactstrap';
import StackedBar from 'components/Plots/StackedBar';
import NavigationPill from 'components/Buttons/NavigationPill';
import ChartWrapper from 'components/Plots/ChartWrapper';
import NoChartData from 'components/Plots/NoChartData';
import { chartNotEmpty } from 'utils/functions';

const OutstandingAdmin = ({ data, dataFilters, keys, currentView, areas }) => {
	const [activeTab, setActiveTab] = useState('surveys');

	let surveys, plans, files;
	if (dataFilters.area) {
		surveys = data?.surveys[dataFilters.area];
		plans = data?.plans[dataFilters.area];
		files = data?.files[dataFilters.area];
	} else {
		surveys = data?.surveys['All Areas'];
		plans = data?.plans['All Areas'];
		files = data?.files['All Areas'];
	}

	const surveyChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: surveys,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={surveys ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Exit and Entry surveys'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'surveys'}
				/>
			</ChartWrapper>
		),
	});

	const planChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: plans,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={plans ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Other required documents that are incomplete'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'surveys'}
				/>
			</ChartWrapper>
		),
	});

	const filesChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: files,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={files ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Files not updated in the last 4 weeks'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'files'}
				/>
			</ChartWrapper>
		),
	});

	const chartArray = [
		{
			tabName: 'surveys',
			tabText: 'Exit and Entry surveys',
			chart: surveyChart,
		},
		{
			tabName: 'plans',
			tabText: 'Other required documents that are incomplete',
			chart: planChart,
		},
		{
			tabName: 'files',
			tabText: 'Files not updated in the last 4 weeks',
			chart: filesChart,
		},
	];

	const navSidebar = chartArray.map((chart, index) => (
		<Row key={index}>
			<Col>
				<NavigationPill activeTab={activeTab} changeActiveTab={setActiveTab} tabName={chart.tabName}>
					{chart.tabText}
				</NavigationPill>
			</Col>
		</Row>
	));

	const chartTabPanes = chartArray.map((chart, index) => (
		<TabPane key={index} tabId={chart.tabName}>
			{chart.chart}
		</TabPane>
	));

	return (
		<Row className="mt-3">
			<Col xs={2}>
				<Nav className="nav-fill flex-column" id="tabs-icons-text" pills role="tablist">
					{navSidebar}
				</Nav>
			</Col>
			<Col xs={10}>
				<TabContent activeTab={activeTab}>{chartTabPanes}</TabContent>
			</Col>
		</Row>
	);
};

export default OutstandingAdmin;
