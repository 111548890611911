import React, { useState } from 'react';
import { Collapse, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

import ProfileRowGroup from 'views/pages/profile/ProfileRowGroup.js';

const ProfileCard = props => {
	const [collapsed, setCollapsed] = useState(true)

	const handleCollapse = () => setCollapsed(!collapsed)

	return (
		<div className="accordion">
			<Card>
				<CardHeader
					onClick={handleCollapse}
					aria-expanded={!collapsed}
				>
					<Row className="align-items-center">
						<Col xs="10">
							<h5 className="h3 mb-0">{props.data.title}</h5>
						</Col>
						<Col className="text-right" xs="4"></Col>
					</Row>
				</CardHeader>
				<Collapse isOpen={!collapsed} >
					<CardBody>
						<Row>
							<ProfileRowGroup data={props.data.rowGroup} />
						</Row>
					</CardBody>
				</Collapse>
			</Card>
		</div>
	);
};

export default ProfileCard;
