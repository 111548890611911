import React, { useState } from 'react';
import classnames from 'classnames';
import { Button, ButtonGroup } from 'reactstrap';

const RadioButtons = props => {
	const [radio, setRadio] = useState(0);
	const Buttons = props.buttonsArray.map((el, index) => (
		<Button
			key={index}
			className={classnames({ active: radio === index })}
			color="secondary"
			onClick={() => {
				setRadio(index);
				props.stateSetter(el.state);
			}}
		>
			{el.buttonText}
		</Button>
	));
	return (
		<>
			<ButtonGroup>{Buttons}</ButtonGroup>
		</>
	);
};

export default RadioButtons;
