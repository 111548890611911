import StackedBar from 'components/Plots/StackedBar';
import ChartWrapper from 'components/Plots/ChartWrapper';

const BriefIntervention = ({ data, dataFilters, keys, currentView, areas }) => {
	let values = dataFilters.area ? data[dataFilters.area] : data['All Areas'];
	return (
		<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="80vh">
			<StackedBar
				data={values ?? []}
				index={'status'}
				indices={areas}
				keys={keys}
				keyFilter={dataFilters.provider}
				xLabel={'Status of referrals who are in the pilot program'}
				xRotation={-15}
				yLabel={'Referrals'}
				view={currentView}
			/>
		</ChartWrapper>
	);
};

export default BriefIntervention;
