import { Row, Container } from 'reactstrap';

const NoChartData = ({ text }) => (
	<Row>
		<Container
			fluid
			className="justify-content-center align-items-center"
			style={{ height: '20vh', display: 'flex' }}
		>
			{text}
		</Container>
	</Row>
);

export default NoChartData;
