import { Col, Row, TabContent, TabPane, Nav } from 'reactstrap';
import { useState } from 'react';
import StackedBar from 'components/Plots/StackedBar';
import _ from 'underscore';
import NavigationPill from 'components/Buttons/NavigationPill';
import ChartWrapper from 'components/Plots/ChartWrapper';
import NoChartData from 'components/Plots/NoChartData';
import { chartNotEmpty } from 'utils/functions';

const checkEmptyDataArray = (data, key) => (!_.isEmpty(data[key]) ? data[key] : []);

const AverageTimes = ({ data, dataFilters, keys, currentView, areas }) => {
	console.log(data);
	const [activeTab, setActiveTab] = useState('RTE');
	let rte = checkEmptyDataArray(data, 'rte-times');
	let rteCount = checkEmptyDataArray(data, 'rte-times-count');
	let reref = checkEmptyDataArray(data, 'rereferrals');
	let rerefTimes = checkEmptyDataArray(data, 'rereferrals-times');
	let seeTimes = checkEmptyDataArray(data, 'see-times');
	let seeTimesCount = checkEmptyDataArray(data, 'see-times-count');
	let otherTimes = checkEmptyDataArray(data, 'other-times');
	let otherTimesCount = checkEmptyDataArray(data, 'other-times-count');
	let bcTimes = checkEmptyDataArray(data, 'bc-times');
	let bcTimesCount = checkEmptyDataArray(data, 'bc-times-count');

	let status, attendance, statusCount, attendanceCount;
	if (dataFilters.area) {
		status = data['status-times'][dataFilters.area];
		attendance = data['attendance-times'][dataFilters.area];
		statusCount = data['status-times-count'][dataFilters.area];
		attendanceCount = data['attendance-times-count'][dataFilters.area];
	} else {
		status = data?.['status-times']?.['All Areas'];
		attendance = data?.['attendance-times']?.['All Areas'];
		statusCount = data?.['status-times-count']?.['All Areas'];
		attendanceCount = data?.['attendance-times-count']?.['All Areas'];
	}

	const rteTimesChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: rte,
		component: (
			<>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
						data={rte ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={'Average time in days required to return to education'}
						yLabel={'Time in days'}
						yLabelOffset={-60}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'rteTimes'}
					/>
				</ChartWrapper>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
						data={rteCount ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={'Young persons that have returned to education'}
						yLabel={'Number of young persons'}
						yLabelOffset={-60}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'rteTimes'}
					/>
				</ChartWrapper>
			</>
		),
	});

	const rerefTimesChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: rerefTimes,
		component: (
			<>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
						data={rerefTimes ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={'Average days between an exit status and Re-referral by DET Areas'}
						yLabel={'Time in days'}
						yLabelOffset={-60}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'rerefTimes'}
					/>
				</ChartWrapper>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
						data={reref ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={'Number of Re-referrals by DET Areas'}
						yLabel={'Number of Re-Referrals'}
						yLabelOffset={-60}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'reref'}
					/>
				</ChartWrapper>
			</>
		),
	});

	const statusTimesChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: status,
		component: (
			<>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 140, left: 100 }}
						data={status ?? []}
						index={'status'}
						indices={areas}
						keys={keys}
						keyFilter={dataFilters.provider}
						xLabel={'Average time in days spent in each status'}
						xLabelOffset={100}
						xRotation={-15}
						legendAnchor="bottom-right"
						yLabel={'Time in days'}
						yLabelOffset={-60}
						view={currentView}
						totalsTableId={'statusTimes'}
					/>
				</ChartWrapper>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 140, left: 100 }}
						data={statusCount ?? []}
						index={'status'}
						indices={areas}
						keys={keys}
						keyFilter={dataFilters.provider}
						xLabel={'Number of young persons separated by status'}
						xLabelOffset={100}
						xRotation={-15}
						legendAnchor="bottom-right"
						yLabel={'Number of young persons'}
						yLabelOffset={-60}
						view={currentView}
						totalsTableId={'statusTimes'}
					/>
				</ChartWrapper>
			</>
		),
	});

	const attendanceTimesChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: attendance,
		component: (
			<>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 140, left: 100 }}
						data={attendance ?? []}
						index={'status'}
						indices={areas}
						keys={keys}
						keyFilter={dataFilters.provider}
						xLabel={'Average time in days to achieve higher attendance statuses'}
						xLabelOffset={100}
						xRotation={-15}
						legendAnchor="bottom-right"
						yLabel={'Time in days'}
						yLabelOffset={-60}
						view={currentView}
						totalsTableId={'attendanceTimes'}
					/>
				</ChartWrapper>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 140, left: 100 }}
						data={attendanceCount ?? []}
						index={'status'}
						indices={areas}
						keys={keys}
						keyFilter={dataFilters.provider}
						xLabel={'Number of young persons that achieved higher attendance statuses by status'}
						xLabelOffset={100}
						xRotation={-15}
						legendAnchor="bottom-right"
						yLabel={'Number of young persons'}
						yLabelOffset={-60}
						view={currentView}
						totalsTableId={'attendanceTimes'}
					/>
				</ChartWrapper>
			</>
		),
	});

	const seeTimesChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: seeTimes,
		component: (
			<>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
						data={seeTimes ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={'Average time in days to achieve sustained educational engagement'}
						yLabel={'Time in days'}
						yLabelOffset={-60}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'seeTimes'}
					/>
				</ChartWrapper>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
						data={seeTimesCount ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={'Number of young persons that have achieved sustained educational engagement'}
						yLabel={'Number of young persons'}
						yLabelOffset={-60}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'seeTimes'}
					/>
				</ChartWrapper>
			</>
		),
	});

	const otherTimesChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: otherTimes,
		component: (
			<>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
						data={otherTimes ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={'Average time in days to achieve exit (other engagement)'}
						yLabel={'Time in days'}
						yLabelOffset={-60}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'otherTimes'}
					/>
				</ChartWrapper>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
						data={otherTimesCount ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={'Number of young persons that achieved exit (other engagement)'}
						yLabel={'Number of young persons'}
						yLabelOffset={-60}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'otherTimes'}
					/>
				</ChartWrapper>
			</>
		),
	});

	const bcTimesChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: bcTimes,
		component: (
			<>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
						data={bcTimes ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={'Average time in days to reach exit (before completion)'}
						yLabel={'Time in days'}
						yLabelOffset={-60}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'bcTimes'}
					/>
				</ChartWrapper>
				<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
					<StackedBar
						margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
						data={bcTimesCount ?? []}
						index={'DETArea'}
						indices={areas}
						indexFilter={dataFilters.area}
						keys={keys}
						keyFilter={dataFilters.provider}
						xRotation={-15}
						xLabel={'Number of young persons that exit before completion'}
						yLabel={'Number of young persons'}
						yLabelOffset={-60}
						legendAnchor="bottom-right"
						xLabelOffset={75}
						view={currentView}
						totalsTableId={'bcTimes'}
					/>
				</ChartWrapper>
			</>
		),
	});

	const chartArray = [
		{
			tabName: 'RTE',
			tabText: 'Average Time to Return to Education',
			chart: rteTimesChart,
		},
		{
			tabName: 'status',
			tabText: 'Average Time Spent in Each Status',
			chart: statusTimesChart,
		},
		{
			tabName: 'attendance',
			tabText: 'Average Time to Achieve Higher Attendance Statuses',
			chart: attendanceTimesChart,
		},
		{
			tabName: 'rerefTimes',
			tabText: 'Average Time between Exit Status and Re-referral',
			chart: rerefTimesChart,
		},
		{
			tabName: 'seeTimes',
			tabText: 'Average Time to achieve Sustained Educational Engagement',
			chart: seeTimesChart,
		},
		{
			tabName: 'otherTimes',
			tabText: 'Average Time to achieve Exit Other Engagement',
			chart: otherTimesChart,
		},
		{
			tabName: 'bcTimes',
			tabText: 'Average Time to reach Exit Before Completion',
			chart: bcTimesChart,
		},
	];

	const navSidebar = chartArray.map((chart, index) => (
		<Row key={index}>
			<Col>
				<NavigationPill activeTab={activeTab} changeActiveTab={setActiveTab} tabName={chart.tabName}>
					{chart.tabText}
				</NavigationPill>
			</Col>
		</Row>
	));

	const chartTabPanes = chartArray.map((chart, index) => (
		<TabPane key={index} tabId={chart.tabName}>
			{chart.chart}
		</TabPane>
	));

	return (
		<Row className="mt-3">
			<Col xs={2}>
				<Nav className="nav-fill flex-column" id="tabs-icons-text" pills role="tablist">
					{navSidebar}
				</Nav>
			</Col>
			<Col xs={10}>
				<TabContent activeTab={activeTab}>{chartTabPanes}</TabContent>
			</Col>
		</Row>
	);
};

export default AverageTimes;
