import { Col, Row, TabContent, TabPane, Nav } from 'reactstrap';
import { useState } from 'react';
import StackedBar from 'components/Plots/StackedBar';
import NavigationPill from 'components/Buttons/NavigationPill';
import ChartWrapper from 'components/Plots/ChartWrapper';
import NoChartData from 'components/Plots/NoChartData';
import { chartNotEmpty } from 'utils/functions';

const OutcomesByCohort = ({ data, dataFilters, keys, currentView, areas }) => {
	const [activeTab, setActiveTab] = useState('atsi');
	let atsi, oohc, yj, disability, refugee, male, female, nonb;
	if (dataFilters.area) {
		atsi = data['atsi'][dataFilters.area];
		oohc = data['oohc'][dataFilters.area];
		yj = data['yj'][dataFilters.area];
		disability = data['disability'][dataFilters.area];
		refugee = data['refugee'][dataFilters.area];
		male = data['male'][dataFilters.area];
		female = data['female'][dataFilters.area];
		nonb = data['nonb'][dataFilters.area];
	} else {
		atsi = data['atsi']['All Areas'];
		oohc = data['oohc']['All Areas'];
		yj = data['yj']['All Areas'];
		disability = data['disability']['All Areas'];
		refugee = data['refugee']['All Areas'];
		male = data['male']['All Areas'];
		female = data['female']['All Areas'];
		nonb = data['nonb']['All Areas'];
	}

	// manually calculate the data for male, filtered and non-binary
	let maleFiltered, femaleFiltered, nonbFiltered;
	if (dataFilters.provider) {
		maleFiltered = male.map(el => ({ status: el.status, Male: el[dataFilters.provider] ?? 0 }));
		femaleFiltered = female.map(el => ({ status: el.status, Female: el[dataFilters.provider] ?? 0 }));
		nonbFiltered = nonb.map(el => ({ status: el.status, 'Non-Binary': el[dataFilters.provider] ?? 0 }));
	} else {
		maleFiltered = male.map(el => ({
			status: el.status,
			Male: Object.keys(el).reduce((a, b) => (b !== 'status' ? a + el[b] : a), 0),
		}));
		femaleFiltered = female.map(el => ({
			status: el.status,
			Female: Object.keys(el).reduce((a, b) => (b !== 'status' ? a + el[b] : a), 0),
		}));
		nonbFiltered = nonb.map(el => ({
			status: el.status,
			'Non-Binary': Object.keys(el).reduce((a, b) => (b !== 'status' ? a + el[b] : a), 0),
		}));
	}

	let genderKeys = ['Male', 'Female', 'Non-Binary'];
	let genderData = [...maleFiltered];
	for (const outerObj of femaleFiltered) {
		if (genderData.find(el => el.status === outerObj.status)) {
			genderData = genderData.map(innerObj =>
				innerObj.status === outerObj.status ? { ...innerObj, Female: outerObj.Female } : { ...innerObj },
			);
		} else {
			genderData.push({ ...outerObj });
		}
	}

	for (const outerObj of nonbFiltered) {
		if (genderData.find(el => el.status === outerObj.status)) {
			genderData = genderData.map(innerObj =>
				innerObj.status === outerObj.status
					? { ...innerObj, 'Non-Binary': outerObj['Non-Binary'] }
					: { ...innerObj },
			);
		} else {
			genderData.push({ ...outerObj });
		}
	}

	// sort the gender according to the status
	genderData.sort((a, b) => (a.status < b.status ? -1 : a.status > b.status ? 1 : 0));

	const atsiChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: atsi,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={atsi ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Current Statuses of Referrals'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'atsi'}
				/>
			</ChartWrapper>
		),
	});

	const oohcChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: oohc,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={oohc ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Current Statuses of Referrals'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'oohc'}
				/>
			</ChartWrapper>
		),
	});

	const yjChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: yj,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={yj ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Current Statuses of Referrals'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'yj'}
				/>
			</ChartWrapper>
		),
	});

	const disabilityChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: disability,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={disability ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Current Statuses of Referrals'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'disability'}
				/>
			</ChartWrapper>
		),
	});

	const refugeeChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: refugee,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={refugee ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Current Statuses of Referrals'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'refugee'}
				/>
			</ChartWrapper>
		),
	});

	const genderChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: genderData,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={genderData ?? []}
					index={'status'}
					indices={areas}
					keys={genderKeys}
					xRotation={-15}
					xLabel={'Current Statuses of Referrals'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'gender'}
					mode="grouped"
				/>
			</ChartWrapper>
		),
	});

	const chartArray = [
		{
			tabName: 'atsi',
			tabText: 'ATSI',
			chart: atsiChart,
		},
		{
			tabName: 'oohc',
			tabText: 'OOHC',
			chart: oohcChart,
		},
		{
			tabName: 'yj',
			tabText: 'YJ',
			chart: yjChart,
		},
		{
			tabName: 'disability',
			tabText: 'Disability',
			chart: disabilityChart,
		},
		{
			tabName: 'refugee',
			tabText: 'Refugee',
			chart: refugeeChart,
		},
		{
			tabName: 'gender',
			tabText: 'Gender',
			chart: genderChart,
		},
	];

	const navSidebar = chartArray.map((chart, index) => (
		<Row key={index}>
			<Col>
				<NavigationPill activeTab={activeTab} changeActiveTab={setActiveTab} tabName={chart.tabName}>
					{chart.tabText}
				</NavigationPill>
			</Col>
		</Row>
	));

	const chartTabPanes = chartArray.map((chart, index) => (
		<TabPane key={index} tabId={chart.tabName}>
			{chart.chart}
		</TabPane>
	));

	return (
		<Row className="mt-3">
			<Col xs={2}>
				<Nav className="nav-fill flex-column" id="tabs-icons-text" pills role="tablist">
					{navSidebar}
				</Nav>
			</Col>
			<Col xs={10}>
				<TabContent activeTab={activeTab}>{chartTabPanes}</TabContent>
			</Col>
		</Row>
	);
};

export default OutcomesByCohort;
