import React from 'react';
import { Row, Col } from 'reactstrap';

const ProfileRow = props => {
	return (
		<>
			<Row>
				{props.textWidth > 0 && (
					<Col lg={props.textWidth}>
						<h5 className="h4 mb-2">{props.name}</h5>
					</Col>
				)}
				<Col lg={props.dataWidth}>
					{props.data !== '' && <p className="text-sm mb-2">{props.data}</p>}
					{props.data === '' && <span>&nbsp;</span>}
				</Col>
			</Row>
		</>
	);
};
export default ProfileRow;
