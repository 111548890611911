import React from 'react';
import { Button, Row } from 'reactstrap';


const ProfileTopCardButton = props => {
    return (
        <Row>
            <Button
                block
                color="primary"
                size="sm"
                type="button"
                className="mb-1"
                onClick={props.onClick}
            >
                {props.buttonText}
            </Button>
        </Row>
    )
}

export default ProfileTopCardButton;