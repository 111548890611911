import React, { useState, useEffect } from 'react';
import { Card, CardTitle, CardBody, Container, Col, Row, Modal, Button } from 'reactstrap';

import PageHeader from 'components/Headers/PageHeader';
import Loading from 'components/General/Loading';

import AddUserForm from './AddUserForm';
import { confirmAlert, adminConfirmAlert, errorAlert } from 'components/Alerts/AddUserAlerts';
import { userTiers } from 'config/userTiers';
import secureAPI from 'utils/secureAPI';

const NewUser = props => {
	const [alert, setAlert] = useState(null);
	const [allValid, setAllValid] = useState(false);
	const [applicant, setApplicant] = useState({});
	const [formOptions, setFormOptions] = useState({
		areas: [],
		providers: [],
	});
	const [loading, setLoading] = useState(true);

	const formStateFuncs = {
		alert: setAlert,
		user: setApplicant,
	};

	const handleSubmitApproval = e => {
		const requester = {
			userName: props.user.userName,
			organisation: props.user.organisation,
			email: props.user.email,
		};
		allValid ? confirmAlert(formStateFuncs, applicant, requester) : errorAlert(setAlert);
	};

	const handleAdminAddUser = e => {
		allValid ? adminConfirmAlert(formStateFuncs, applicant) : errorAlert(setAlert);
	};

	useEffect(() => {
		let canSet = true;
		secureAPI({ method: 'GET', endpoint: 'OPTIONS' })
			.then(response => {
				if (canSet) {
					setFormOptions(response.data.options);
					setLoading(false);
				}
			})
			.catch(err => console.log(err));
	}, []);

	return !loading ? (
		<>
			{alert}
			<PageHeader name="Add User" parentName="Hub" user={props.user} />

			<Container className="mt--6" fluid>
				<Card className="m-auto" style={{ maxWidth: '720px' }}>
					<CardBody>
						<Col>
							<CardTitle tag="h3" className="h2 font-weight-bold">
								Add User
							</CardTitle>
							<AddUserForm
								user={props.user}
								options={formOptions}
								setAllValid={setAllValid}
								applicant={applicant}
								updateApplicant={setApplicant}
							/>
						</Col>
						{userTiers.COORD.rank >= props.user.accessRank ? (
							<Button block color="primary" onClick={handleAdminAddUser}>
								{'Create user'}
							</Button>
						) : (
							<Button block color="primary" onClick={handleSubmitApproval}>
								{'Submit user for administrator approval'}
							</Button>
						)}
					</CardBody>
				</Card>
			</Container>
		</>
	) : (
		<Modal className="modal-dialog-centered" isOpen={loading}>
			<div className="modal-header">
				<h6 className="modal-title" id="modal-title-default">
					Loading User Form ....
				</h6>
			</div>
			<div className="modal-body">
				<Container>
					<Row>
						<Col className="text-center">
							<Loading />
						</Col>
					</Row>
				</Container>
			</div>
		</Modal>
	);
};

export default NewUser;
