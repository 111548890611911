import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Col, CardFooter, Nav } from 'reactstrap';

import ProfileRowGroup from 'views/pages/profile/ProfileRowGroup';
import ProfileTopCardButton from 'components/Buttons/ProfileTopCardButton';
import ProfileTopCardActionButton from 'components/Buttons/ProfileTopCardActionButton';
import { formatDate, formatDateTime } from 'utils/dateFormatter';
import isEmptyObject from 'utils/isEmptyObject';
import formatPriorityCategory from 'utils/formatPriorityCategory';
import NavigationPill from 'components/Buttons/NavigationPill';

const ProfileTopCard = props => {
	const [alert, setAlert] = useState(null);
	let history = useHistory();
	const refId = props.data['RefID'];

	const rg1 = {
		colSize: 5,
		textWidth: 6,
		dataWidth: 6,
		rows: [
			{ name: 'Student Key', data: props.data['StudentKey'] },
			{ name: 'Preferred name', data: props.data['Young person - preferred name'] },
			{ name: 'Age', data: props.data['Age'] },
			{ name: 'Date of birth', data: props.data['Date of Birth'] },
			{ name: 'School', data: props.data['Most recent school'] },
			{ name: 'Campus', data: props.data['Most recent campus'] },
			{ name: 'School - other', data: props.data['Most recent school (other)'] },
			{ name: 'Year level', data: props.data['Year level'] },
			{ name: 'Best contact at school', data: props.data['School contact - name'] },
			{
				name: 'Attendance rate at referral',
				data: props.data['AttendanceRate2'] ? `${props.data['AttendanceRate2']} %` : null,
			},
			{ name: 'Referral Date', data: formatDate(props.data['ReferralDate']) },
		],
		// ].filter(row => row.data?.trim()),
	};

	const priorityCohort = () => {
		let priority = props.data['Priority young person']?.trim();
		let category = props.data['YP categories (e.g. ATSI)']?.trim();

		return category ? `${priority ?? 'Unknown'} - ${formatPriorityCategory(category)}` : priority;
	};

	const rg2 = {
		colSize: 5,
		textWidth: 6,
		dataWidth: 6,
		rows: [
			{ name: 'School ID', data: props.data['SchoolID'] },
			{ name: '10-11 Yr Pilot Program', data: props.data['Pilot Program'] },
			{ name: 'Priority cohort', data: priorityCohort() },
			{ name: 'Status', data: props.data['Status'] },
			{ name: 'Consent to participate obtained', data: props.data['Intake and assessment - consent'] },
			{ name: 'Service Provider', data: props.data['Allocate provider'] },
			{ name: 'Case manager', data: props.data['Case manager'] },
			{ name: 'Worker safety concerns', data: props.data['Worker safety concerns'] },
			{ name: 'Entry survey completed and uploaded', data: props.data['EntrySurveyComplete'] },
			{ name: 'Exit survey completed and uploaded', data: props.data['ExitSurveyComplete'] },
			{ name: 'Referral ID', data: props.data['externalDataReference'] },
			{ name: 'Date of last NDMS update', data: formatDateTime(props.data['LastUpdate']) },
		],
		// ].filter(row => row.data?.trim()),
	};

	const handleUpdate = e => {
		history.push(`/profile-update/${refId}`);
	};
	const handleNotes = e => {
		history.push(`/profile-notes/${refId}`);
	};
	const handleHistory = e => {
		history.push(`/profile-history/${refId}/history`);
	};
	const handleTimeline = e => {
		history.push(`/profile-history/${refId}/timeline`);
	};
	const handleCloseCase = e => {
		history.push(`/profile-close-case/${refId}`);
	};
	const handleSubmitSurvey = e => {
		history.push(`/profile-submit-survey/${refId}`);
	};
	const handleUploads = e => {
		history.push(`/profile-uploads/${refId}`);
	};

	return (
		<>
			{alert}
			<Row className="card-wrapper">
				<Col lg="12">
					<Card>
						<CardHeader>
							<Row className="align-items-center">
								<Col xs="8">
									<h5 className="h2 mb-0">
										{props.data['Young person - first name']} {props.data['Young person - surname']}
									</h5>
								</Col>
								<Col className="text-right" xs="4">
									<ProfileTopCardActionButton
										user={props.user}
										funcs={{
											alert: setAlert,
											data: props.dataFunc,
										}}
										data={props.data}
										update={handleUpdate}
									/>
								</Col>
							</Row>
						</CardHeader>

						<CardBody>
							<Row>
								<ProfileRowGroup data={rg1} />
								<ProfileRowGroup data={rg2} />
								<Col lg="1">
									<ProfileTopCardButton onClick={handleNotes} buttonText="Notes" />
									<ProfileTopCardButton onClick={handleTimeline} buttonText="View Timeline" />
									<ProfileTopCardButton onClick={handleHistory} buttonText="View Updates" />
									<ProfileTopCardButton onClick={handleUploads} buttonText="View Uploads" />
									{props.data.Status === 'Closed' ? null : (
										<ProfileTopCardButton onClick={handleCloseCase} buttonText="Close Case" />
									)}
									<ProfileTopCardButton onClick={handleSubmitSurvey} buttonText="Submit Survey" />
								</Col>
							</Row>
						</CardBody>
						<CardFooter>
							<div className="nav-wrapper">
								<Nav
									className="nav-fill flex-column flex-md-row"
									id="tabs-icons-text"
									pills
									role="tablist"
								>
									<NavigationPill
										activeTab={props.activeTab}
										changeActiveTab={props.changeActiveTab}
										tabName="profile"
									>
										<i className="ni ni-collection mr-2" /> Profile
									</NavigationPill>
									<NavigationPill
										activeTab={props.activeTab}
										changeActiveTab={props.changeActiveTab}
										tabName="surveys"
									>
										<i className="ni ni-folder-17 mr-2" /> Surveys
									</NavigationPill>
									<NavigationPill
										activeTab={props.activeTab}
										changeActiveTab={props.changeActiveTab}
										tabName="supports"
									>
										<i className="ni ni-building mr-2" /> Supports
									</NavigationPill>
									{isEmptyObject(props.graveyard) ? null : (
										<NavigationPill
											activeTab={props.activeTab}
											changeActiveTab={props.changeActiveTab}
											tabName="graveyard"
										>
											<i className="ni ni-watch-time mr-2" /> Legacy Data
										</NavigationPill>
									)}
								</Nav>
							</div>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default ProfileTopCard;
