import { Col, Row, TabContent, TabPane, Nav } from 'reactstrap';
import { useState } from 'react';
import StackedBar from 'components/Plots/StackedBar';
import NavigationPill from 'components/Buttons/NavigationPill';
import ChartWrapper from 'components/Plots/ChartWrapper';
import NoChartData from 'components/Plots/NoChartData';
import { chartNotEmpty } from 'utils/functions';

const OutcomesOverTime = ({ data, dataFilters, keys, currentView, areas }) => {
	const [activeTab, setActiveTab] = useState('after3months');
	let after3months = dataFilters.area ? data['after-3months'][dataFilters.area] : data['after-3months']['All Areas'];
	let after6months = dataFilters.area ? data['after-6months'][dataFilters.area] : data['after-6months']['All Areas'];
	let after9months = dataFilters.area ? data['after-9months'][dataFilters.area] : data['after-9months']['All Areas'];
	let after12months = dataFilters.area
		? data['after-12months'][dataFilters.area]
		: data['after-12months']['All Areas'];
	let after18months = dataFilters.area
		? data['after-18months'][dataFilters.area]
		: data['after-18months']['All Areas'];

	const after3MonthsChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data for status after 3 months." />,
		values: after3months,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 140, left: 50 }}
					data={after3months ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-25}
					legendAnchor="bottom-right"
					yLabel={'Number of Referrals'}
					view={currentView}
					totalsTableId={'after-three-months'}
				/>
			</ChartWrapper>
		),
	});

	const after6MonthsChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data for status after 6 months." />,
		values: after6months,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 140, left: 50 }}
					data={after6months ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-25}
					legendAnchor="bottom-right"
					yLabel={'Number of Referrals'}
					view={currentView}
					totalsTableId={'after-six-months'}
				/>
			</ChartWrapper>
		),
	});

	const after9MonthsChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data for status after 9 months." />,
		values: after9months,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 140, left: 50 }}
					data={after9months ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-25}
					legendAnchor="bottom-right"
					yLabel={'Number of Referrals'}
					view={currentView}
					totalsTableId={'after-nine-months'}
				/>
			</ChartWrapper>
		),
	});

	const after12MonthsChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data for status after 12 months." />,
		values: after12months,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 140, left: 50 }}
					data={after12months ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-25}
					legendAnchor="bottom-right"
					yLabel={'Number of Referrals'}
					view={currentView}
					totalsTableId={'after-twelve-months'}
				/>
			</ChartWrapper>
		),
	});

	const after18MonthsChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data for status after 12 months." />,
		values: after18months,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 140, left: 50 }}
					data={after18months ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-25}
					legendAnchor="bottom-right"
					yLabel={'Number of Referrals'}
					view={currentView}
					totalsTableId={'after-eighteen-months'}
				/>
			</ChartWrapper>
		),
	});

	const chartArray = [
		{
			tabName: 'after3months',
			tabText: 'Status After 3 Months',
			chart: after3MonthsChart,
		},
		{
			tabName: 'after6months',
			tabText: 'Status After 6 Months',
			chart: after6MonthsChart,
		},
		{
			tabName: 'after9months',
			tabText: 'Status After 9 Months',
			chart: after9MonthsChart,
		},
		{
			tabName: 'after12months',
			tabText: 'Status After 12 Months',
			chart: after12MonthsChart,
		},
		{
			tabName: 'after18months',
			tabText: 'Status After 18 Months',
			chart: after18MonthsChart,
		},
	];

	const navSidebar = chartArray.map((chart, index) => (
		<Row key={index}>
			<Col>
				<NavigationPill activeTab={activeTab} changeActiveTab={setActiveTab} tabName={chart.tabName}>
					{chart.tabText}
				</NavigationPill>
			</Col>
		</Row>
	));

	const chartTabPanes = chartArray.map((chart, index) => (
		<TabPane key={index} tabId={chart.tabName}>
			{chart.chart}
		</TabPane>
	));

	return (
		<Row className="mt-3">
			<Col xs={2}>
				<Nav className="nav-fill flex-column" id="tabs-icons-text" pills role="tablist">
					{navSidebar}
				</Nav>
			</Col>
			<Col xs={10}>
				<TabContent activeTab={activeTab}>{chartTabPanes}</TabContent>
			</Col>
		</Row>
	);
};

export default OutcomesOverTime;
