import React from 'react';
import { Button } from 'reactstrap';
import { userTiers } from 'config/userTiers';
import { reopenCase, reopenRequest } from 'components/Alerts/ProfileAlerts';

const ProfileTopCardActionButton = props => {
    if (props.data.Status !== 'Closed') {
        return (
            <Button color="default" href="#" onClick={props.update} size="l">
                <i className="fas fa-edit" style={{ margin: '0 10px 0 0' }}></i>
                Update Profile
            </Button>
        )
    }
    
    let buttonText,
        buttonAction,
        icon,
        disabledState = false;

    if (props.user.accessRank > userTiers.COORD.rank && props.data.reopenRequest) {
        disabledState = true;
        icon = "fas fa-info-circle";
        buttonAction = () => {};
        buttonText = "Reopen Request Submitted";
    } else if (props.user.accessRank > userTiers.COORD.rank) {
        icon = "fas fa-lock-open";
        buttonText = "Request Reopen Case";
        buttonAction = () => reopenRequest({
            funcs: props.funcs,
            data: props.data,
            requester: {
                email: props.user.email,
                organisation: props.user.organisation,
                userName: props.user.userName
            }
        });
    } else {
        icon = "fas fa-lock-open";
        buttonText = "Reopen Case";
        buttonAction = () => reopenCase({
            funcs: props.funcs,
            data: props.data,
            user: props.user
        });
    };

    return (
        <Button color="default" href="#" onClick={buttonAction} size="l" disabled={disabledState}>
            <i className={icon} style={{ margin: '0 10px 0 0' }}></i>
            {buttonText}
        </Button>
    )
}

export default ProfileTopCardActionButton