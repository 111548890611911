import React from 'react';
import { confirmApprove, confirmDeny } from 'components/Alerts/AddUserAlerts';
import CellButton from 'components/Tables/CellButton';


export const ApproveButton = props => (
    <CellButton
        func={() => approveUser(props)}
        text="Approve"
        customProps={{
            style: {marginRight: "0.5rem", marginLeft: 0},
            color: "success"
        }}
    />
)

export const DenyButton = props => (
    <CellButton
        func={() => denyUser(props)}
        text="Deny"
        customProps={{
            style: {marginRight: 0, marginLeft: "0.5rem"},
            color: "warning"
        }}
    />
)

const approveUser = options => {
    const applicant = {
        firstName: options.data['FirstName'],
        lastName: options.data['LastName'],
        email: options.data['Email'],
        access: options.data['Access'],
        organisation: options.data['Organisation'],
        tableauAccess: options.data['TableauAccess'],
        detAreas: options.data['DETAreas'].split(',').map(value => ({area: value}))
    }
    confirmApprove({
        ...options,
        applicant: applicant,
    })
}


const denyUser = options => {
    const applicant = {
        firstName: options.data['FirstName'],
        lastName: options.data['LastName'],
        email: options.data['Email'],
        access: options.data['Access'],
        organisation: options.data['Organisation'],
        tableauAccess: options.data['TableauAccess'],
        detAreas: options.data['DETAreas'].split(',').map(value => ({area: value}))
    }
    confirmDeny({
        ...options,
        applicant: applicant,
    })
}