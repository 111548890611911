import React from 'react';
import { Button } from 'reactstrap';

const AddNotesButton = props => {
    const toggleModal = () => props.modalFunc(state => !state)

    return (
        <>
            <Button color="default" href="#" onClick={toggleModal} size="l">
                <i className="fas fa-edit" style={{ margin: '0 10px 0 0' }}></i>
                Add Notes
            </Button>
        </>
    )
}

export default AddNotesButton;
