import StackedBar from 'components/Plots/StackedBar';
import ChartWrapper from 'components/Plots/ChartWrapper';

const ClientSuccessRates = ({ data, dataFilters, keys, currentView, areas }) => {
	let values = dataFilters.area ? data[dataFilters.area] : data['All Areas'];
	return (
		<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="80vh">
			<StackedBar
				data={values ?? []}
				dataLabels={false}
				index={'rate'}
				indices={areas}
				keys={keys}
				keyFilter={dataFilters.provider}
				xLabel={'Outcome Category'}
				yLabel={'Percentage'}
				view={currentView}
				mode={'grouped'}
			/>
		</ChartWrapper>
	);
};

export default ClientSuccessRates;
