const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
// const BASE_API_URL = 'https://8ao6nk4la1.execute-api.ap-southeast-2.amazonaws.com/dev';
const BASE_QUALTRICS_URL = process.env.REACT_APP_BASE_QUALTRICS_URL;
const CASE_CLOSURE_FORM = process.env.REACT_APP_CASE_CLOSURE_FORM;
const NEW_REFERRAL_FORM = process.env.REACT_APP_NEW_REFERRAL_FORM;
const SUBMIT_SURVEY_FORM = process.env.REACT_APP_SUBMIT_SURVEY_FORM;
const UPDATE_REFERRAL_FORM = process.env.REACT_APP_UPDATE_REFERRAL_FORM;

export const Endpoints = {
	REFERRALS: `${BASE_API_URL}/reflist`,
	PROFILE: `${BASE_API_URL}/profile`,
	NOTES: `${BASE_API_URL}/notes`,
	HISTORY: `${BASE_API_URL}/history`,
	SURVEYS: `${BASE_API_URL}/survey`,
	UPLOADS: `${BASE_API_URL}/uploads`,
	SUPPORTS: `${BASE_API_URL}/supports`,
	REPORTING: `${BASE_API_URL}/reporting/`,
	OPTIONS: `${BASE_API_URL}/add-users/options`,
	APPROVE_USER: `${BASE_API_URL}/add-users/approval`,
	ADMIN_USER: `${BASE_API_URL}/add-users/admin`,
	REOPEN_CASE: `${BASE_API_URL}/reopen-ref/admin`,
	REOPEN_REQUEST: `${BASE_API_URL}/reopen-ref/approval`,
	LEGACY_REF: `${BASE_API_URL}/legacy/ref`,

	CLOSE_CASE: `${BASE_QUALTRICS_URL}/${CASE_CLOSURE_FORM}`,
	SUBMIT_SURVEY: `${BASE_QUALTRICS_URL}/${SUBMIT_SURVEY_FORM}`,
	NEW_REFERRAL: `${BASE_QUALTRICS_URL}/${NEW_REFERRAL_FORM}`,
	UPDATE_REFERRAL: `${BASE_QUALTRICS_URL}/${UPDATE_REFERRAL_FORM}`,

	APP:
		'http://localhost:3000/, https://app.naviprogram.com/, https://master.d2b9bwo6nvolfi.amplifyapp.com/, https://test.d2b9bwo6nvolfi.amplifyapp.com/, https://app.dev-naviprogram.com/, https://master.doi76v5yqqeye.amplifyapp.com/, https://dev.doi76v5yqqeye.amplifyapp.com/',
};
