import React from 'react';
import ProfileCard from 'components/Cards/ProfileCard';
import { Row, Col } from 'reactstrap';

const ProfileGraveyard = ({ data }) => {
	const graveyardInfo = {
		title: 'Young Person Data From Legacy System',
		link: '',
		colSize: 12,
		rowGroup: {
			colSize: 12,
			textWidth: 6,
			dataWidth: 6,
			rows: [
				{ name: 'Education barriers', data: data['Education barriers'] },
				{ name: 'Housing', data: data['Housing'] },
				{ name: 'Health barriers - consent', data: data['Health barriers - consent'] },
				{ name: 'Health barriers - consent details', data: data['Health barriers - consent details'] },
				{ name: 'Health barriers - consent date', data: data['Health barriers - consent date'] },
				{ name: 'Health barriers', data: data['Health barriers'] },
				{ name: 'Health barriers - other', data: data['Health barriers - other'] },
				{ name: 'Strengths/interests', data: data['Strengths/interests'] },
				{ name: 'Other Barriers/challenges', data: data['Other Barriers/challenges'] },
				{ name: 'Parent/Guardian/Carer - Relation', data: data['Parent/Guardian/Carer - Relation'] },
				{
					name: 'Parent/Guardian/Carer - Supportive of referral',
					data: data['Parent/Guardian/Carer - Supportive of referral'],
				},
				{ name: 'Consent provided - disability', data: data['Consent provided - disability'] },
				{ name: 'Consent provided - yes - disability', data: data['Consent provided - yes - disability'] },
				{ name: 'Consent provided - date - disability', data: data['Consent provided - date - disability'] },
				{
					name: 'Involved with DHHS Child Protection Services',
					data: data['Involved with DHHS Child Protection Services'],
				},
				{
					name: 'Under supervision of youth justice worker',
					data: data['Under supervision of youth justice worker'],
				},
				{
					name: 'Youth justice worker -  First Given Name',
					data: data['Youth justice worker -  First Given Name'],
				},
				{ name: 'Youth justice worker -  Surname', data: data['Youth justice worker -  Surname'] },
				{ name: 'Youth justice worker - Work phone', data: data['Youth justice worker - Work phone'] },
				{ name: 'Youth justice worker - Mobile phone', data: data['Youth justice worker - Mobile phone'] },
				{ name: 'Youth justice worker - Email address', data: data['Youth justice worker - Email address'] },
				{ name: 'Youth justice worker - Organisation', data: data['Youth justice worker - Organisation'] },
				{ name: 'Youth justice worker - job title', data: data['Youth justice worker - job title'] },
				{
					name: 'Sibling currently at the school / education setting',
					data: data['Sibling currently at the school / education setting'],
				},
				{ name: 'Sibling First Given Name', data: data['Sibling First Given Name'] },
				{ name: 'Sibling Surname', data: data['Sibling Surname'] },
				{ name: 'Sibling Date of birth', data: data['Sibling Date of birth'] },
				{
					name: 'Secondary Parent/Guardian/Carer - Relation to young person',
					data: data['Secondary Parent/Guardian/Carer - Relation to young person'],
				},
				{ name: 'Social and emotional barriers', data: data['Social and emotional barriers'] },
				{ name: 'Re-engagement plan', data: data['Re-engagement plan'] },
				{
					name: 'Student Support Services - Contact Name',
					data: data['Student Support Services - Contact Name'],
				},
				{
					name: 'Student Support Services - Contact Number',
					data: data['Student Support Services - Contact Number'],
				},
				{
					name: 'Student Support Services - Contact Email',
					data: data['Student Support Services - Contact Email'],
				},
				{ name: 'Country of Birth', data: data['Country of Birth'] },
				{ name: 'Sexual orientation', data: data['Sexual orientation'] },
				{ name: 'Sexual orientation (self described)', data: data['Sexual orientation (self described)'] },
				{ name: 'PSD Funding', data: data['PSD Funding'] },
				{
					name: 'Refugee or asylum seeker background or similar',
					data: data['Refugee or asylum seeker background or similar'],
				},
				{
					name: 'Currently in statutory out of home care',
					data: data['Currently in statutory out of home care'],
				},
				{ name: 'Out of home care type', data: data['Out of home care type'] },
				{ name: "Referrer's frequency of contact", data: data["Referrer's frequency of contact"] },
				{
					name: "Referrer's date of last communication",
					data: data["Referrer's date of last communication"],
				},
				{ name: 'Referrer - Job Title', data: data['Referrer - Job Title'] },
				{
					name: 'Housing, transport and financial barrier',
					data: data['Housing, transport and financial barrier'],
				},
				{ name: 'Out of home care type - Other', data: data['Out of home care type - Other'] },
				{ name: 'email', data: data['email'] },
				{ name: 'Intervention', data: data['Intervention'] },
				{ name: 'Active Hold', data: data['Active Hold'] },
				{ name: 'Active Hold - Other', data: data['Active Hold - Other'] },
				{ name: 'Close - Exit survey complete', data: data['Close - Exit survey complete'] },
				{ name: 'Close - Other supports', data: data['Close - Other supports'] },
				{ name: 'Close - Other supports (detail)', data: data['Close - Other supports (detail)'] },
				{ name: 'Close - Other supports (advised)', data: data['Close - Other supports (advised)'] },
				{ name: 'Close - Education provider (advised)', data: data['Close - Education provider (advised)'] },
				{ name: 'Close - Information up to date', data: data['Close - Information up to date'] },
			].filter(el => el.data),
		},
	};
	return (
		<Row className="card-wrapper">
			<Col lg="12">
				<ProfileCard data={graveyardInfo} />
			</Col>
		</Row>
	);
};

export default ProfileGraveyard;
