import { Col, Row } from 'reactstrap';
import StackedBar from 'components/Plots/StackedBar';
import ChartWrapper from 'components/Plots/ChartWrapper';
import NoChartData from 'components/Plots/NoChartData';
import { chartNotEmpty } from 'utils/functions';

const InternalSupports = ({ data, dataFilters, keys, currentView, areas }) => {
	let presupports = dataFilters.area ? data.presupports[dataFilters.area] : data.presupports['All Areas'];
	let postsupports = dataFilters.area ? data.postsupports[dataFilters.area] : data.postsupports['All Areas'];

	const presupportsChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: presupports,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 70, left: 120 }}
					data={presupports ?? []}
					index={'supports'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xLabel={'Pre-Referral Internal Supports Provided'}
					xLabelOffset={60}
					xRotation={-20}
					legendX={110}
					yLabel={'Referrals'}
					view={currentView}
					totalsTableId={'presupports'}
				/>
			</ChartWrapper>
		),
	});

	const postsupportsChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: postsupports,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="80vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 70, left: 120 }}
					data={postsupports ?? []}
					index={'supports'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xLabel={'Post-Referral Internal Supports Provided'}
					xLabelOffset={60}
					xRotation={-20}
					legendX={110}
					yLabel={'Referrals'}
					view={currentView}
					totalsTableId={'postsupports'}
				/>
			</ChartWrapper>
		),
	});

	return (
		<div>
			<Row>
				<Col>{presupportsChart}</Col>
			</Row>
			<Row>
				<Col>{postsupportsChart}</Col>
			</Row>
		</div>
	);
};

export default InternalSupports;
