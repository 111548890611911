import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from 'reactstrap';

const ReportingHeader = props => {
	return (
		<>
			<div className="header bg-info pb-6">
				<Container fluid>
					<div className="header-body">
						<Row className="align-items-center py-4">
							<Col lg="6" xs="7">
								<h6 className="h2 text-white d-inline-block mb-0">{props.name}</h6>
								<Breadcrumb
									className="d-none d-md-inline-block ml-md-4"
									listClassName="breadcrumb-links breadcrumb-dark"
								>
									<BreadcrumbItem>
										<Link to={`/hub`}>
											<i className="fas fa-home" />
										</Link>
									</BreadcrumbItem>
									<BreadcrumbItem>
										<Link to={`/reporting-list`}>{props.parentName}</Link>
									</BreadcrumbItem>
									<BreadcrumbItem aria-current="page" className="active">
										{props.name}
									</BreadcrumbItem>
								</Breadcrumb>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</>
	);
};

// ProfileHeader.propTypes = {
// 	name: PropTypes.string,
// 	parentName: PropTypes.string,
// };

export default ReportingHeader;

