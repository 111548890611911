import {useState, React} from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card,  TabContent, TabPane,Nav, CardBody} from 'reactstrap';
import NavigationPill from 'components/Buttons/NavigationPill';

import TableauChart from './TableauChart';
import NivoChart from './NivoChart';
import ReportError from 'views/pages/errors/ReportError';
import ReportingHeader from 'components/Headers/ReportingHeader';

import { Reports } from 'config/reports';

const Reporting = props => {
	
	const params = useParams();

	const report = Reports.find(el => el.id === params.id)
	const requireSideBar = report.tabs?.length > 1;

	const [activeTab, setActiveTab] =  useState(requireSideBar ?report.tabs[0].tabName : '');

	const navSidebar = requireSideBar ? ( 
			report.tabs.map((tab, index) => (
				<Row key={index}>
					<Col>
						<NavigationPill activeTab={activeTab} changeActiveTab={setActiveTab} tabName={tab.tabName}>
							{tab.tabText}
						</NavigationPill>
					</Col>
				</Row>
			))
	) : (
		undefined
	);

	const chartTabPanes =  requireSideBar ? (
		report.tabs.map((tab, index) => (
			<TabPane key={index} tabId={tab.tabName}>
				<TableauChart user =  {props.user} url = {tab.url} isActiveTab = {tab.tabName === activeTab}/>
			</TabPane>
		))
	) : (
		undefined
	)

	const url = !requireSideBar ? report.tabs[0]?.url : undefined;
	

	const TableauChartwithSidebar = () => {
		return(
			<>
				<ReportingHeader name= {report.title} parentName="Reporting List" user={props.user} />
								<Container className="mt--6" fluid>
									<Card>
										<CardBody>
											<Row>
												<Col xs={2}>
													<Nav className="nav-fill flex-column" id="tabs-icons-text" pills role="tablist">
														{navSidebar}
													</Nav>
												</Col>
												<Col xs={10}>
													<TabContent activeTab={activeTab}>{chartTabPanes}</TabContent>
												</Col>
											</Row>

										</CardBody>
									</Card>
				</Container>
			</>
		)
	};

	const TableauChartwithoutSidebar = () => {
		return(
			<>
				<ReportingHeader name= {report.title} parentName="Reporting List" user={props.user} />

				<Container className="mt--6" fluid>
					<Row>
						<Col>
							<TableauChart user =  {props.user} url = {url} isActiveTab = {true}/>
						</Col>
					</Row>
				</Container>
						
			</>
		)
	};

	const NivoReport = () => {
		return (
			<>
				<ReportingHeader name= {report.title} parentName="Reporting List" user={props.user} />

				<NivoChart {...props}/>
			</>
			
		)
	}
	const ReportErrorUnauthorised = () => {
		return (
			<ReportError user={props.user} message="Unauthorised" />
		)
		
	}

	const ReportErrorInvalid = () => {
		return (
			<ReportError user={props.user} message="Invalid Report" />
		)
		
	}

	let validAccess = props.user.accessRank <= report?.access
	let reportExists = report !== undefined || report !== null
	let ChartExists = !report.fallback? report.tabs.length > 0 : report.chart !== undefined

	const renderTableauChart = requireSideBar? <TableauChartwithSidebar/> : <TableauChartwithoutSidebar/>
	const renderChart = !report.fallback? renderTableauChart : <NivoReport/>
	const renderError = !validAccess? <ReportErrorUnauthorised /> : <ReportErrorInvalid /> ;
	const renderReport = (validAccess && reportExists && ChartExists)? renderChart : renderError
	
	return  (
		renderReport
	);
};

export default Reporting;
