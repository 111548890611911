import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { createTableColumns, createTotalsInArrayObjects, removeTotalsInArrayObjects } from 'utils/functions';
import { Container, Row, Col } from 'reactstrap';

import NoDataIndication from 'components/Tables/NoDataIndication';
import CopyTable from 'components/Tables/CopyTable';

const ChartTotalsTable = props => {
	const [alert, setAlert] = useState(null);
	const tableRef = useRef();
	const [tableElement, setTableElement] = useState(document.getElementById('totals-table'));

	const tableData =
		props.type === 'percent'
			? removeTotalsInArrayObjects([...props.data])
			: createTotalsInArrayObjects([...props.data]);
	const tableId = props.tableId;
	const tableLabel = props.label;

	const cols =
		props.type === 'percent'
			? [
					{
						dataField: props.index,
						text: props.index,
					},
					...createTableColumns(props.keys, false),
			  ]
			: [
					{
						dataField: props.index,
						text: props.index,
						footer: 'Totals',
					},
					...createTableColumns(props.keys, true),
					...createTableColumns(['total'], true),
			  ];

	useEffect(() => {
		//force a deep rendering, as totals-table element renders after the copy table button.
		setTableElement(document.getElementById(tableId ?? 'totals-table'));
	}, [tableRef, tableId]);

	let tableIndex = props.index;

	return (
		<ToolkitProvider data={tableData} keyField={tableIndex} columns={cols}>
			{props => (
				<div className="py-2 table-responsive">
					{alert}
					<Container className="py-2" fluid>
						<Row>
							<Col xs={12} sm={2}>
								<CopyTable tableElement={tableElement} alertFunc={setAlert} />
							</Col>
							<Col style={{ textAlign: 'center' }}>
								<h3 className="h3 mb-0">{tableLabel}</h3>
							</Col>
						</Row>
					</Container>
					<BootstrapTable
						id={tableId ?? 'totals-table'}
						keyField={tableIndex}
						ref={tableRef}
						bootstrap4={true}
						bordered={true}
						noDataIndication={() => <NoDataIndication loading={false} />}
						{...props.baseProps}
					/>
				</div>
			)}
		</ToolkitProvider>
	);
};

export default ChartTotalsTable;
