import _ from 'underscore';

export function getSortedDistinct(array, key) {
	return [...new Set(array.map(el => el[key]))].sort().reduce(function(result, item, index, array) {
		if (item && item !== undefined) {
			result[item] = item;
		}
		return result;
	}, {});
}

export function getAllExcept(array, keys) {
	return [...new Set(array.map(el => Object.keys(el).filter(key => !keys.includes(key))).flat())];
}

export function createTableColumns(array, footer = false) {
	return array.map(el => ({
		dataField: el,
		text: el,
		align: 'center',
		footer: footer
			? columnData =>
					columnData.reduce((acc, curr) => {
						curr = curr === undefined ? 0 : curr;
						return acc + curr;
					}, 0)
			: undefined,
		footerAlign: 'center',
	}));
}

export function createTotalsInArrayObjects(array) {
	return array.map(el => {
		if (el['total'] !== undefined) {
			return el;
		}
		el['total'] = Object.values(el).reduce((acc, curr) => {
			curr = typeof curr !== 'number' ? 0 : curr;
			return acc + curr;
		}, 0);
		return el;
	});
}

export function removeTotalsInArrayObjects(array) {
	return array.map(({ total, ...item }) => item);
}

export function getTspanGroups(value, maxLineLength, maxLines = 2) {
	// Add the following property to the axisBottom for SVG charts

	// renderTick: ({ opacity, textAnchor, textBaseline, textX, textY, theme, value, x, y }) => {
	// 	return (
	// 		<g transform={`translate(${x},${y})`} style={{ opacity }}>
	// 			<text
	// 				alignmentBaseline={textBaseline}
	// 				style={theme.axis.ticks.text}
	// 				textAnchor={textAnchor}
	// 				transform={`translate(${textX},${textY})`}
	// 			>
	// 				{getTspanGroups(value)}
	// 			</text>
	// 		</g>
	// 	);
	// },

	const words = value.split(' ');

	//reduces the words into lines of maxLineLength
	const assembleLines = words.reduce(
		(acc, word) => {
			//if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
			if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
				return {
					lines: acc.lines.concat([acc.currLine]),
					currLine: word,
				};
			}
			//otherwise add the word to the current line
			return {
				...acc,
				currLine: acc.currLine + ' ' + word,
			};
		},
		{ lines: [], currLine: '' },
	);

	//add the ending state of current line (the last line) to lines
	const allLines = assembleLines.lines.concat([assembleLines.currLine]);

	//for now, only take first 2 lines due to tick spacing and possible overflow
	const lines = allLines.slice(0, maxLines);
	let children = [];
	let dy = 0;

	lines.forEach((lineText, i) => {
		children.push(
			<tspan x={0} dy={dy} key={i}>
				{// if on the second line, and that line's length is within 3 of the max length, add ellipsis
				1 === i && allLines.length > 2 ? lineText.slice(0, maxLineLength - 3) + '...' : lineText}
			</tspan>,
		);
		//increment dy to render next line text below
		dy += 15;
	});

	return children;
}

export const chartNotEmpty = options => (!_.isEmpty(options.values) ? options.component : options.noDataComponent);
