import React from 'react';
import ReactIframeResizer from 'react-iframe-resizer-super';
import { useState, useEffect } from 'react';
import { Card, Container, Row, Col, Modal } from 'reactstrap';
import { useParams } from 'react-router-dom';

import ProfileUpdateHeader from 'components/Headers/ProfileUpdateHeader';
import Loading from 'components/General/Loading';
import { Endpoints } from 'config/endpoints';
import { iframeOptions, iframeStyles } from 'config/iframes';
import ProfileErrorPage from 'views/pages/errors/ProfileErrorPage';
import secureAPI from 'utils/secureAPI';

const ProfileUpdate = props => {
	const [name, setName] = useState(['']);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);

	let params = useParams();

	const updateUrl = `${Endpoints.UPDATE_REFERRAL}?RefID=${params.id}&userEmail=${props.user.email}`

	useEffect(() => {
		secureAPI(
			{ method: 'POST', endpoint: 'PROFILE'},
			{ RefID: params.id }
		)
			.then(response => {
				setData(response.data);
				let firstname = response.data['Young person - first name'] ?? '';
				let surname = response.data['Young person - surname'] ?? '';
				setName(`${firstname} ${surname}`);
				setLoading(false);
			})
			.catch(error => {
				console.log(error);
			});

	}, [params.id]);
	
	if (data.Status === 'Closed' || data.status === 'invalid') {
		return <ProfileErrorPage message={"Profile update unavailable"} user={props.user} />
	}

	return !loading ? (
		<>
			<ProfileUpdateHeader name="Update Profile" id={params.id} parentName={name} user={props.user} />
			<Container className="mt--6" fluid>
				<Row>
					<Col>
						<Card
							style={{
								height: '76vh',
								background: '#F3F3F3',
							}}
						>
							<ReactIframeResizer
								iframeResizerOptions={iframeOptions}
								iframeResizerUrl={false}
								src={updateUrl}
								style={iframeStyles}
							></ReactIframeResizer>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	) : (
		<>
			<Modal className="modal-dialog-centered" isOpen={loading}>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Loading Profile Update...
					</h6>
				</div>
				<div className="modal-body">
					<Container>
						<Row>
							<Col className="text-center">
								<Loading />
							</Col>
						</Row>
					</Container>
				</div>
			</Modal>
		</>
	);
};

export default ProfileUpdate;
