import React from 'react';
import { Button } from 'reactstrap';


const CellButton = props => (
    <Button
        size="sm"
        onClick={props.func}
        {...props.customProps}
    >
        {props.text}
    </Button>
)

export default CellButton