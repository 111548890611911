import React from 'react';
import ReferralNumbers from 'views/pages/reporting/dashboards/ReferralNumbers';
import ActiveHold from 'views/pages/reporting/dashboards/ActiveHold';
import CaseManagement from 'views/pages/reporting/dashboards/CaseManagement';
import ReturnToEducation from 'views/pages/reporting/dashboards/ReturnToEducation';
import SuccessfulOutcomes from 'views/pages/reporting/dashboards/SuccessfulOutcomes';
import ReferralDemographics from 'views/pages/reporting/dashboards/ReferralDemographics';
import FundingNumbers from 'views/pages/reporting/dashboards/FundingNumbers';
import InternalSupports from 'views/pages/reporting/dashboards/InternalSupports';
import OutstandingAdmin from 'views/pages/reporting/dashboards/OutstandingAdmin';
import ReferralDownloads from 'views/pages/reporting/dashboards/ReferralDownloads';
import ReferralsByStatus from 'views/pages/reporting/dashboards/ReferralsByStatus';
import ReferralsByStatusUpdates from 'views/pages/reporting/dashboards/ReferralsByStatusUpdates';
import BriefIntervention from 'views/pages/reporting/dashboards/BriefIntervention';
import AverageTimes from 'views/pages/reporting/dashboards/AverageTimes';
import ClientSuccessRates from 'views/pages/reporting/dashboards/ClientSuccessRates';
import EngagementOutcomes from 'views/pages/reporting/dashboards/EngagementOutcomes';
import OutcomesOverTime from 'views/pages/reporting/dashboards/OutcomesOverTime';
import ExitReasons from 'views/pages/reporting/dashboards/ExitReasons';
import ReferralDistinctCMAHStatusUpdates from 'views/pages/reporting/dashboards/ReferralDistinctCMAHStatusUpdates';
import OutcomesByCohort from 'views/pages/reporting/dashboards/OutcomesByCohort';
import ReferralsBySchools10 from 'views/pages/reporting/dashboards/ReferralsBySchools10';

const REACT_APP_TABLEAU_BASE_URL = process.env.REACT_APP_TABLEAU_BASE_URL;
const REACT_APP_TABLEAU_DASHBOARD_NAME = process.env.REACT_APP_TABLEAU_DASHBOARD_NAME;
const TABLEAU_PARAMS = '?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=true'

const getTableauURL = (dashboardName, reportName = undefined) => {
	reportName = reportName || REACT_APP_TABLEAU_DASHBOARD_NAME
	return REACT_APP_TABLEAU_BASE_URL + reportName + '/' + dashboardName + TABLEAU_PARAMS
}

export const Reports = [
	{
		id: 'referral-numbers',
		section: 'Program Demand',
		title: 'Referrals in NDBMS',
		description: 'This report details the number of referrals enterred through the referral system.',
		access: 2,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<ReferralNumbers
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('ReferralsinNDBMS'),
			}
		],
		
	},
	{
		id: 'referral-download',
		parallelRequests: ['referral-download-open', 'referral-download-closed'],
		section: 'Program Demand',
		title: 'Export referrals from NDBMS',
		controlsOff: true,
		description: 'This table enables the export of all referrals from NDBMS.',
		access: 2,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => <ReferralDownloads data={data} />,
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('ExportreferralsinNDMS'),
			}
		]
	},
	{
		id: 'referral-numbers-status',
		section: 'Program Demand',
		title: 'Referrals by Status',
		description: 'This report details the number of referrals by current status.',
		access: 2,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<ReferralsByStatus
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('Referralsbystatus'),
			}
		]
	},
	{
		id: 'referral-numbers-status-updates',
		section: 'Program Demand',
		title: 'Referral Status Updates',
		description: 'This report details the number of referrals by updated statuses throughout the nominated period.',
		access: 0,
		fallback: false,
				chart: (data, dataFilters, keys, currentView, areas) => (
			<ReferralsByStatusUpdates
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('Referralstatusupdates'),
			}
		]
	},
	{
		id: 'distinct-cmah',
		section: 'Program Demand',
		title: 'Distinct referrals in Case Management / Active Hold',
		description: 'This report details the distinct referrals that has been in Case Management or Active Hold.',
		access: 0,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<ReferralDistinctCMAHStatusUpdates
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('DistinctreferralsinCMAH'),
			}
		]
	},
	{
		id: 'referral-by-school',
		section: 'Program Demand',
		title: 'Top 10 Schools by Referral',
		description: 'This report details the top 10 schools by referral volume.',
		access: 2,
		fallback: true,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<ReferralsBySchools10
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('Top10SchoolsbyReferral'),
			}
		]
	},
	{
		id: 'active-hold',
		section: 'Program Capacity',
		title: 'Referrals in Active Hold',
		description: 'This report details the number of referrals currently in Active Hold.',
		access: 2,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<ActiveHold data={data} dataFilters={dataFilters} keys={keys} currentView={currentView} areas={areas} />
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('ReferralsinAH'),
			}
		]
	},
	{
		id: 'case-management',
		section: 'Program Capacity',
		title: 'Referrals in Case Management',
		description: 'This report details the number of referrals currently in Case Management.',
		access: 2,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<CaseManagement data={data} dataFilters={dataFilters} keys={keys} currentView={currentView} areas={areas} />
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('ReferralsinCM'),
			}
		]
	},
	{
		id: 'return-education',
		section: 'Program Outcomes',
		title: 'Returned to Education',
		description: 'This report details the number of referrals that returned to education.',
		access: 2,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<ReturnToEducation
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('ReturntoEducation'),
			}
		]
	},
	{
		id: 'successful-outcomes',
		section: 'Program Outcomes',
		title: 'Successful Outcomes',
		description: 'This report details the number of referrals with successful outcomes.',
		access: 2,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<SuccessfulOutcomes
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: 'RTEAH',
				tabText: 'Return to Education from Active Hold',
				url:  getTableauURL('SO-RTEAH'),
			},
			{
				tabName: 'RTECM',
				tabText: 'Return to Education from Case Management',
				url: getTableauURL('SO-RTECM'),
			},
			{
				tabName: 'exitPointSummary',
				tabText: 'Exit Point Summary',
				url: getTableauURL('SO-ExitPointSummary'),
			},
			{
				tabName: 'successfulExit',
				tabText: 'Young People Who May Be Ready To Exit',
				url: getTableauURL('SO-Readytoexit'),
			},
			{
				tabName: 'attendanceRate',
				tabText: 'Attendance Rate',
				url: getTableauURL('SO-Attendancerate'),
			},
			{
				tabName: 'tailoredSupport',
				tabText: 'Tailored Support',
				url: getTableauURL('SO-TailoredSupport'),
			},
			{
				tabName: 'resilience',
				tabText: 'Resilience',
				url: getTableauURL('SO-Resilience'),
			},
			{
				tabName: 'exitOtherReasons',
				tabText: 'Exits with Other Engagement',
				url: getTableauURL('SO-ExitOther'),
			},
			{
				tabName: 'successfullyExited',
				tabText: 'Exits with Sustained Educational Reengagement',
				url: getTableauURL('SO-ExitSus'),
			},
		]
	},
	{
		id: 'brief-intervention',
		section: 'Program Outcomes',
		title: 'Pilot Program',
		description: 'This report details the progress of referrals who are in the pilot program',
		access: 0,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<BriefIntervention
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('PilotProgram'),
			}
		]
	},
	{
		id: 'outcomes-over-time',
		section: 'Program Outcomes',
		title: 'Outcomes Over Time',
		description: 'This report details the progress of referrals over time from the date of referral.',
		access: 0,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<OutcomesOverTime
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: 'after3months',
				tabText: 'Status After 3 Months',
				url:  getTableauURL('OutcomesOverTime-3Months', 'NavigatorReporting-OutcomesOverTime'),
			},
			{
				tabName: 'after6months',
				tabText: 'Status After 6 Months',
				url:  getTableauURL('OutcomesOverTime-6Months', 'NavigatorReporting-OutcomesOverTime'),
			},
			{
				tabName: 'after9months',
				tabText: 'Status After 9 Months',
				url: getTableauURL('OutcomesOverTime-9Months', 'NavigatorReporting-OutcomesOverTime'),
			},
			{
				tabName: 'after12months',
				tabText: 'Status After 12 Months',
				url: getTableauURL('OutcomesOverTime-12Months', 'NavigatorReporting-OutcomesOverTime'),
			},
			{
				tabName: 'after18months',
				tabText: 'Status After 18 Months',
				url: getTableauURL('OutcomesOverTime-18Months', 'NavigatorReporting-OutcomesOverTime'),
			},
		]
	},
	{
		id: 'exit-reasons',
		section: 'Program Outcomes',
		title: 'Reasons for Exit',
		description: 'This report details the reasons for exit for major exit points in the system.',
		access: 2,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<ExitReasons data={data} dataFilters={dataFilters} keys={keys} currentView={currentView} areas={areas} />
		),
		tabs: [
			{
				tabName: 'beforecompletion',
				tabText: 'Reasons for Exit Before Completion',
				url: getTableauURL('ExitBC_1'),
			},
			{
				tabName: 'caseclosure',
				tabText: 'Reasons for Case Closure',
				url: getTableauURL('Caseclosure'),
			},
			{
				tabName: 'otherreasons',
				tabText: 'Reasons for Exit Other Engagement Achieved',
				url: getTableauURL('ExitOtherEng'),
			},
			{
				tabName: 'successfulreasons',
				tabText: 'Reasons for Exit Sustained Educational Re-engagement',
				url: getTableauURL('ExitSusRe-eng'),
			},
		]
	},
	{
		id: 'outcomes-by-cohort',
		section: 'Program Outcomes',
		title: 'Outcomes by cohort',
		description: 'This report details the outcomes of YPs by cohort.',
		access: 0,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<OutcomesByCohort
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: 'atsi',
				tabText: 'ATSI',
				url:  getTableauURL('OutcomesbyATSI'),
			},
			{
				tabName: 'oohc',
				tabText: 'OOHC',
				url: getTableauURL('OutcomesbyOOHC'),
			},
			{
				tabName: 'yj',
				tabText: 'YJ',
				url: getTableauURL('OutcomesbyYJ'),
			},
			{
				tabName: 'disability',
				tabText: 'Disability',
				url: getTableauURL('OutcomesbyDisability'),
			},
			{
				tabName: 'refugee',
				tabText: 'Refugee',
				url: getTableauURL('OutcomesbyRefugee'),
			},
			{
				tabName: 'gender',
				tabText: 'Gender',
				url: getTableauURL('OutcomesbyGender'),
			},
		]
	},
	{
		id: 'average-times',
		section: 'Program Metrics',
		title: 'Average times',
		description: 'This report details the average times taken by referrals for various metrics.',
		access: 0,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<AverageTimes data={data} dataFilters={dataFilters} keys={keys} currentView={currentView} areas={areas} />
		),
		tabs: [
			
			{
				tabName: 'status',
				tabText: 'Average Time Spent in Each Status',
				url: getTableauURL('AverageTimes-Status','NavigatorReporting-AverageTimes'),
			},
			{
				tabName: 'RTE',
				tabText: 'Average Time to Return to Education',
				url: getTableauURL('AverageTimes-RTE','NavigatorReporting-AverageTimes'),
			},
			{
				tabName: 'seeTimes',
				tabText: 'Average Time to achieve Sustained Educational Engagement',
				url: getTableauURL('AverageTimes-SEE','NavigatorReporting-AverageTimes'),
			},
			{
				tabName: 'otherTimes',
				tabText: 'Average Time to achieve Exit Other Engagement',
				url: getTableauURL('AverageTimes-ExitOtherEngagement','NavigatorReporting-AverageTimes'),
			},
			{
				tabName: 'bcTimes',
				tabText: 'Average Time to reach Exit Before Completion',
				url: getTableauURL('AverageTimes-ExitBC','NavigatorReporting-AverageTimes'),
			},
			{
				tabName: 'attendance',
				tabText: 'Average Time to Achieve Higher Attendance Statuses',
				url: getTableauURL('AverageTimes-AttendanceStatus','NavigatorReporting-AverageTimes'),
			},
			{
				tabName: 'rerefTimes',
				tabText: 'Average Time between Exit Status and Re-referral',
				url: getTableauURL('AverageTimes-ReReferarls2','NavigatorReporting-AverageTimes'),
			},
		]
	},
	{
		id: 'client-success',
		section: 'Program Metrics',
		title: 'Client success rates',
		description: 'Overall performance of Navigator across the cohort.',
		access: 0,
		fallback: true,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<ClientSuccessRates
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('Clientsuccessrates'),
			}
		]
	},
	{
		id: 'engagement-outcomes',
		section: 'Program Metrics',
		title: 'Engagement at referral v Navigator outcomes',
		description: 'Navigator outcome of clients relative to their referral engagement rate with Navigator',
		access: 0,
		fallback: true,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<EngagementOutcomes
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: 'noEng',
				tabText: 'YP with 0% attendance/no school enrolment at referral',
				url: getTableauURL('EngatreferralYP0_1'),
			},
			{
				tabName: 'someEng',
				tabText: 'YP with >0% attendance at referral',
				url: getTableauURL('EngatreferralYP0_1'),
			},
			{
				tabName: 'RTE',
				tabText: 'YP who have RTE - where are they now',
				url: getTableauURL('EngatreferralYPRTE'),
			},
		]
	},
	{
		id: 'referral-demographics',
		section: 'Program Demographics & Funding',
		title: 'Referral Demographics',
		description: 'Distribution of referrals based on demographics (ATSI, OOHC, etc.).',
		access: 2,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<ReferralDemographics
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('Referraldemographics'),
			}
		]
	},
	{
		id: 'augmented-navigator',
		section: 'Program Demographics & Funding',
		title: 'Augmented Navigator',
		description: 'Tracking of clients serviced through Augmented Navigator function.',
		access: 0,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<FundingNumbers data={data} dataFilters={dataFilters} keys={keys} currentView={currentView} areas={areas} />
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('AugmentedNavigator'),
			}
		]
	},
	{
		id: 'internal-supports',
		section: 'Local Area Improvement',
		title: 'Internal School Supports',
		description: 'Tracking of clients with different internal school supports.',
		access: 2,
		fallback: true,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<InternalSupports
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: '',
				tabText: '',
				url: getTableauURL('ISS-PrePost-Reff'),
			}
		]
	},
	{
		id: 'outstanding-admin',
		section: 'Local Area Improvement',
		title: 'Outstanding Administration',
		description:
			'Tracking clients with outstanding surveys, re-engagement and deferral plans, and overdue statuses.',
		access: 2,
		fallback: false,
		chart: (data, dataFilters, keys, currentView, areas) => (
			<OutstandingAdmin
				data={data}
				dataFilters={dataFilters}
				keys={keys}
				currentView={currentView}
				areas={areas}
			/>
		),
		tabs: [
			{
				tabName: 'surveys',
				tabText: 'Exit and Entry surveys',
				url: getTableauURL('OA-ExitandEntrySurvey'),
			},
			{
				tabName: 'plans',
				tabText: 'Other required documents that are incomplete',
				url: getTableauURL('OA-Otherrequireddocum'),
			},
			{
				tabName: 'files',
				tabText: 'Files not updated in the last 4 weeks',
				url: getTableauURL('OA-Filesnotupdated'),
			},
		]
	},
	// {
	// 	id: 'eiif-1',
	// 	section: 'EIIF Reports',
	// 	title: 'Population with greater than 3 months of CM',
	// 	description: 'This report details the YPs with greater than 3 months of CM.',
	// 	access: 99,
	// 	controlsOff: true,
	// 	chart: (data, dataFilters, keys, currentView, areas) => <EIIF1 data={data} />,
	// },
];
