import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import { Card, Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import NoDataIndication from 'components/Tables/NoDataIndication';
import CopyTable from 'components/Tables/CopyTable';
import PrintTable from 'components/Tables/PrintTable';
import AutoSort from 'components/Tables/AutoSort';
import DatepickerTable from 'components/Tables/DatePicker';

import { pagination } from 'config/tableConfig';
import { formatDate, formatDateTime } from 'utils/dateFormatter';
import secureAPI from 'utils/secureAPI';
import compileTableRows from 'utils/compileTableRows';

const { SearchBar } = Search;

const getSortedDistinct = (array, key) =>
	[...new Set(array.map(el => el[key]))].sort().reduce(function(result, item, index, array) {
		if (item && item !== undefined) {
			result[item] = item;
		}
		return result;
	}, {});

const ReferralListTable = props => {
	const [alert, setAlert] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sortState, setSortState] = useState({ field: 'customSortField', order: 'desc' });
	const [selectOptions, setSelectOptions] = useState({
		caseManagers: {},
		serviceProviders: {},
		detAreas: {},
		statuses: {},
		pilotProgram: {},
	});
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	const tableRef = useRef();

	const customiseRow = rowObj => {
		// create the customised sort field order
		rowObj[
			'customSortField'
		] = `${rowObj['StatusSortOrder']} ${rowObj['Priority young person']} ${rowObj['ReferralDate']}`;

		// ensure each date of birth is a date object to be compatible with the table filter
		// also populate undefined or null dates of birth as epoch time
		rowObj['Date of Birth'] = rowObj['Date of Birth']
			? moment(rowObj['Date of Birth'], 'DD/MM/YYYY').add(12, 'hours').toDate()
			: new Date(0);
		
		return rowObj;
	};

	useEffect(() => {
		let canSet = true;
		secureAPI({ method: 'POST', endpoint: 'REFERRALS' }, { user: props.user })
			.then(response => {
				let rows = response.data.rows;
				let columns = response.data.columns;
				let tableRows = compileTableRows(rows, columns, customiseRow);

				if (canSet) {
					const caseManagerOptions = getSortedDistinct(tableRows, 'Case manager');
					const serviceProviderOptions = getSortedDistinct(tableRows, 'Allocate provider');
					const detAreaOptions = getSortedDistinct(tableRows, 'DET area');
					const statusOptions = getSortedDistinct(tableRows, 'Status');
					const pilotProgramOptions = getSortedDistinct(tableRows, 'Pilot Program');

					setSelectOptions({
						caseManagers: caseManagerOptions,
						serviceProviders: serviceProviderOptions,
						detAreas: detAreaOptions,
						statuses: statusOptions,
						pilotProgram: pilotProgramOptions,
					});
					setDataTable(tableRows);
					setOriginalData(tableRows);
					setLoading(false);
				}
			})
			.catch(error => {
				console.log(error);
			});
		return () => (canSet = false);
	}, [props.user]);

	useEffect(() => {
		let result = originalData;
		let canSet = true;

		if (moment.isMoment(startDate)) {
			result = result.filter(el => moment.utc(el['ReferralDate']).isSameOrAfter(startDate));
		}

		if (moment.isMoment(endDate)) {
			let endDateInclusive = moment(endDate).add(1, 'd');
			result = result.filter(el => moment.utc(el['ReferralDate']).isSameOrBefore(endDateInclusive));
		}

		if (canSet) {
			setDataTable(result);
		}

		return () => (canSet = false);
	}, [startDate, endDate, originalData]);

	const linkFormatter = (cell, row) => {
		return <Link to={`/profile/${row['RefID']}`}>{cell}</Link>;
	};

	const linkDateTimeFormatter = (cell, row) => {
		return <Link to={`/profile/${row['RefID']}`}>{formatDateTime(cell)}</Link>;
	};

	const linkDateFormatter = (cell, row) => {
		return <Link to={`/profile/${row['RefID']}`}>{formatDate(cell)}</Link>;
	};

	const workerSafetyFormatter = (cell, row) => {
		if (cell === 'Yes') {
			return (
				<Link to={`/profile/${row['RefID']}`}>
					<i className="fas fa-exclamation-triangle" />
				</Link>
			);
		} else {
			return <Link to={`/profile/${row['RefID']}`} />;
		}
	};
	const DOBFormatter = (cell, row) => {
		const epochTime = new Date(0).toLocaleDateString('en-AU');
		const dateCell = cell.toLocaleDateString('en-AU');
		if (dateCell === epochTime) {
			return pilotProgramFormatter('', row);
		}
		return pilotProgramFormatter(dateCell, row);
	};

	const pilotProgramFormatter = (cell, row) => {
		if (row['Pilot Program'] === 'Yes') {
			return (
				<Link to={`/profile/${row['RefID']}`}>
					{cell} {<i className="ni ni-user-run"></i>}
				</Link>
			);
		} else {
			return linkFormatter(cell, row);
		}
	};

	const handleSort = (field, order) => setSortState({ field, order });

	const headerSortingStyle = { color: '#ffffff', backgroundColor: '#5d90c3' };

	const headerFormatter = (column, colIndex, { sortElement, filterElement }) => (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{filterElement}

			{column.text}
		</div>
	);

	const rowFlagStyle = (row, rowIndex) => {
		const style = {};
		if (row['Priority override'] && row['Priority override'] !== 'No') {
			style.backgroundColor = '#ff00004a'; // red
		} else if (
			(row['Priority young person'] === 'Yes' || row['Priority young person'] === 'Unsure') &&
			!row['Priority override']
		) {
			style.backgroundColor = '#ffd60036'; // yellow
		} else if (row['DETAreaChanged'] === '1') {
			style.backgroundColor = '#0000ff2e'; // blue
		}
		// // old rules
		// if (row['DETAreaChanged'] === '1' && row['Under12'] === '1') {
		// 	style.backgroundColor = '#ff00004a'; // red
		// } else if (row['Under12'] === '1') {
		// 	style.backgroundColor = '#ffd60036'; // yellow
		// } else if (row['DETAreaChanged'] === '1') {
		// 	style.backgroundColor = '#0000ff2e'; // blue
		// }

		// bold if worker safety is found
		// if (row['Worker safety concerns'] === 'Yes') {
		// 	style.fontWeight = 'bold';
		// }

		return style;
	};

	return (
		<>
			{alert}
			<Container className="mt--6" fluid>
				<Row>
					<div className="col">
						<Card>
							<ToolkitProvider
								data={dataTable}
								keyField="RefID"
								columns={[
									{
										dataField: 'customSortField',
										text: '',
										hidden: true,
									},
									{
										dataField: 'RefID',
										text: 'RefID',
										hidden: true,
									},
									{
										dataField: 'Young person - first name',
										text: 'First Name',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
									},
									{
										dataField: 'Young person - surname',
										text: 'Surname',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
									},
									{
										dataField: 'Date of Birth',
										text: 'Date of Birth',
										sort: true,
										onSort: handleSort,
										formatter: DOBFormatter,
										headerSortingStyle,
										filter: dateFilter({
											// withoutEmptyComparatorOption: true,
											comparators: [Comparator.LE, Comparator.GE, Comparator.EQ],
											// defaultValue: { date: new Date(), comparator: Comparator.LE },
										}),
										headerFormatter,
									},
									{
										dataField: 'Year level',
										text: 'Year level',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
									},
									{
										dataField: 'Priority young person',
										text: 'Priority Cohort',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
									},
									{
										dataField: 'Pilot Program',
										text: '10-11 Yr Pilot Program',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
										filter: selectFilter({
											options: selectOptions.pilotProgram,
										}),
										headerFormatter,
									},
									{
										dataField: 'Worker safety concerns',
										text: 'Worker Safety',
										sort: true,
										onSort: handleSort,
										align: 'center',
										formatter: workerSafetyFormatter,
										headerSortingStyle,
										hidden: true,
									},
									{
										dataField: 'School',
										text: 'School',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
									},
									{
										dataField: 'Campus',
										text: 'Campus',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
									},
									{
										dataField: 'Status',
										text: 'Status',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
										filter: selectFilter({
											options: selectOptions.statuses,
										}),
										headerFormatter,
									},
									{
										dataField: 'DET area',
										text: 'DET Area',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
										filter: selectFilter({
											options: selectOptions.detAreas,
										}),
										headerFormatter,
									},
									{
										dataField: 'Allocate provider',
										text: 'Service Provider',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
										filter: selectFilter({
											options: selectOptions.serviceProviders,
										}),
										headerFormatter,
									},
									{
										dataField: 'Case manager',
										text: 'Case Manager',
										sort: true,
										onSort: handleSort,
										formatter: linkFormatter,
										headerSortingStyle,
										filter: selectFilter({
											options: selectOptions.caseManagers,
										}),
										headerFormatter,
									},
									{
										dataField: 'LastUpdate',
										text: 'Record Last Updated',
										sort: true,
										onSort: handleSort,
										formatter: linkDateTimeFormatter,
										headerSortingStyle,
									},
									{
										dataField: 'ReferralDate',
										text: 'Referral Date',
										sort: true,
										onSort: handleSort,
										formatter: linkDateFormatter,
										headerSortingStyle,
									},
								]}
								search
							>
								{props => (
									<div className="py-4 table-responsive">
										<Container fluid>
											<Row>
												<Col xs={12} sm={6}>
													<CopyTable
														tableElement={document.getElementById('ref-list-table')}
														alertFunc={setAlert}
													/>
													<PrintTable tableRef={tableRef.current} />
													<AutoSort
														stateFunc={setSortState}
														initState={{ field: 'customSortField', order: 'desc' }}
													/>
												</Col>
												<Col xs={12} sm={6}>
													<div
														id="datatable-basic_filter"
														className="dataTables_filter pb-1 float-right"
													>
														<label>
															Search:
															<SearchBar
																className="form-control-sm"
																placeholder=""
																{...props.searchProps}
															/>
														</label>
													</div>
												</Col>
											</Row>
											<Row>
												<Col lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
													<span
														style={{
															fontWeight: 'bold',
															fontSize: '0.875rem',
															marginTop: '1rem',
														}}
													>
														Referral Dates:
													</span>
												</Col>
												<Col lg={5}>
													<DatepickerTable
														placeholder="Start Date"
														stateFunc={setStartDate}
														stateValue={startDate}
													/>
												</Col>
												<Col lg={5}>
													<DatepickerTable
														placeholder="End Date"
														stateFunc={setEndDate}
														stateValue={endDate}
													/>
												</Col>
											</Row>
										</Container>

										<BootstrapTable
											id="ref-list-table"
											ref={tableRef}
											bootstrap4={true}
											filter={filterFactory()}
											pagination={pagination}
											bordered={false}
											noDataIndication={() => <NoDataIndication loading={loading} />}
											sort={{ dataField: sortState.field, order: sortState.order }}
											rowStyle={rowFlagStyle}
											{...props.baseProps}
										/>
									</div>
								)}
							</ToolkitProvider>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default ReferralListTable;
