import React from 'react';
import { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Container, Row, Col, Modal } from 'reactstrap';
import { useParams } from 'react-router-dom';

import ProfileUpdateHeader from 'components/Headers/ProfileUpdateHeader';
import Loading from 'components/General/Loading';
import AddNotesButton from 'components/Buttons/AddNotesButton';
import AddNotesModal from 'components/Modals/AddNotesModal';
import { formatDateTime } from 'utils/dateFormatter';
import secureAPI from 'utils/secureAPI';

const Notes = props => {
	const [notes, setNotes] = useState([]);
	const [data, setData] = useState({});
	const [modal, setModal] = useState(false);
	const [name, setName] = useState(['']);
	const [loading, setLoading] = useState(true);
	let params = useParams();

	useEffect(() => {
		let canSet = true;
		const getRefProfile = (
			secureAPI(
				{method: 'POST', endpoint: 'PROFILE'},
				{ RefID: params.id }
			)
				.then(response => {
					if (canSet) {
						let firstname = response.data['Young person - first name'] ?? ''
						let surname = response.data['Young person - surname'] ?? ''
						setName(`${firstname} ${surname}`);
						setData(response.data);
					}
				})
				.catch(error => {
					console.log(error);
				})
		)
		const getProfileNotes = (
			secureAPI(
				{method: 'POST', endpoint: 'NOTES'},
				{ RefID: params.id }
			)
				.then(response => {
					if (canSet) {
						setNotes(response.data);
					}
				})
				.catch(error => {
					console.log(error);
				})
		)
		const requestArr = [getRefProfile, getProfileNotes]
		Promise.all(requestArr).then(() => {
			if (canSet) {
				setLoading(false);
			}
		})

		return () => canSet = false
	}, [params.id]);
	
	return !loading ? (
		<>
			<ProfileUpdateHeader name="Notes" id={params.id} parentName={name} user={props.user} />
			<Container className="mt--6" fluid>
				<Row>
					<Col lg="12">
						<Card>
							<CardHeader className="bg-transparent">
								<Row className="align-items-center">
									<Col xs="8">
										<h3 className="mb-0">Notes</h3>
									</Col>
									<Col className="text-right" xs="4">
										{data.Status === "Closed" ? null : <AddNotesButton modalFunc={setModal}/>}
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								{ notes.length > 0 ? notes.map((note, index) => (
									<div
										className="timeline timeline-one-side"
										data-timeline-axis-style="dashed"
										data-timeline-content="axis"
										key={index}
									>
										<div className="timeline-block">
											<span className="timeline-step badge-success">
												<i className="ni ni-bell-55" />
											</span>
											<div className="timeline-content">
												<small className="text-muted font-weight-bold">
													{formatDateTime(note.LastUpdate)}
												</small>
												<h5 className="mt-3 mb-0">
													{note.userEmail ? `by ${note.userEmail}` : ''}
												</h5>
												<p className="text-sm mt-1 mb-0">{note.Notes}</p>
											</div>
										</div>
									</div>
								)) : (<p>{`No notes on file for ${name}`}</p>)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
            <AddNotesModal
				state={modal}
				refId={params.id}
				user={props.user}
				modalFunc={setModal}
				setNotes={setNotes}
			/>
		</>
	) : (
		<>
			<Modal className="modal-dialog-centered" isOpen={loading}>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Loading Notes ....
					</h6>
				</div>
				<div className="modal-body">
					<Container>
						<Row>
							<Col className="text-center">
								<Loading />
							</Col>
						</Row>
					</Container>
				</div>
			</Modal>
		</>
	);
};

export default Notes;
