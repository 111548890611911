import React from 'react';
import { Container, Col, Card, CardFooter, CardBody, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import ReportingHeader from 'components/Headers/ReportingHeader';

const ReportError = props => {
	let history = useHistory();
	return (
		<>
			<ReportingHeader name="Report" parentName="Reporting List" user={props.user} />
			<Container className="mt--6" fluid>
				<Card className="m-auto" style={{ maxWidth: '720px' }}>
					<Col>
						<CardBody>
							<div className="h2 font-weight-bold">{props.message}</div>
						</CardBody>
						<CardFooter>
							<Button block onClick={() => history.push(`/hub`)} color="primary">
								Back to Hub
							</Button>
						</CardFooter>
					</Col>
				</Card>
			</Container>
		</>
	);
};

export default ReportError;
