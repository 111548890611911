import React from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactBSAlert from 'react-bootstrap-sweetalert';

export const pagination = paginationFactory({
	page: 1,
	alwaysShowAllBtns: true,
	showTotal: true,
	withFirstAndLast: false,
	sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
		<div className="dataTables_length" id="datatable-basic_length">
			<label>
				Show
				{
					<select
						name="datatable-basic_length"
						aria-controls="datatable-basic"
						className="form-control form-control-sm"
						onChange={e => onSizePerPageChange(e.target.value)}
					>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="30">30</option>
						<option value="40">40</option>
						<option value="50">50</option>
					</select>
				}
				entries.
			</label>
		</div>
	),
});

export const copyToClipboardAsTable = (el, alertFunc) => {
    var body = document.body,
        range,
        sel;
    if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
            range.selectNodeContents(el);
            sel.addRange(range);
        } catch (e) {
            range.selectNode(el);
            sel.addRange(range);
        }
        document.execCommand('copy');
    } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
        range.execCommand('Copy');
    }
    alertFunc(
        <ReactBSAlert
            success
            style={{ display: 'block', marginTop: '0px' }}
            title="Good job!"
            onConfirm={() => alertFunc([])}
            onCancel={() => alertFunc([])}
            confirmBtnBsStyle="info"
            btnSize=""
        >
            Copied to clipboard!
        </ReactBSAlert>,
    );
};