import { Col, Row, TabContent, TabPane, Nav } from 'reactstrap';
import { useState } from 'react';
import StackedBar from 'components/Plots/StackedBar';
import NavigationPill from 'components/Buttons/NavigationPill';
import ChartWrapper from 'components/Plots/ChartWrapper';
import NoChartData from 'components/Plots/NoChartData';
import { chartNotEmpty } from 'utils/functions';

const EngagementOutcomes = ({ data, dataFilters, keys, currentView, areas }) => {
	const [activeTab, setActiveTab] = useState('noEng');
	let noEng, someEng, RTE;
	if (dataFilters.area) {
		noEng = data['no-engagements'][dataFilters.area];
		someEng = data['some-engagements'][dataFilters.area];
		RTE = data['rte'][dataFilters.area];
	} else {
		noEng = data['no-engagements']['All Areas'];
		someEng = data['some-engagements']['All Areas'];
		RTE = data['rte']['All Areas'];
	}

	const noEngChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: noEng,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={noEng ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Current Statuses of Referrals'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'noEng-table'}
				/>
			</ChartWrapper>
		),
	});

	const someEngChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: someEng,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={someEng ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Current Statuses of Referrals'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'someEng-table'}
				/>
			</ChartWrapper>
		),
	});

	const RTEChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: RTE,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 100, left: 80 }}
					data={RTE ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					xRotation={-15}
					xLabel={'Current Statuses of Referrals'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendAnchor="bottom-right"
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'RTE-table'}
				/>
			</ChartWrapper>
		),
	});

	const chartArray = [
		{
			tabName: 'noEng',
			tabText: 'YP with 0% attendance/no school enrolment at referral',
			chart: noEngChart,
		},
		{
			tabName: 'someEng',
			tabText: 'YP with >0% attendance at referral',
			chart: someEngChart,
		},
		{
			tabName: 'RTE',
			tabText: 'YP who have RTE - where are they now',
			chart: RTEChart,
		},
	];

	const navSidebar = chartArray.map((chart, index) => (
		<Row key={index}>
			<Col>
				<NavigationPill activeTab={activeTab} changeActiveTab={setActiveTab} tabName={chart.tabName}>
					{chart.tabText}
				</NavigationPill>
			</Col>
		</Row>
	));

	const chartTabPanes = chartArray.map((chart, index) => (
		<TabPane key={index} tabId={chart.tabName}>
			{chart.chart}
		</TabPane>
	));

	return (
		<Row className="mt-3">
			<Col xs={2}>
				<Nav className="nav-fill flex-column" id="tabs-icons-text" pills role="tablist">
					{navSidebar}
				</Nav>
			</Col>
			<Col xs={10}>
				<TabContent activeTab={activeTab}>{chartTabPanes}</TabContent>
			</Col>
		</Row>
	);
};

export default EngagementOutcomes;
