import { Col, Row, TabContent, TabPane, Nav } from 'reactstrap';
import { useState } from 'react';
import StackedBar from 'components/Plots/StackedBar';
import NavigationPill from 'components/Buttons/NavigationPill';
import ChartWrapper from 'components/Plots/ChartWrapper';
import NoChartData from 'components/Plots/NoChartData';
import { chartNotEmpty } from 'utils/functions';

const ExitReasons = ({ data, dataFilters, keys, currentView, areas }) => {
	const [activeTab, setActiveTab] = useState('beforecompletion');
	let beforeCompletionReasons = dataFilters.area
		? data['before-completion'][dataFilters.area]
		: data['before-completion']['All Areas'];
	let caseClosureReasons = dataFilters.area
		? data['case-closure'][dataFilters.area]
		: data['case-closure']['All Areas'];
	let otherReasons = dataFilters.area ? data['other'][dataFilters.area] : data['other']['All Areas'];
	let successfulReasons = dataFilters.area ? data['successful'][dataFilters.area] : data['successful']['All Areas'];

	const beforeCompletionChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: beforeCompletionReasons,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 140, left: 50 }}
					data={beforeCompletionReasons ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					legendAnchor="bottom-right"
					yLabel={'Number of Referrals'}
					view={currentView}
					totalsTableId={'before-completion'}
				/>
			</ChartWrapper>
		),
	});

	const caseClosureChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: caseClosureReasons,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 140, left: 50 }}
					data={caseClosureReasons ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					legendAnchor="bottom-right"
					yLabel={'Number of Referrals'}
					view={currentView}
					totalsTableId={'case-closure'}
				/>
			</ChartWrapper>
		),
	});

	const otherChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: otherReasons,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 140, left: 50 }}
					data={otherReasons ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					legendAnchor="bottom-right"
					yLabel={'Number of Referrals'}
					view={currentView}
					totalsTableId={'other-reasons'}
				/>
			</ChartWrapper>
		),
	});

	const successfulChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: successfulReasons,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 200, bottom: 140, left: 50 }}
					data={successfulReasons ?? []}
					index={'status'}
					indices={areas}
					keys={keys}
					keyFilter={dataFilters.provider}
					legendAnchor="bottom-right"
					yLabel={'Number of Referrals'}
					view={currentView}
					totalsTableId={'successful-reasons'}
				/>
			</ChartWrapper>
		),
	});

	const chartArray = [
		{
			tabName: 'beforecompletion',
			tabText: 'Reasons for Exit Before Completion',
			chart: beforeCompletionChart,
		},
		{
			tabName: 'caseclosure',
			tabText: 'Reasons for Case Closure',
			chart: caseClosureChart,
		},
		{
			tabName: 'otherreasons',
			tabText: 'Reasons for Exit Other Engagement Achieved',
			chart: otherChart,
		},
		{
			tabName: 'successfulreasons',
			tabText: 'Reasons for Exit Sustained Educational Re-engagement',
			chart: successfulChart,
		},
	];

	const navSidebar = chartArray.map((chart, index) => (
		<Row key={index}>
			<Col>
				<NavigationPill activeTab={activeTab} changeActiveTab={setActiveTab} tabName={chart.tabName}>
					{chart.tabText}
				</NavigationPill>
			</Col>
		</Row>
	));

	const chartTabPanes = chartArray.map((chart, index) => (
		<TabPane key={index} tabId={chart.tabName}>
			{chart.chart}
		</TabPane>
	));

	return (
		<Row className="mt-3">
			<Col xs={2}>
				<Nav className="nav-fill flex-column" id="tabs-icons-text" pills role="tablist">
					{navSidebar}
				</Nav>
			</Col>
			<Col xs={10}>
				<TabContent activeTab={activeTab}>{chartTabPanes}</TabContent>
			</Col>
		</Row>
	);
};

export default ExitReasons;
