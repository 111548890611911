import classnames from 'classnames';
import { NavItem, NavLink } from 'reactstrap';

const NavigationPill = ({ activeTab, changeActiveTab, tabName, children }) => (
	<NavItem>
		<NavLink
			aria-selected={activeTab === tabName}
			className={classnames('mb-sm-3 mb-md-3', {
				active: activeTab === tabName,
			})}
			onClick={() => changeActiveTab(tabName)}
			role="tab"
		>
			{children}
		</NavLink>
	</NavItem>
);

export default NavigationPill;
