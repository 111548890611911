import React, { useEffect, useState } from 'react';
import {
	Card,
	CardHeader,
	CardBody,
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Button,
	Input,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import Datetime from 'react-datetime';
import moment from 'moment';
import _ from 'underscore';

import NoDataIndication from 'components/Tables/NoDataIndication';
import RadioButtons from 'components/Buttons/RadioButtons';
import ColVCentered from 'components/Grid/ColVCentered';

import { Reports } from 'config/reports';
import secureAPI from 'utils/secureAPI';
import { userTiers } from 'config/userTiers';

const NivoChart = props => {
	const [values, setValues] = useState([]);
	const [currentView, setCurrentView] = useState('chart');
	const [providers, setProviders] = useState([]);
	const [options, setOptions] = useState({ areas: [], providers: [] });
	const [topLoading, setTopLoading] = useState(true);
	const [chartLoading, setChartLoading] = useState(true);
	const [dataFilters, setDataFilters] = useState({
		area: null,
		provider: null,
	});
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	const params = useParams();

	const report = Reports.find(report => report.id === params.id);

	const handleProviderChange = e => {
		// grab target value before synthetic event is cleared.
		const targetProvider = e.target.value;
		if (targetProvider?.toLowerCase() === 'filter by provider') {
			setDataFilters(state => ({ ...state, provider: null }));
		} else {
			setDataFilters(state => ({ ...state, provider: targetProvider }));
		}
	};

	const handleAreaChange = e => {
		// grab target value before synthetic event is cleared.
		const targetArea = e.target.value;
		if (targetArea?.toLowerCase() === 'filter by det area') {
			setDataFilters(state => ({ ...state, area: null }));
		} else {
			setDataFilters(state => ({ ...state, area: targetArea }));
		}
	};

	useEffect(() => {
		let canSet = true;
		if (canSet) {
			setChartLoading(true);
		}
		const requestSingle = () =>
			secureAPI(
				{ method: 'POST', endpoint: 'REPORTING' },
				{ type: report.id, startDate: JSON.stringify(startDate), endDate: JSON.stringify(endDate) },
			)
				.then(response => {
					if (canSet) {
						setOptions({ areas: response.data.areas, providers: response.data.providers });
						setValues(response.data.values);
						setProviders(response.data.providers);
						setTopLoading(false);
						setChartLoading(false);
					}
				})
				.catch(err => console.log(err));

		const requestMultiple = () =>
			Promise.all(
				report.parallelRequests.map(request =>
					secureAPI(
						{ method: 'POST', endpoint: 'REPORTING' },
						{ type: request, startDate: JSON.stringify(startDate), endDate: JSON.stringify(endDate) },
					),
				),
			)
				.then(responses => {
					if (canSet) {
						//TODO: setOptions and setKeys for parallel requests
						const returnedValues = responses.map(response => response.data.values);
						setValues(returnedValues);
						setTopLoading(false);
						setChartLoading(false);
					}
				})
				.catch(err => {
					console.log(err);
				});
		report.parallelRequests ? requestMultiple() : requestSingle();

		return () => (canSet = false);
	}, [startDate, endDate, report.id, report.parallelRequests]);

	let filterComponents =
		props.user.accessRank > userTiers.STAFF_TABLEAU.rank ? null : (
			<Row>
				<Col className="mr-0 pr-0">
					<Dropdown
						id="provider"
						onChange={handleProviderChange}
						options={options.providers}
						placeholder="Filter by provider"
					/>
				</Col>
				<Col className="ml-0 pl-0">
					<Dropdown
						id="area"
						onChange={handleAreaChange}
						options={options.areas}
						placeholder="Filter by DET area"
					/>
				</Col>
			</Row>
		);

	let dateRangePicker = (
		<Row>
			<Col className="mr-0 pr-0">
				<Datepicker placeholder="Start date" value={startDate} setValue={setStartDate} />
			</Col>
			<Col className="mr-0 pl-0">
				<Datepicker placeholder="End date" value={endDate} setValue={setEndDate} />
			</Col>
		</Row>
	);

	let controlsViewer =
		!topLoading && !report.controlsOff ? (
			<Row>
				<Col xl="6">
					<Form className="justify-content-center">{filterComponents}</Form>
				</Col>
				<Col xl="6">
					<Form className="justify-content-center">{dateRangePicker}</Form>
				</Col>
			</Row>
		) : null;

	let chartViewer =
		!chartLoading && !_.isEmpty(values) ? (
			report.chart(values, dataFilters, providers, currentView, options.areas)
		) : (
			<NoDataIndication loading={chartLoading} />
		);

	return (
		<>
			<Container className="mt--6" fluid>
				<Row>
					<Col>
						<Card>
							<CardHeader>
								<Row>
									<ColVCentered xs={10}>
										<h5 className="h3 mb-0">{report.title}</h5>
									</ColVCentered>
									<Col>
										<RadioButtons
											stateSetter={setCurrentView}
											buttonsArray={[
												{
													state: 'chart',
													buttonText: 'Chart',
												},
												{
													state: 'totals',
													buttonText: 'Totals',
												},
											]}
										/>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								{controlsViewer}
								{chartViewer}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

const Dropdown = ({ id, onChange, options, placeholder }) => {
	const createOptions = array => array.map((el, i) => <option key={i}>{el}</option>);

	return (
		<FormGroup className="mb-2 mr-sm-2 mb-sm-0">
			<Input bsSize="sm" id={id} onChange={onChange} defaultValue={null} type="select">
				<option value={null}>{placeholder}</option>
				{createOptions(options)}
			</Input>
		</FormGroup>
	);
};

const Datepicker = ({ placeholder, value, setValue }) => {
	const displayValue = value => (moment(value).isValid() ? moment(value).format('DD MMM YYYY') : value);
	const changeState = selectedDate => {
		if (typeof selectedDate === 'object') {
			setValue(selectedDate);
		} else {
			setValue('');
		}
	};
	const renderDateInput = renderProps => (
		<Input {...renderProps} bsSize="sm" placeholder={placeholder} value={displayValue(value)} />
	);
	return (
		<FormGroup className="mb-2 mr-sm-2 mb-sm-0">
			<InputGroup size="sm">
				<InputGroupAddon addonType="prepend">
					<InputGroupText>
						<i className="ni ni-calendar-grid-58" />
					</InputGroupText>
				</InputGroupAddon>
				<Datetime
					renderInput={renderDateInput}
					dateFormat={'DD MMM YYYY'}
					timeFormat={false}
					closeOnSelect={true}
					onChange={changeState}
				/>
				<InputGroupAddon addonType="append">
					<Button className="btn-outline-primary shadow-none" onClick={() => setValue('')}>
						Clear
					</Button>
				</InputGroupAddon>
			</InputGroup>
		</FormGroup>
	);
};

export default NivoChart;
