import React from 'react';

import { ConfirmAlert, SuccessAlert, ErrorAlert, CancelAlert, LoadingAlert } from 'components/Alerts/ActionAlerts';
import secureAPI from 'utils/secureAPI';

export const reopenCase = options => {
    options.funcs.alert(
        <ConfirmAlert
            text="Case will be reopened and updates can be made."
            onConfirm={confirmReopen(options)}
            onCancel={cancelAction(options.funcs)}
        />
    )
}

export const reopenRequest = options => {
    options.funcs.alert(
        <ConfirmAlert
            text="A reopen request will be opened to the attention of Navigator admins."
            onConfirm={confirmRequest(options)}
            onCancel={cancelAction(options.funcs)}
        />
    )
}

const confirmReopen = options => {
    return () => {
        options.funcs.alert(<LoadingAlert />)
        secureAPI(
            {method: 'PUT', endpoint: 'REOPEN_CASE'},
            {
                ref: {
                    RefID: options.data['RefID'],
                    userEmail: options.user.email,
                    admin: options.user.userName,
                }
            }
        )
            .then(response => {
                console.log(response.data.body);
                options.funcs.data({...options.data, Status: 'Reopened'});
                options.funcs.alert(
                    <SuccessAlert
                        title="Case Reopened!"
                        onConfirm={() => options.funcs.alert(null)}
                        onCancel={() => options.funcs.alert(null)}
                    />
                )
            })
            .catch(err => {
                console.log(err);
                options.funcs.alert(
                    <ErrorAlert
                        onConfirm={() => options.funcs.alert(null)}
                        onCancel={() => options.funcs.alert(null)}
                    />
                )
            })
    }
}

const confirmRequest = options => {
    return () => {
        options.funcs.alert(<LoadingAlert />);
        secureAPI(
            {method: 'PUT', endpoint: 'REOPEN_REQUEST'},
            {
                ref: { RefID: options.data['RefID'] },
                requester: options.requester
            }
        )
            .then(response => {
                console.log(response.data.body);
                options.funcs.data({...options.data, reopenRequest: 1});
                options.funcs.alert(
                    <SuccessAlert
                        title="Case Reopened!"
                        onConfirm={() => options.funcs.alert(null)}
                        onCancel={() => options.funcs.alert(null)}
                    />
                )
            })
            .catch(err => {
                console.log(err);
                options.funcs.alert(
                    <ErrorAlert
                        onConfirm={() => options.funcs.alert(null)}
                        onCancel={() => options.funcs.alert(null)}
                    />
                )
            })
    }
}

const cancelAction = funcs => {
    return () => funcs.alert(
        <CancelAlert onConfirm={() => funcs.alert(null)} />
    )
}