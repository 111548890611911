import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';

import Loading from 'components/General/Loading';

export const ConfirmAlert = props => (
    <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '150px' }}
        title={props.title ?? "Confirm Action"}
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
        showCancel
        confirmBtnBsStyle="info"
        confirmBtnText="Confirm"
        cancelBtnBsStyle="warning"
        cancelBtnText="Cancel"
        btnSize=""
    >
        {props.text}
        {props.children}
    </ReactBSAlert>
)

export const SuccessAlert = props => {
    const reloadProps = props.reload ? {
        showConfirm: false,
    } : {
        onCancel: props.onCancel,
        confirmBtnBsStyle:"info",
        confirmBtnText:"OK",
        btnSize:"",
    }

    const reload = () => {
        setTimeout(() => window.location.reload(false), 1500)
    }

    return (
        <ReactBSAlert
            success
            style={{ display: 'block', marginTop: '150px' }}
            title={props.title ?? "Action Successful!"}
            onConfirm={props.onConfirm}
            {...reloadProps}
        >
            <Row><Col>{props.text}</Col></Row>
            {props.children}
            {props.reload ? reload() : null}
        </ReactBSAlert>
    )
}

export const CancelAlert = props => (
    <ReactBSAlert
        info
        style={{ display: 'block', marginTop: '150px' }}
        title={props.title ?? "Action cancelled"}
        showConfirm={false}
        onConfirm={props.onConfirm}
        timeout={1500}
    >
        <Row><Col>{props.text}</Col></Row>
        {props.children}
        <Row><Col>This message will automatically close automatically</Col></Row>
    </ReactBSAlert>
)

export const ErrorAlert = props => (
    <ReactBSAlert
        danger
        style={{ display: 'block', marginTop: '150px' }}
        title={props.title ?? "Error encountered"}
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
        confirmBtnBsStyle="info"
        confirmBtnText="OK"
        btnSize=""
    >
        <Row><Col>{props.text}</Col></Row>
        {props.children}
    </ReactBSAlert>
)

export const LoadingAlert = () => (
    <ReactBSAlert
        style={{ display: 'block', marginTop: '150px' }}
        title="Loading"
        onConfirm={() => {}}
        onCancel={() => {}}
        confirmBtnBsStyle="info"
        confirmBtnText="..."
        btnSize=""
    >
        <Loading />
    </ReactBSAlert>
)