import React from 'react';
import ProfileCard from 'components/Cards/ProfileCard';
import { Row, Col } from 'reactstrap';

const ProfileCardContents = props => {
	const dgContactInfo = {
		title: "Young Person's Contact Information",
		link: '',
		colSize: 12,
		rowGroup: {
			colSize: 12,
			textWidth: 4,
			dataWidth: 8,
			rows: [
				{ name: 'Address', data: props.data['YP address - Street number and name'] },
				{ name: 'Address', data: props.data['YP address - Name of building'] },
				{ name: 'Suburb', data: props.data['YP address - Suburb'] },
				{ name: 'State', data: props.data['YP address - State'] },
				{ name: 'Postcode', data: props.data['YP address - Postcode'] },
				{ name: 'Young person phone number (if relevant)', data: props.data['Young person - phone'] },
			],
		},
	};

	const dgExistingManager = {
		title: 'Case Management External to Navigator',
		link: '',
		colSize: 12,
		rowGroup: {
			colSize: 12,
			textWidth: 4,
			dataWidth: 8,
			rows: [
				{
					name: 'The family or young person has a non-Navigator case manager',
					data: props.data['YP - existing case manager other supports'],
				},
				{ name: 'Contact person', data: props.data['YP - existing case manager other supports - Name'] },
				{ name: 'Organisation', data: props.data['YP - existing case manager other supports - organisation'] },
				{
					name: 'Type of case management',
					data: props.data['YP - existing case manager other supports - type of case management'],
				},
				{ name: 'Phone', data: props.data['YP - existing case manager - Phone number'] },
				{ name: 'Email', data: props.data['YP - existing case manager - Email'] },
			],
		},
	};

	const dgSchoolEduSetting = {
		title: 'School/Education Setting History',
		link: '',
		colSize: 12,
		rowGroup: {
			colSize: 12,
			textWidth: 4,
			dataWidth: 8,
			rows: [
				{
					name: 'The YP is enrolled in a Victorian school/education setting at referral',
					data: props.data['Education setting at referral'],
				},
				{ name: 'School/setting enrolled in at referral', data: props.data['School at referral'] },
				{ name: 'Campus', data: props.data['Campus at referral'] },
				{
					name: 'Name of school/setting enrolled in at referral (if other)',
					data: props.data['School at referral (other)'],
				},
				{ name: 'Year level', data: props.data['Year level'] },
				{ name: 'Year level (if other)', data: props.data['Year level (other)'] },

				{
					name: 'New (post referral) school/education setting the YP has enrolled at',
					data: props.data['Most recent school'],
				},
				{ name: 'Campus', data: props.data['Most recent campus'] },
				{
					name: 'New (post referral) school/education setting the YP has enrolled at (if other)',
					data: props.data['Most recent school (other)'],
				},
				{
					name: 'Current school/education setting supports offered (modified or reduced timetable, or other)',
					data: props.data['School supports'],
				},
				{
					name: 'Current school/education setting supports offered (other details)',
					data: props.data['School supports (other)'],
				},

				{
					name: 'Best school/setting contact person (current school/setting)',
					data: props.data['School contact - name'],
				},
				{ name: 'Phone', data: props.data['School contact - phone number'] },
				{ name: 'Email', data: props.data['School contact - email address'] },
				{ name: 'Job title', data: props.data['School contact - title'] },
				{ name: 'Their relationship to young person', data: props.data['School contact - relationship'] },
				{ name: 'Best time to contact them', data: props.data['School contact - best time'] },
				{ name: 'Best way to contact them', data: props.data['School contact - contact method'] },
				{ name: 'School feedback on Navigator', data: props.data['School feedback on Navigator'] },
			],
		},
	};

	const dgSchool = {
		title: 'Attendance and Engagement (at Referral)',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{ name: 'Attendance rate % - Current term to date', data: props.data['AttendanceRate1'] },
				{ name: 'Attendance rate % - last term (ii)', data: props.data['AttendanceRate2'] },
				{ name: 'Attendance rate % - term before (iii)', data: props.data['AttendanceRate3'] },
				{ name: 'Attendance rate % - term before (iv)', data: props.data['AttendanceRate4'] },
				{ name: 'Attendance rate % - term before (v)', data: props.data['AttendanceRate5'] },

				{ name: 'Date/s of any suspensions', data: props.data['Date of suspensions'] },
				{
					name: 'Last date the young person attended school/setting',
					data: props.data['Last date the young person attended school'],
				},
				{
					name: "School's opinion regarding reason for YP's non-attendance/disengagement",
					data: props.data['School - Reasons for non-attendance/disengagement'],
				},
				{
					name: "School's opinion regarding reason for YP's non-attendance/disengagement (if other)",
					data: props.data['School - Reasons for non-attendance/disengagement (other)'],
				},
				{
					name: 'Parent/carers opinion regarding reason for YP’s non-attendance/ disengagement',
					data: props.data['Referrer (Parent) - reason for disengagement'],
				},
				{
					name: 'Parent/carers opinion regarding reason for YP’s non-attendance/ disengagement (if other)',
					data: props.data['Referrer (Parent) - reason for disengagement (other)'],
				},
				{
					name: 'Other referrer’s opinion regarding reason for YP’s non-attendance/disengagement',
					data: props.data['Referrer (other) - attempted contact - reason for not contacting school'],
				},
				{
					name: 'Other referrer’s opinion regarding reason for YP’s non-attendance/disengagement (if other)',
					data: props.data['Please provide detail'],
				},
			],
		},
	};

	const dgParentCarer = {
		title: 'Parent/Carer Contact Details',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 5,
			dataWidth: 7,
			rows: [
				{ name: 'Primary parent/carer - Name', data: props.data['Primary parent/carer - Name'] },
				{ name: 'Phone', data: props.data['Primary parent/carer - Phone number'] },
				{ name: 'Email', data: props.data['Primary parent/carer - Email'] },
				{
					name: 'Best way and time to contact them',
					data: props.data['Primary parent/carer - Best way and time to contact'],
				},
				{
					name: 'Primary parent/carer - Relationship to young person',
					data: props.data['Primary parent/carer - Relationship to young person'],
				},
				{ name: 'Secondary parent/carer - Name', data: props.data['Secondary parent/carer - Name'] },
				{ name: 'Phone', data: props.data['Secondary parent/carer - Phone number'] },
				{ name: 'Email', data: props.data['Secondary parent/carer - Email'] },
				{
					name: 'Best way and time to contact them',
					data: props.data['Secondary parent/carer - Best way and time to contact'],
				},
				{
					name: 'Secondary parent/carer - Relationship to young person',
					data: props.data['Secondary parent/carer - Relationship to young person'],
				},
				{ name: 'Other person/party involved - Name', data: props.data['Other person involved - Name'] },
				{ name: 'Job title/role', data: props.data['Other person involved - Title/role'] },
				{ name: 'Phone', data: props.data['Other person involved - Phone number'] },
				{ name: 'Email', data: props.data['Other person involved - Email'] },
				{ name: 'Reason they are involved', data: props.data['Other person involved - Reason'] },
				{ name: 'Interpreter required for parent/carer', data: props.data['Interpreter required'] },
				{ name: 'Parent/Carer interpreter language', data: props.data['PC interpreter - language'] },
			],
		},
	};

	const dgStatus = {
		title: 'Exit, Deferral and Duplicate File Details',
		link: '',
		colSize: 12,
		rowGroup: {
			colSize: 12,
			textWidth: 4,
			dataWidth: 8,
			rows: [
				{
					name: "For duplicate files - Transferred all relevant information into YP's existing file",
					data: props.data['Duplicate referral - detail'],
				},
				{ name: 'For deferred referral - Reason for deferral', data: props.data['Referral deferred - reason'] },
				{ name: 'Further details regarding deferral', data: props.data['Referral deferred - reason (other)'] },
				{ name: 'Deferred referral plan developed', data: props.data['Referral deferred - plan'] },
				{
					name: 'Exit (sustained educational reengagement) - exit destination type',
					data: props.data['Exit successful - reason'],
				},
				{
					namd: 'Exit (sustained educational reengagement) - exit destination type (if other)',
					data: props.data['Exit successful - reason (other)'],
				},
				{
					name: 'Name of new school YP is successfully attending',
					data: props.data['Exit successful - education setting (school)'],
				},
				{ name: 'Campus', data: props.data['Exit successful - education setting (campus)'] },
				{
					name: 'Name of new school YP is successfully attending (if other)',
					data: props.data['Exit successful - other education setting'],
				},
				{
					name:
						'Exit - end point education setting determined in line with Operating Guidelines and DET policy',
					data: props.data['Exit successful - end point education setting'],
				},
				{ name: 'If not, why not', data: props.data['Exit successful - end point education setting (no)'] },
				{ name: 'Exit (before completion) - reason', data: props.data['Exit before completion - reason'] },
				{
					name: 'If reason was "unresponsive/uncontactable" - details',
					data: props.data['Exit before completion - YP unresponsive'],
				},
				{
					name: 'If reason was "request to cease participation/revoked consent" - details',
					data: props.data['Exit before completion - cease/revoked consent'],
				},
				{ name: 'Exit (other engagement achieved) - reason', data: props.data['Exit other - reason'] },
				{
					name: 'Any school based supports that apply',
					data: props.data['Exit successful - school based supports'],
				},
				{
					name: 'Details regarding other school based supports',
					data: props.data['Exit successful - school based supports (other)'],
				},
				{ name: 'If reason was "other" - details', data: props.data['Exit other - other reason'] },
				{
					name: 'If reason was "planned alternative positive outcome" - details',
					data: props.data['Exit other - alternative positive outcome'],
				},
				{
					name: 'Exit other engagement achieved - discussed with area SSM',
					data: props.data['Exit other - area service support approval'],
				},
				{
					name: 'Exit survey - completed and uploaded',
					data: props.data['Exit survey completed and uploaded'],
				},
				{ name: 'If not completed, why not', data: props.data['Exit survey completed and uploaded - no'] },
				{ name: 'Exit Plan/supported exit - done', data: props.data['Exit plan/supported exit complete'] },
				{ name: 'If not done, why not', data: props.data['Exit plan/supported exit complete - no'] },
			],
		},
	};

	const dgInterventions = {
		title: 'Navigator Program Interventions, Support and Requirements',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 5,
			dataWidth: 7,
			rows: [
				{
					name: 'Parent/carer/guardian/mature minor - consent to participate obtained',
					data: props.data['Intake and assessment - consent'],
				},
				{ name: 'Entry survey completed and uploaded', data: props.data['EntrySurveyComplete'] },
				{ name: 'Augmented Navigator', data: props.data['Augmented navigator'] },
				{ name: 'Case manager', data: props.data['Case manager'] },
				{ name: 'Navigator initiated supports', data: props.data['Actions'] },
				{ name: 'Other supports', data: props.data['Other supports offered'] },
				{
					name:
						'For YP who have increased attendance, or newly enrolled in a school/setting since referral, which category do they belong to',
					data: props.data['RTE reason'],
				},
				{ name: 'Details if other', data: props.data['RTE reason (other)'] },
			],
		},
	};

	const dgConsentNavigator = {
		title: 'Navigator Referrals Completed by Navigator Coordinator (or delegate)',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{ name: 'Authorisation to input referral', data: props.data['NC_authorisation'] },
				{ name: 'Navigator Coordinator (or delegate) who input this referral', data: props.data['NC_name'] },
				{
					name:
						'Did the referrer (that this referral was completed on behalf of) have parent/carer/guardian consent to submit a referral',
					data: props.data['NC_authorisation_method'],
				},
				{
					name:
						'If the referrer (that this referral was completed on behalf of) obtained consent to submit referral, who was this from?',
					data: props.data['Consent from young person or parent/carer'],
				},
				{
					name:
						'Reason the Navigator Coordinator (or delegate) who input this referral believed the referral should have been submitted without parent/carer/guardian consent',
					data: props.data['NC consent no P/C'],
				},
				{
					name: 'Did the YP consent to the referral being submitted?',
					data: props.data['NC consent - YP consent'],
				},
				// {name: 'No consent - YP consent', data: props.data['No consent - YP consent']},
				// {name: 'If there was no parent/carer consent to submit the referral did the referrer attempt to contact them', data: props.data['No consent - TP consent (detail)']},
				// {name: 'Declaration', data: props.data['Declaration']},
			],
		},
	};

	const dgConsentPC = {
		title: 'Parent/Carer who Consented to a Referral Being Submitted',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{ name: 'Name', data: props.data['PC consent - name'] },
				{ name: 'Relationship with YP', data: props.data['PC consent - relationship'] },
				{ name: 'Verbal or written consent provided', data: props.data['PC consent - method of consent'] },
				{ name: 'Date consent to submit the referral obtained', data: props.data['PC consent - date'] },
				{ name: 'Who obtained the consent to submit a referral', data: props.data['PC consent - obtained by'] },
				{
					name: 'Has the YP also consented to the referral being submitted',
					data: props.data['PC consent - YP consent'],
				},
			],
		},
	};

	const dgConsentMM = {
		title: 'Mature Minor Consent to Submit a Referral',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{
					name:
						'Who determined the young person should be regarded as a mature minor for the referral - first name',
					data: props.data['MM consent determination - first name'],
				},
				{ name: 'Surname', data: props.data['MM consent determination - surname'] },
				{ name: 'Job title', data: props.data['MM consent determination - Role'] },
				{ name: 'Contact number or email', data: props.data['MM consent determination - email/phone'] },
				{ name: 'Date determination was made', data: props.data['MM consent determination - date made'] },
				{
					name: 'Date mature minor provided consent to submit a referral',
					data: props.data['MM consent - date provided'],
				},
				{ name: 'Verbal or written consent provided?', data: props.data['MM consent - method of consent'] },
				{
					name: 'Who obtained the consent - first name',
					data: props.data['MM consent obtained by - first name'],
				},
				{ name: 'Who obtained the consent - surname', data: props.data['MM consent obtained by - surname'] },
				{
					name: 'Details about any contact or contact attempts with parent/carer',
					data: props.data['MM consent - contact with parent/carer'],
				},
			],
		},
	};

	const dgConsentAP = {
		title: 'Consent to Submit the Referral - Other Source',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{
					name: 'Who consented to the referral being submitted - first name',
					data: props.data['AP consent - first name'],
				},
				{ name: 'Surname', data: props.data['AP consent - surname'] },
				{ name: 'Relationship to young person', data: props.data['AP consent - relationship'] },
				{
					name: 'Reason they can legally consent to a referral being submitted',
					data: props.data['AP consent - authority to provide'],
				},
				{ name: 'Verbal or written consent provided', data: props.data['AP consent - method of consent'] },
				{ name: 'Date consent to submit the referral was obtained', data: props.data['AP consent - date'] },
				{ name: 'Who obtained it - first name', data: props.data['AP consent obtained by - first name'] },
				{ name: 'Surname', data: props.data['AP consent obtained by - surname'] },
				{
					name: 'Did the young person also consent to this referral being submitted',
					data: props.data['AP consent - YP consent'],
				},
			],
		},
	};

	const dgRef = {
		title: 'Who Submitted the Referral',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{ name: 'Referral source', data: props.data['Referrer - relationship'] },
				{ name: 'Referral source (if other)', data: props.data['Referrer - relationship (detail)'] },
				{ name: 'First name', data: props.data['Referrer - First Name'] },
				{ name: 'Surname', data: props.data['Referrer - Surname'] },
				{ name: 'Phone', data: props.data['Referrer - Phone'] },
				{ name: 'Email', data: props.data['Referrer - Email'] },
				{ name: 'Organisation', data: props.data['Referrer - Organisation'] },
				{ name: 'What is their relationship to the YP', data: props.data['Referrer - Relationship to YP'] }, //different questions
			],
		},
	};

	const dgReferralSourceSchool = {
		title: 'Principal (or Equivalent) Endorsement of Referral ',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{
					name: 'Principal (or equivalent) endorsement to submit/support referral obtained',
					data: props.data['Principal consent'],
				},
				{ name: 'First name', data: props.data['Endorsing principal - first name'] },
				{ name: 'Surname', data: props.data['Endorsing principal - surname'] },
				{ name: 'Job title', data: props.data['Endorsing principal - title'] },
				{ name: 'Date they endorsed referral', data: props.data['Endorsing principal - date'] },
			],
		},
	};

	const dgReferralSourceParent = {
		title: 'Pre-Referral Consultation with School - Parent/Carer Referrers',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{
					name: 'Parent/carer raised engagement concerns with school/education setting',
					data: props.data['Referrer (Parent) - raised concerns with school'],
				},
				{
					name: 'Reason for not raising concerns',
					data: props.data['Referrer (Parent) - raised concerns with school (no)'],
				},
				{
					name: 'Name of Person at school/education setting concerns were discussed with',
					data: props.data['Referrer (Parent) - raised concerns with school - Name'],
				},
				{
					name: 'Their job title/role',
					data: props.data['Referrer (Parent) - raised concerns with school - Title/role'],
				},
				{
					name: 'Date of discussion',
					data: props.data['Referrer (Parent) - raised concerns with school - Date'],
				},
				{
					name: 'Outcome of discussion',
					data: props.data['Referrer (Parent) - raised concerns with school - Outcome'],
				},
				{
					name:
						'If attempts to discuss engagement concerns with the school/education setting were unsuccessful, name of person parent/carer attempted contact with',
					data: props.data['Referrer (Parent) - attempted contact with - Name'],
				},
				{
					name: 'Their job title/role',
					data: props.data['Referrer (Parent) - attempted contact with - Title / role'],
				},
				{
					name: 'Date of last contact attempt',
					data: props.data['Referrer (Parent) - attempted contact with - Date'],
				},
			],
		},
	};

	const dgReferralSourceOther = {
		title: 'Pre-Referral Consultation with School - Other Referrers',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{
					name: 'Engagement concerns raised with school/education setting by parent/carer/other',
					data: props.data['Referrer (other) - raised concerns with school'],
				},
				{
					name: 'Reason for not raising concerns',
					data: props.data['Referrer (other) - reason for not contacting school'],
				},
				{
					name: 'Who at the school/education setting concerns were discussed with',
					data: props.data['Referrer (other) - raised concerns with school - Name'],
				},
				{
					name: 'Their job title/role',
					data: props.data['Referrer (other) - raised concerns with school - Title/role'],
				},
				{
					name: 'Date of discussion',
					data: props.data['Referrer (other) - raised concerns with school - Date'],
				},
				{
					name: 'Outcome of discussion',
					data: props.data['Referrer (other) - raised concerns with school - Outcome'],
				},
				{
					name: 'Who had this discussion with the school/education setting',
					data: props.data['Referrer (other) - raised concerns with school - who made it'],
				},
				{
					name:
						'If attempts to discuss engagement concerns with the school/education setting were unsuccessful, name of person parent/carer/other attempted contact with',
					data: props.data['Referrer (other) - attempted contact with - Name'],
				},
				{
					name: 'Date of last contact attempt',
					data: props.data['Referrer (other) - attempted contact with - Date'],
				},
				{ name: 'Attempted contact method', data: props.data['Referrer (other) - attempted contact - how'] },
				{
					name: 'Who made the contact',
					data: props.data['Referrer (other) - attempted contact - who made it'],
				},
			],
		},
	};

	const dgDemoInfo = {
		title: "Young Person's Demographic Information",
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{ name: 'Gender', data: props.data['YP Gender'] },
				{ name: 'Gender (if other)', data: props.data['YP Gender (other)'] },
				{ name: 'Preferred pronouns', data: props.data['YP Gender pronoun'] },
				{ name: 'Preferred pronoun (if other)', data: props.data['YP Gender pronoun (other)'] },
				{ name: 'Young person - interpreter language', data: props.data['YP interpreter - language'] },
				{ name: 'CALD or NCCD inclusion (for DET data collection only)', data: props.data['YP CALD'] },
				{ name: 'CALD background (for DET data collection only)', data: props.data['YP CALD (detail)'] },
				{
					name: 'Which DET priority cohort the young person belongs to',
					data: props.data['YP categories (e.g. ATSI)'],
				},
				{ name: 'Who deemed the YP at risk of YJ involvement', data: props.data['YP YJ risk'] },
				{
					name: "What is the young person's disability and how might it impact on educational engagement",
					data: props.data['YP disability'],
				},
				{
					name: "Young person's strengths/interests",
					data: props.data['YP strengths/interests'],
				},
			],
		},
	};

	const dgWorkSafety = {
		title: 'Worker Safety/Welfare Concerns',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{ name: 'Worker safety concerns raised', data: props.data['Worker safety concerns'] },
				{ name: 'Details', data: props.data['Worker safety concerns (detail)'] },
			],
		},
	};

	const dgPreRef = {
		title: 'School/Setting Based Supports Offered (Before Referral)',
		link: '',
		rowGroup: {
			colSize: 12,
			textWidth: 7,
			dataWidth: 5,
			rows: [
				{
					name:
						'Date (at referral) the school/setting last discussed students disengagement with parent/carer (or attempted to)',
					data: props.data['Last contact parent/carer - date'],
				},
				{
					name: 'Who from the school/setting contacted/discussed with the parent/carer',
					data: props.data['Last contact parent/carer - who by'],
				},
				{ name: 'Outcome of contact/contact attempt', data: props.data['Last contact parent/carer - outcome'] },
				{
					name: 'If Individual Education Plan was current at referral - date developed',
					data: props.data['Individual Education Plan - date'],
				},
				{
					name: 'Date Individual Education Plan was last reviewed',
					data: props.data['Individual Education Plan - last reviewed'],
				},
				{
					name: 'If Student Attendance Improvement Plan was current at referral - date developed',
					data: props.data['Student Attendance Improvement Plan - date'],
				},
				{
					name: 'Date Student Attendance Improvement Plan last reviewed',
					data: props.data['Student Attendance Improvement Plan - last reviewed'],
				},
				{ name: 'Date of Attendance Referral', data: props.data['Attendance Referral - Date'] },
				{
					name: 'Date of Whereabouts Unknown Referral',
					data: props.data['Whereabouts Unknown Referral - Date'],
				},
				{
					name: 'If Behaviour Support Plan was current at referral - date developed',
					data: props.data['Behaviour Support Plan - date'],
				},
				{
					name: 'Date Behaviour Support Plan last reviewed',
					data: props.data['Behaviour Support Plan - last reviewed'],
				},
				{
					name: 'If a Student Health Support Plan was current at referral - date developed',
					data: props.data['Student Health Support Plan - date'],
				},
				{
					name: 'Date Student Health Support Plan last reviewed',
					data: props.data['Student Health Support Plan - last reviewed'],
				},
				{
					name: 'If a Student Absence Learning Plan was current at referral - date developed',
					data: props.data['Student Absence Learning Plan - date'],
				},
				{
					name: 'Date Student Absence Learning Plan last reviewed',
					data: props.data['Student Absence Learning Plan - last reviewed'],
				},
				{
					name: 'If an SSG meeting was held prior to referral - date of last meeting',
					data: props.data['Last SSG meeting - date'],
				},
				{ name: 'Meeting - outcome', data: props.data['Last SSG meeting - outcome'] },
				{ name: 'Meeting - attendee list', data: props.data['Last SSG meeting - Attendee list'] },
				{
					name: 'Date reduced/modified timetable commenced',
					data: props.data['Reduced/modified timetable - date'],
				},
				{ name: 'Date SSS referral made', data: props.data['SSS referral - date'] },
				{ name: 'Details of SSS referral', data: props.data['SSS referral - details'] },
				{
					name: 'Any other internal school/education setting supports in place at referral',
					data: props.data['Other internal school/education setting supports'],
				},
				{
					name: 'Details about referrals to external supports made by school/setting',
					data: props.data['Other referrals made by the school / education setting'],
				},
				{
					name:
						'Was the school/setting/referrer aware of any current or previous external support offered (at referral)',
					data: props.data['External supports'],
				},
				{
					name: 'If yes, there were previous external supports offered - details',
					data: props.data['External support (detail)'],
				},
				{
					name: 'If yes, there were current external supports in place/ offered  - details',
					data: props.data['Current external supports'],
				},
			],
		},
	};

	return (
		<Row className="card-wrapper">
			<Col lg="6">
				<ProfileCard data={dgContactInfo}></ProfileCard>
				<ProfileCard data={dgDemoInfo}></ProfileCard>
				<ProfileCard data={dgParentCarer}></ProfileCard>
				<ProfileCard data={dgSchoolEduSetting}></ProfileCard>
				<ProfileCard data={dgSchool}></ProfileCard>
				<ProfileCard data={dgRef}></ProfileCard>
				<ProfileCard data={dgReferralSourceParent}></ProfileCard>
				<ProfileCard data={dgConsentNavigator}></ProfileCard>
				<ProfileCard data={dgConsentPC}></ProfileCard>
			</Col>
			<Col lg="6">
				<ProfileCard data={dgInterventions}></ProfileCard>
				<ProfileCard data={dgWorkSafety}></ProfileCard>
				<ProfileCard data={dgStatus}></ProfileCard>
				<ProfileCard data={dgExistingManager}></ProfileCard>
				<ProfileCard data={dgPreRef}></ProfileCard>
				<ProfileCard data={dgReferralSourceSchool}></ProfileCard>
				<ProfileCard data={dgReferralSourceOther}></ProfileCard>
				<ProfileCard data={dgConsentMM}></ProfileCard>
				<ProfileCard data={dgConsentAP}></ProfileCard>
			</Col>
		</Row>
	);
};

export default ProfileCardContents;
