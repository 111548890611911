import React from 'react';
import ReactIframeResizer from 'react-iframe-resizer-super';
import { Card, Container, Row, Col } from 'reactstrap';

import PageHeader from 'components/Headers/PageHeader';
import { Endpoints } from 'config/endpoints';
import { iframeOptions, iframeStyles } from 'config/iframes';

const NewReferral = props => {
	return (
		<>
			<PageHeader name="New Referral" parentName="Hub" user={props.user} />

			<Container className="mt--6" fluid>
				<Row>
					<Col>
						<Card style={{ height: '76vh', background: '#F3F3F3' }}>
							<ReactIframeResizer
								iframeResizerOptions={iframeOptions}
								iframeResizerUrl={false}
								src={Endpoints.NEW_REFERRAL}
								style={iframeStyles}
							></ReactIframeResizer>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default NewReferral;
