import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Modal, TabContent, TabPane } from 'reactstrap';

import ProfileHeader from 'components/Headers/ProfileHeader';
import Loading from 'components/General/Loading';
import ProfileErrorPage from 'views/pages/errors/ProfileErrorPage';

import ProfileTopCard from 'components/Cards/ProfileTopCard';
import ProfileCardContents from 'views/pages/profile/ProfileCardContents';
import ProfileGraveyard from 'views/pages/profile/ProfileGraveyard';
import ProfileSurveyTable from 'components/Tables/ProfileSurveyTable';
import ProfileSupportsTable from 'components/Tables/ProfileSupportsTable';
import secureAPI from 'utils/secureAPI';

const Profile = props => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState('profile');
	const [surveys, setSurveys] = useState({
		surveyCols: [],
		surveyResponses: [],
	});
	const [supports, setSupports] = useState({
		supportCols: [],
		supportOptions: [],
	});
	const [graveyard, setGraveyard] = useState({});

	const params = useParams();

	useEffect(() => {
		let canSet = true;
		const getRefProfile = secureAPI({ method: 'POST', endpoint: 'PROFILE' }, { RefID: params.id, user: props.user })
			.then(response => {
				if (canSet) {
					setData(response.data);
				}
			})
			.catch(error => {
				console.log(error);
			});

		const getSurveys = secureAPI({ method: 'POST', endpoint: 'SURVEYS' }, { RefID: params.id })
			.then(response => {
				if (canSet) {
					setSurveys({
						surveyCols: response.data.columns,
						surveyResponses: response.data.response,
					});
				}
			})
			.catch(error => {
				console.log(error);
			});

		const getSupports = secureAPI({ method: 'POST', endpoint: 'SUPPORTS' }, { RefID: params.id })
			.then(response => {
				if (canSet) {
					setSupports({
						supportCols: response.data.columns,
						supportOptions: response.data.response,
					});
				}
			})
			.catch(error => {
				console.log(error);
			});

		const getGraveyard = secureAPI({ method: 'POST', endpoint: 'LEGACY_REF' }, { RefID: params.id })
			.then(response => {
				if (canSet) {
					setGraveyard(response.data);
				}
			})
			.catch(error => {
				console.log(error);
			});

		const requestArr = [getRefProfile, getSurveys, getSupports, getGraveyard];
		Promise.all(requestArr).then(() => {
			if (canSet) {
				setLoading(false);
			}
		});
		return () => (canSet = false);
	}, [params.id, props.user]);

	if (data.status === 'invalid') {
		return <ProfileErrorPage message={data.message} user={props.user} />;
	}

	return !loading ? (
		<>
			<ProfileHeader name="Profile" parentName="Referral List" user={props.user} />
			<Container className="mt--6" fluid>
				<ProfileTopCard
					data={data}
					dataFunc={setData}
					user={props.user}
					activeTab={activeTab}
					changeActiveTab={setActiveTab}
					graveyard={graveyard}
				/>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="profile">
						<ProfileCardContents data={data} />
					</TabPane>
					<TabPane tabId="surveys">
						<ProfileSurveyTable data={surveys} />
					</TabPane>
					<TabPane tabId="supports">
						<ProfileSupportsTable data={supports} />
					</TabPane>
					<TabPane tabId="graveyard">
						<ProfileGraveyard data={graveyard} />
					</TabPane>
				</TabContent>
			</Container>
		</>
	) : (
		<>
			<Modal className="modal-dialog-centered" isOpen={loading}>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Loading Profile ....
					</h6>
				</div>
				<div className="modal-body">
					<Container>
						<Row>
							<Col className="text-center">
								<Loading />
							</Col>
						</Row>
					</Container>
				</div>
			</Modal>
		</>
	);
};

export default Profile;
