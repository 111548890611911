import React from 'react';
import { ResponsiveBarCanvas } from '@nivo/bar';
import ChartTotalsTable from 'components/Tables/ChartTotalsTable';

const StackedBar = ({
	data,
	totalsData,
	dataLabels = true,
	index,
	indices,
	indexFilter,
	keys,
	keyFilter,
	xRotation,
	xLabel,
	xLabelOffset,
	yAxisOff,
	yRotation,
	yLabel,
	yLabelOffset,
	legendOff,
	legendAnchor,
	legendX,
	legendY,
	margins,
	horizontal,
	view,
	totalsTableId,
	totalsType,
	mode,
}) => {
	let filteredData = indexFilter ? data.filter(el => el[index] === indexFilter) : data;
	let tableData = totalsData ? totalsData : data;
	let filteredTotals = indexFilter ? tableData.filter(el => el[index] === indexFilter) : tableData;
	let filteredKeys = keyFilter ? keys.filter(el => el === keyFilter) : keys;
	let filteredIndices = indexFilter ? [indexFilter] : indices;
	if (view === 'totals') {
		return (
			<ChartTotalsTable
				data={filteredTotals}
				keys={filteredKeys}
				indices={filteredIndices}
				index={index}
				tableId={totalsTableId}
				label={xLabel}
				type={totalsType}
			/>
		);
	}
	return (
		<ResponsiveBarCanvas
			data={filteredData}
			keys={filteredKeys}
			indexBy={index}
			layout={horizontal ? 'horizontal' : 'vertical'}
			margin={margins ?? { top: 50, right: 200, bottom: 100, left: 60 }}
			padding={0.3}
			valueScale={{ type: 'linear' }}
			indexScale={{ type: 'band', round: true }}
			colors={{ scheme: 'spectral' }}
			borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: xRotation,
				legend: xLabel,
				legendPosition: 'middle',
				legendOffset: xLabelOffset ?? 85,
			}}
			axisLeft={
				yAxisOff
					? null
					: {
							tickSize: 5,
							tickPadding: 5,
							tickRotation: yRotation ?? 0,
							legend: yLabel,
							legendPosition: 'middle',
							legendOffset: yLabelOffset ?? -40,
					  }
			}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
			enableLabel={dataLabels}
			legends={
				legendOff
					? undefined
					: [
							{
								dataFrom: 'keys',
								anchor: legendAnchor ?? 'bottom-right',
								direction: 'column',
								justify: false,
								translateX: legendX ?? 120,
								translateY: legendY ?? 0,
								itemsSpacing: 2,
								itemWidth: 100,
								itemHeight: 20,
								itemDirection: 'left-to-right',
								itemOpacity: 0.85,
								symbolSize: 20,
								effects: [
									{
										on: 'hover',
										style: {
											itemOpacity: 1,
										},
									},
								],
							},
					  ]
			}
			enableGridX={horizontal ? true : false}
			enableGridY={horizontal ? false : true}
			motionConfig="stiff"
			role="application"
			groupMode={mode ?? 'stacked'}
		/>
	);
};

export default StackedBar;
