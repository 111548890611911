import React from 'react';
import { Col } from 'reactstrap';

export default function ColVCentered(props) {
	return (
		<Col style={{ display: 'flex', alignItems: 'center' }} {...props}>
			{props.children}
		</Col>
	);
}
