import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { AmplifyAuthenticator} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import { Button, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { ReactComponent as Brand } from 'assets/img/brand/det-logo.svg';

import routes from 'routes.js';
import awsconfig from '../aws-exports';
import { Endpoints } from 'config/endpoints';

import { userTiers, userRanks } from 'config/userTiers';

const hostIndex = authURLs => {
	var getIndexOf = searchString => authURLs.findIndex(url => url.search(searchString) > -1);
	var authIndex = getIndexOf(window.location.origin);
	return authIndex > -1 ? authURLs[authIndex] : authURLs[getIndexOf('://prod')];
};

const AUTH_URL_VALIDATOR = process.env.REACT_APP_AUTH_URL_VALIDATOR;

const updatedAwsConfig = {
	...awsconfig,
	oauth: {
		...awsconfig.oauth,
		domain: AUTH_URL_VALIDATOR,
		scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
		responseType: 'token',
		redirectSignIn: hostIndex(Endpoints.APP.split(', ')),
		redirectSignOut: hostIndex(Endpoints.APP.split(', ')),
	},
};

Amplify.configure(updatedAwsConfig);
Auth.configure(updatedAwsConfig);

const isDesktop = window.innerWidth > 1023;

// set sidenav from reload
if (isDesktop) {
	document.body.classList.add('g-sidenav-pinned');
	document.body.classList.remove('g-sidenav-hidden');
} else {
	document.body.classList.remove('g-sidenav-pinned');
	document.body.classList.add('g-sidenav-hidden');
}

const AuthLayout = props => {
	const initUser = {
		userName: '',
		email: '',
		access: '',
		accessRank: 99,
		area: '',
		organisation: '',
		userLoaded: false,
	};
	const [sidenavOpen, setSidenavOpen] = useState(isDesktop);
	const [authState, setAuthState] = useState('loading');
	const [user, setUser] = useState(initUser);

	const location = useLocation();


	const setUserRank = (userAccess, override = null) => {
		let access = userAccess ?? userTiers.STAFF.access;
		let rank = userRanks[access] ?? userTiers.STAFF.rank;
		return override ?? rank;
	};
	



	useEffect(() => {
		Auth.currentAuthenticatedUser({
			bypassCache: true
			}).then(user => 
			{
				let currentUser = {
					userName: `${user.attributes.given_name} ${user.attributes.family_name}`,
					email: user.attributes.email,
					userLoaded: true,
					access: user.attributes['custom:access'],
					accessRank: setUserRank(user.attributes['custom:access']),
					area: user.attributes['custom:area'],
					organisation: user.attributes['custom:organisation'],
				};

				setUser(currentUser);
			});
	}, [location.pathname])

	useLayoutEffect(() => {
		onAuthUIStateChange((nextAuthState, user) => {
			if (nextAuthState === AuthState.SignedIn) {
				setAuthState(nextAuthState);
				Auth.currentAuthenticatedUser({
					bypassCache: true
					}).then(user => 
					{
						let currentUser = {
							userName: `${user.attributes.given_name} ${user.attributes.family_name}`,
							email: user.attributes.email,
							userLoaded: true,
							access: user.attributes['custom:access'],
							accessRank: setUserRank(user.attributes['custom:access']),
							area: user.attributes['custom:area'],
							organisation: user.attributes['custom:organisation'],
						};
		
						setUser(currentUser);
					});

			} else if (nextAuthState === AuthState.SignedOut) {
				setAuthState(nextAuthState);
				setUser({
					userName: '',
					email: '',
					access: '',
					accessRank: 99,
					area: '',
					organisation: '',
					userLoaded: false,
				});
			}
		});
	}, []);

	useEffect(() => {
		if (location.pathname) {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
		}
	}, [location.pathname]);

	const getRoutes = routes => {
		return routes.map((prop, key) => {
			const Component = prop.component;
			return prop.rank >= user.accessRank ? (
				<Route
					path={`${prop.path}`}
					render={props => <Component {...props} user={user} />}
					key={key}
				/>
			) : null;
		});
	};

	// toggles collapse between mini sidenav and normal
	const toggleSidenav = e => {
		if (document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.remove('g-sidenav-pinned');
			document.body.classList.add('g-sidenav-hidden');
		} else {
			document.body.classList.add('g-sidenav-pinned');
			document.body.classList.remove('g-sidenav-hidden');
		}

		setSidenavOpen(!sidenavOpen);
	};

	const getNavbarTheme = () => {
		return props.location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
	};

	const sidebarRoutes = routes
		.filter(route => route.rank >= user.accessRank);

	return authState === AuthState.SignedIn && user.userLoaded ? (
		<AmplifyAuthenticator usernameAlias="email">
			<Sidebar
				{...props}
				routes={sidebarRoutes}
				toggleSidenav={toggleSidenav}
				sidenavOpen={sidenavOpen}
				logo={{
					innerLink: '/',
					imgSrc: require('assets/img/brand/det-logo-p.png'),
					imgAlt: '...',
				}}
			/>
			<div className="main-content">
				<AdminNavbar
					{...props}
					user={user}
					theme={getNavbarTheme()}
					toggleSidenav={toggleSidenav}
					sidenavOpen={sidenavOpen}
				/>
				<Switch>
					{getRoutes(routes)}
					<Redirect from="*" to={`/hub`} />
				</Switch>
			</div>
			{sidenavOpen ? <div className="backdrop d-xl-none" onClick={toggleSidenav} /> : null}
		</AmplifyAuthenticator>
	) : (
		<AmplifyAuthenticator>
			<div
				slot="sign-in"
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flex: 1,
					height: '100vh',
					width: '100vw',
				}}
			>

				<Card style={{ width: '50%', maxWidth: '35em' }}>
					<CardHeader className="card-borderless" style={{ display: 'flex', justifyContent: 'center' }}>
						<Brand 
							alt="VicGovDET"
							style={{
								maxWidth: '100%',
								height: 'auto',
							}}
						/>
					</CardHeader>
					<CardBody>
						<Col>
							<Button
								block
								style={{ height: '80px' }}
								color="primary"
								onClick={() => Auth.federatedSignIn()}
							>
								Sign into Navigator Portal
							</Button>
						</Col>
					</CardBody>
				</Card>
			</div>
		</AmplifyAuthenticator>
	);
};

export default AuthLayout;

// pipeline test
