import { Col, Row } from 'reactstrap';
import StackedBar from 'components/Plots/StackedBar';
import ChartWrapper from 'components/Plots/ChartWrapper';
import NoChartData from 'components/Plots/NoChartData';
import { chartNotEmpty } from 'utils/functions';

const ReferralsBySchools10 = ({ data, dataFilters, keys, currentView, areas }) => {
	let school = data['school-ranked'];

	let finalSetKeys = [...new Set(school.map(el => Object.keys(el).filter(key => key !== 'DETArea')).flat())];

	const schoolChart = chartNotEmpty({
		noDataComponent: <NoChartData text="No data" />,
		values: school,
		component: (
			<ChartWrapper currentView={currentView} totalsKeyword="totals" chartMaxHeight="72vh">
				<StackedBar
					margins={{ top: 25, right: 80, bottom: 100, left: 80 }}
					data={school ?? []}
					index={'DETArea'}
					indices={areas}
					keys={finalSetKeys}
					keyFilter={null}
					xRotation={-15}
					xLabel={'Current Statuses of Referrals'}
					yLabel={'Number of Referrals'}
					yLabelOffset={-60}
					legendOff
					xLabelOffset={75}
					view={currentView}
					totalsTableId={'school'}
				/>
			</ChartWrapper>
		),
	});

	return (
		<Row className="mt-3">
			<Col>{schoolChart}</Col>
		</Row>
	);
};

export default ReferralsBySchools10;
