import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

const AutSort = props => {
    // this component is a hack for mutli column sorting
    // only allows single direction sorting (asc, desc)
    // relies on string sorting
	const handleAutoSort = e => props.stateFunc(props.initState)
    return (
        <>
            <Button
                className="buttons-copy buttons-html5"
                color="default"
                size="sm"
                id="auto-sort"
                onClick={handleAutoSort}
            >
                <span>
                    <i
                        className="fas fa-reply"
                        style={{ margin: '0 10px 0 0' }}
                    ></i>
                    Auto Sort
                </span>
            </Button>
            <UncontrolledTooltip placement="top" target="auto-sort">
                This will order referrals by Status, Priority and Referral Date.
            </UncontrolledTooltip>
        </>
    )
}

export default AutSort;