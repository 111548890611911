import React from 'react';
import { Col } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import { ConfirmAlert, SuccessAlert, CancelAlert, LoadingAlert } from 'components/Alerts/ActionAlerts';
import { formatDateTime } from 'utils/dateFormatter';
import secureAPI from 'utils/secureAPI';

export const confirmApprove = options => {
    return options.func(
        <ConfirmAction
            title="Confirm Reopen Referral"
            text="The following referral will be reopened and edits may be made."
            data={options.data}
            onConfirm={handleConfirmApproval(options)}
            onCancel={handleCancelAlerts(options.func)}
        />
    )
}

export const confirmDeny = options => {
    return options.func(
        <ConfirmAction
            title="Confirm Deny Request"
            text="The following referral will be reopened and edits may be made."
            data={options.data}
            onConfirm={handleConfirmDenial(options)}
            onCancel={handleCancelAlerts(options.func)}
        />
    )
}

const ConfirmAction = props => (
    <ConfirmAlert
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
        title={props.title}
        text={props.text}
    >
        <ConfirmRefTable data={props.data} />
    </ConfirmAlert>
)

const handleConfirmApproval = options => {
    return () => {
        options.func(<LoadingAlert />);

        const promiseArr = [
            secureAPI(
                {method: 'PUT', endpoint: 'REOPEN_CASE'},
                {
                    ref: {
                        RefID: options.data['RefID'],
                        userEmail: options.user.email,
                        admin: options.user.userName,
                    }
                }
            )
                .then(response => console.log(response))
                .catch(err => console.log(err)),

            secureAPI(
                {method: 'POST', endpoint: 'REOPEN_CASE'},
                {
                    confirm:{
                        RefID: options.data['RefID'],
                        type: "approved",
                        admin: options.user.userName,
                        userEmail: options.user.email,
                    }
                }
            )
                .then(response => console.log(response))
                .catch(err => console.log(err)),
        ];

        Promise.all(promiseArr).then(() => {
            options.func(successAlert(options.func));
            removeConfirmed(options);
        })
    }
}

const handleConfirmDenial = options => {
    return () => {
        options.func(<LoadingAlert />)
        
        const promiseArr = [
            secureAPI(
                {method: 'POST', endpoint: 'REOPEN_CASE'},
                {
                    confirm:{
                        RefID: options.data['RefID'],
                        type: "denied",
                        admin: options.user.userName,
                        userEmail: options.user.email,
                    }
                }
            )
                .then(response => console.log(response))
                .catch(err => console.log(err))
        ];

        Promise.all(promiseArr).then(() => {
            options.func(successAlert(options.func));
            removeConfirmed(options);
        })
    }

}

const removeConfirmed = options => {
    const filterConfirmed = data => data.filter(
        row => row['RequestID'] !== options.data['RequestID']
    )
    options.tableFunc(filterConfirmed)
}

const successAlert = funcs => {
    let alertFunc;
    if (typeof(funcs) === "object") {
        alertFunc = funcs.alert;
    } else {
        alertFunc = funcs;
    }
    return (
        <SuccessAlert
            onConfirm={handleClearAlerts(alertFunc)}
            onCancel={handleClearAlerts(alertFunc)}
        />
    )
}

const handleCancelAlerts = alertFunc => {
    return () => alertFunc(
        <CancelAlert onConfirm={() => alertFunc(null)} />
    )
};

const handleClearAlerts = (alertFunc) => {
    return () => {
        alertFunc(null);
    }
}

const ConfirmRefTable = props => {
    let data = props.data
    const dataTable = [
        {
            field: "First Name",
            value: data.RefFirstName
        },
        {
            field: "Last Name",
            value: data.RefLastName
        },
        {
            field: "Date of Birth",
            value: data.RefDOB
        },
        {
            field: "DET Area",
            value: data.RefDETArea
        },
        {
            field: "Requester Name",
            value: data.RequesterName
        },
        {
            field: "Requester Organisation",
            value: data.RequesterOrg
        },
        {
            field: "Date Requested",
            value: formatDateTime(data.DateRequested)
        },
    ].filter(el => el.value)

    return (
        <Col>
            <ToolkitProvider
                data={dataTable}
                keyField="field"
                columns={[
                    {
                        dataField: 'field',
                        text: 'Field',
                        align: 'left',
                    },
                    {
                        dataField: 'value',
                        text: 'Value',
                    },
                ]}
            >
                {props => {
                    return (
                        <div>
                            <BootstrapTable
                                id="confirm-user-table"
                                bootstrap4={true}
                                bordered={true}
                                {...props.baseProps}
                            />
                        </div>
                    );
                }}
            </ToolkitProvider>
        </Col>
    )
}

