import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

import { copyToClipboardAsTable } from 'config/tableConfig';

const CopyTable = props => (
	<>
		{/* {console.log(props.tableElement)} */}
		<Button
			className="buttons-copy buttons-html5"
			color="default"
			size="sm"
			id="copy-tooltip"
			onClick={() => copyToClipboardAsTable(props.tableElement, props.alertFunc)}
		>
			<span>
				<i className="fas fa-copy" style={{ margin: '0 10px 0 0' }}></i>
				Copy
			</span>
		</Button>
		<UncontrolledTooltip placement="top" target="copy-tooltip">
			This will copy to your clipboard the visible rows of the table.
		</UncontrolledTooltip>
	</>
);

export default CopyTable;
