import React from 'react';

import PageHeader from 'components/Headers/PageHeader';
import PendingUsersTable from './PendingUsersTable';

const PendingUsers = props => {
	return (
		<>
			<PageHeader name="Users Pending Approval" parentName="Hub" user={props.user} />
            <PendingUsersTable user={props.user} />
		</>
	);
};

export default PendingUsers;