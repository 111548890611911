import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import HubHeader from 'components/Headers/HubHeader.js';
import HubCard from 'components/Cards/HubCard';
import Loading from 'components/General/Loading';

import { userTiers } from 'config/userTiers';
import secureAPI from 'utils/secureAPI';

const Hub = props => {
	const history = useHistory();
	const [approvals, setApprovals] = useState({
		users: null,
		cases: null,
	});

	const hubRefClick = () => {
		history.push(`/referral-form`);
	};
	const refListClick = () => {
		history.push(`/referral`);
	};
	const reportClick = () => {
		history.push(`/reporting-list`);
	};
	const hubAddUserClick = () => {
		history.push(`/add-user`);
	};
	const hubApproveUserClick = () => {
		history.push(`/approve-users`);
	};
	const hubPendingUsersClick = () => {
		history.push(`/pending-users`);
	};
	const hubApproveReopenRequestsClick = () => {
		history.push(`/approve-reopen-requests`);
	};
	const hubPendingReopenRequestsClick = () => {
		history.push(`/pending-reopen-requests`);
	};

	useEffect(() => {
		if (props.user.accessRank > userTiers.COORD.rank) {
			return;
		}

		let canSet = true;
		let delay = 1000 * 60 * 30; // every half hour

		const getUserCounts = () =>
			secureAPI(
				{ method: 'POST', endpoint: 'APPROVE_USER' },
				{
					count: true,
				},
			)
				.then(response => response.data.total ?? null)
				.catch(err => {
					console.log(err);
					return;
				});

		const getReopenCounts = () =>
			secureAPI(
				{ method: 'POST', endpoint: 'REOPEN_REQUEST' },
				{
					user: {
						access: props.user.access,
						area: props.user.area,
						count: true,
					},
				},
			)
				.then(response => response.data.total ?? null)
				.catch(err => {
					console.log(err);
					return;
				});

		const getAll = () => {
			Promise.all([getUserCounts(), getReopenCounts()]).then(values => {
				console.log('resolved', values[0], values[1]);
				if (canSet) {
					setApprovals({
						users: values[0],
						cases: values[1],
					});
				}
				setTimeout(getAll, delay);
			});
		};

		getAll();

		return () => {
			canSet = false;
			clearTimeout(getAll);
		};
	}, [props.user.accessRank, props.user.access, props.user.area]);

	const approvalBadge = numbers => {
		if (numbers == null) {
			return {
				titleBadge: <Loading mini />,
			};
		} else if (numbers === 0) {
			return null;
		} else {
			return {
				titleBadge: `${numbers} Pending`,
				titleBadgeColor: 'danger',
			};
		}
	};

	return (
		<>
			<HubHeader name="Hub" />
			<Container className="mt--6" fluid>
				<div className="card-deck">
					<HubCard
						color="bg-gradient-orange"
						onClick={hubRefClick}
						title="Referral Form"
						body="Form process to refer young people to the Navigator program."
						buttonText="Submit new referral"
						icon="ni ni-single-02"
					/>
					<HubCard
						color="bg-gradient-green"
						onClick={refListClick}
						title="Referral List"
						body="Paginated table of current referrals in the Navigator program."
						buttonText="List current referrals"
						icon="ni ni-bullet-list-67"
					/>
					<HubCard
						color="bg-gradient-purple"
						onClick={reportClick}
						title="Reporting"
						// badge="In Progress"
						badgeColor="warning"
						body="Analytical and graphical Navigator program reporting suite."
						buttonText="View reports"
						icon="ni ni-sound-wave"
					/>
				</div>
				{userTiers.COORD.rank >= props.user.accessRank ? (
					<Row className="card-wrapper">
						<Col>
							<div className="card-deck">
								<HubCard
									color="bg-gradient-teal"
									onClick={hubAddUserClick}
									title="Add New User"
									body="Add a new user to the Navigator program."
									buttonText="Add user"
									icon="ni ni-lock-circle-open"
								/>
								<HubCard
									color="bg-gradient-red"
									onClick={hubApproveUserClick}
									title="Approve Users"
									{...approvalBadge(approvals.users)}
									body="Approve users to the Navigator program."
									buttonText="Approve users"
									icon="ni ni-check-bold"
								/>
								<HubCard
									color="bg-gradient-green"
									onClick={hubApproveReopenRequestsClick}
									title="Reopen Requests"
									{...approvalBadge(approvals.cases)}
									body="Approve requests to reopen referrals in the Navigator program."
									buttonText="Approve reopen requests"
									icon="ni ni-curved-next"
								/>
							</div>
						</Col>
					</Row>
				) : (
					<Row className="card-wrapper">
						<Col>
							<div className="card-deck">
								<HubCard
									color="bg-gradient-teal"
									onClick={hubAddUserClick}
									title="Add New User"
									body="Add a new user to the Navigator program."
									buttonText="Add user"
									icon="ni ni-lock-circle-open"
								/>
								<HubCard
									color="bg-gradient-red"
									onClick={hubPendingUsersClick}
									title="Pending User Requests"
									body="View user request outcomes by Navigator admins."
									buttonText="View pending users"
									icon="ni ni-check-bold"
								/>
								<HubCard
									color="bg-gradient-green"
									onClick={hubPendingReopenRequestsClick}
									title="Pending Reopen Requests"
									body="View referral reopen request outcomes by Navigator admins."
									buttonText="View pending reopen requests"
									icon="ni ni-curved-next"
								/>
							</div>
						</Col>
					</Row>
				)}
			</Container>
		</>
	);
};

export default Hub;
