import React from 'react';

import PageHeader from 'components/Headers/PageHeader';
import PendingReopenRequestsTable from 'components/Tables/PendingReopenRequestsTable';

const PendingReopenRequests = props => {
	return (
		<>
			<PageHeader name="Requests Pending Approval" parentName="Hub" user={props.user} />
			<PendingReopenRequestsTable user={props.user} />
		</>
	);
};

export default PendingReopenRequests;
